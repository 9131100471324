import React, {useEffect, useState} from 'react';
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
import {Link} from "react-router-dom";

function Global(props) {
    const [staticNews, setStaticNews] = useState(null)

    const handleLink = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    useEffect(()=>{
        fetch(`${props.debugValue}/api/blogs/static`)
            .then(res=>res.json())
            .then(data=> setStaticNews(data)).catch(err=>console.log(err))
    },[])

    if (!staticNews || (staticNews && staticNews.status === 400)) return null

    return (
        <>
            <section className={'articles__global'}>
                <div className="container_s">
                    <div className="articles__wrapper">
                        <div className="articles__section_text mb-2">
                            <div className="articles__section_title text-heading-4 mb-1">
                                Learn all about solar power
                            </div>
                            <div className="articles__section_cta text-heading-7">
                                <Link onClick={handleLink} to={'/blog'}>See all articles <i className="ri-arrow-right-line"></i></Link>
                            </div>
                        </div>

                        <Splide aria-label="" hasTrack={false} className={'articles__dt'}
                                options={{
                                    arrows: false,
                                    autoPlay:false,
                                    loop: false,
                                    perPage: 3,
                                    gap: '32px',
                                    pagination: false
                                }}>
                            <SplideTrack className="">
                                {staticNews && staticNews.map(article=>{
                                    return(
                                        <SplideSlide key={article.path} className={'article'}>
                                            <div className="">
                                                <div className="article__top">
                                                    <img src={article.cover_img} alt=""/>
                                                </div>
                                                <div className="article__bottom">
                                                    <div className="article__title text-heading-5">{article.title}</div>
                                                    <div className="article__cta text-heading-6"><Link onClick={handleLink} to={`/blog${article.path}`}>Read <i className="ri-arrow-right-line"></i></Link></div>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                    )
                                })}
                            </SplideTrack>
                        </Splide>
                    </div>
                </div>
            </section>
            <section className="articles__global_mobile">
                <div className="container_s">
                    <div className="articles__mobile_wrapper">
                        <div className="articles__section_text mb-2">
                            <div className="articles__section_title text-heading-4 mb-1">
                                Learn all about solar power
                            </div>
                        </div>
                        <Splide aria-label="" hasTrack={false} className={'splide'}
                                options={{
                                    arrows: true,
                                    autoPlay:true,
                                    loop: false,
                                    perPage: 1,
                                    gap: '32px',
                                    pagination: false,
                                }}>

                            <SplideTrack className="">
                                {staticNews && staticNews.map(article=>{
                                    return(
                                        <SplideSlide key={article.path} className={'article'}>
                                            <div className="">
                                                <div className="article__top">
                                                    <img src={article.cover_img} alt=""/>
                                                </div>
                                                <div className="article__bottom">
                                                    <div className="article__title text-heading-5">{article.title}</div>
                                                    <div className="article__cta text-heading-6"><Link onClick={handleLink} to={`/blog${article.path}`}>Read <i className="ri-arrow-right-line"></i></Link></div>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                    )
                                })}

                                <SplideSlide>
                                    <div className="articles__section_cta text-heading-5">
                                        <Link onClick={handleLink} to={'/blog'}>See all articles <i className="ri-arrow-right-line"></i></Link>
                                    </div>
                                </SplideSlide>
                            </SplideTrack>

                        </Splide>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Global;
