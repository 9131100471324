'use client';

import {Address} from "../components/form/form-quote";

export const handleSolarEstimatorSubmit = (formData, handleStatus, props, calculationResult, handleCalculationResult) =>{
    if (formData.firstname.length > 0 && formData.lastname.length > 0 && formData.u_data.length > 0){
        handleStatus(300)
        let tmp_data = formData
        tmp_data.user_id = props.bd.user_id
        fetch(`${props.debugValue}/api/form/solar-estimator`,{
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + props.bd.t,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json()).then(data => {
            if (data.status === 200) {
                handleStatus(200)
                handleCalculationResult({...calculationResult,
                    bill_saved: data.bill_saved,
                    overall_savings: data.overall_savings,
                })
            }else if (data.status === 400) {
                handleStatus(400)
                alert('The estimate was not sent, please try again.')
            }else if (data.status === 401) {
                alert('Issue with estimation please try again.')
            }
        }).catch(err=>{
            handleStatus(301)
            alert('Something went wrong! Please try again.')
        })
    }else{
        alert('Please fill all the required fields.')
    }
}

export const handleQuoteForm = async function(formData, props, addError, source){
    let {email, firstName, lastName, phone, type, address} = formData
    if (!email || !firstName || !lastName || !phone || !type || !address || !source) {
        addError('Please fill all the required fields.')
    }else{
        try{
            let res = await fetch(`${props.debugValue}/api/form/communication/${source}`, {
                method: 'POST',
                body: JSON.stringify({
                    u_data: email,
                    firstname: firstName,
                    lastname: lastName,
                    formId: source,
                    phone: phone,
                    type: type,
                    address: address,
                    comment: formData.comment ? formData.comment : '',
                    informative: props.bd
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + props.bd.t
                }
            }).catch(err => console.log(err))
            if (res && res.status === 200){
                return res
            }else {
                addError('Something went wrong! Please refresh the page and try again.')
            }

        }catch(error){
            alert("Something went wrong! Please try again.")
        }
    }
}
