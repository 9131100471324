import React from 'react';
import {Form} from "react-router-dom";

function ReferralInvite({loading}) {
    const submitInvite = (e) => {
        e.preventDefault();

    }
    return (
        <>
            <Form style={{padding: '24px'}}>
                <div className="invite__form_subtitle text-body-3">
                </div>
                <div className="invite__form_title text-heading-4">
                    Refer a friend
                </div>
                <div className="input__wrapper">
                    <i className="ri-user-line"></i><input required type="text" name={'name'}
                                                           className="text-body-1"
                                                           placeholder={"Your friend's name"}/>
                </div>
                <div className="input__wrapper">
                    <i className="ri-mail-line"></i><input required type="text" name={'name'}
                                                           className="text-body-1"
                                                           placeholder={"Your friend's email"}/>
                </div>
                {loading &&
                    <button disabled className={'text-body-1 mb-1'}
                            style={{fontWeight: 'bold', cursor: 'crosshair'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="135" height="40"
                             viewBox="0 0 135 140" fill="#fff">
                            <rect y="10" width="15" height="120" rx="6">
                                <animate attributeName="height" begin="0.5s" dur="1s"
                                         values="120;110;100;90;80;70;60;50;40;140;120"
                                         calcMode="linear" repeatCount="indefinite"/>
                                <animate attributeName="y" begin="0.5s" dur="1s"
                                         values="10;15;20;25;30;35;40;45;50;0;10"
                                         calcMode="linear" repeatCount="indefinite"/>
                            </rect>
                            <rect x="30" y="10" width="15" height="120" rx="6">
                                <animate attributeName="height" begin="0.25s" dur="1s"
                                         values="120;110;100;90;80;70;60;50;40;140;120"
                                         calcMode="linear" repeatCount="indefinite"/>
                                <animate attributeName="y" begin="0.25s" dur="1s"
                                         values="10;15;20;25;30;35;40;45;50;0;10"
                                         calcMode="linear" repeatCount="indefinite"/>
                            </rect>
                            <rect x="60" width="15" height="140" rx="6">
                                <animate attributeName="height" begin="0s" dur="1s"
                                         values="120;110;100;90;80;70;60;50;40;140;120"
                                         calcMode="linear" repeatCount="indefinite"/>
                                <animate attributeName="y" begin="0s" dur="1s"
                                         values="10;15;20;25;30;35;40;45;50;0;10"
                                         calcMode="linear" repeatCount="indefinite"/>
                            </rect>
                            <rect x="90" y="10" width="15" height="120" rx="6">
                                <animate attributeName="height" begin="0.25s" dur="1s"
                                         values="120;110;100;90;80;70;60;50;40;140;120"
                                         calcMode="linear" repeatCount="indefinite"/>
                                <animate attributeName="y" begin="0.25s" dur="1s"
                                         values="10;15;20;25;30;35;40;45;50;0;10"
                                         calcMode="linear" repeatCount="indefinite"/>
                            </rect>
                            <rect x="120" y="10" width="15" height="120" rx="6">
                                <animate attributeName="height" begin="0.5s" dur="1s"
                                         values="120;110;100;90;80;70;60;50;40;140;120"
                                         calcMode="linear" repeatCount="indefinite"/>
                                <animate attributeName="y" begin="0.5s" dur="1s"
                                         values="10;15;20;25;30;35;40;45;50;0;10"
                                         calcMode="linear" repeatCount="indefinite"/>
                            </rect>
                        </svg>
                    </button>
                }
                {!loading &&
                    <button className={'text-body-1 mb-1'} style={{fontWeight: 'bold'}}>Confirm
                        my
                        referral bonus
                    </button>
                }
            </Form>
        </>
    );
}

export default ReferralInvite;
