import '../../assets/css/home-solutions.css'
import React, {useEffect, useState} from 'react';
import {Splide, SplideSlide, SplideTrack} from '@splidejs/react-splide';
import '@splidejs/react-splide/css'
import Global from "../shared/global";
import {Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";

function HomeOwnerBattery(props) {
    const [solutionsIndex, setSolutionsIndex] = useState('0')
    const [sliderValue, setSliderValue] = useState("260")
    const handleSolutionsTab = (e) =>{
        const index = e.target.dataset.tabindex
        if (index){
            setSolutionsIndex(index)
        }
    }

    const setDefaultSlider = (e) =>{
        let slider = document.getElementById('slider')
        let selector = document.getElementById('selector')
        let selectValue = document.getElementById('selectValue')
        let progressBar = document.getElementById('progressBar')

        selectValue.innerHTML = "$ "+slider.value
        selector.style.left = slider.value/10 + '%'
        progressBar.style.width = slider.value/10 + '%'
    }
    useEffect(()=>{
        setDefaultSlider()
    },[])
    const handleInput = (e) =>{
        setSliderValue(e.target.value)
        setDefaultSlider()
    }

    const handleSubmitCta = (e) => {
        e.preventDefault()
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Homeowners - Battery system</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/homeowners/battery`}/>
                </Helmet>
            </HelmetProvider>
            <section className="solutions__hero_light">
                <div className="">
                    <div className="solutions__hero_wrapper">
                        <div className="solutions__hero_pages">
                            <Splide aria-label="" hasTrack={false}
                            options={{
                                perPage: 1,
                                arrows: false,
                                type: 'loop',
                                autoplay:true,
                                resetProgress: false
                            }}>
                                <SplideTrack>
                                    <SplideSlide style={{backgroundImage: `url('${require('../../assets/media/pictures/static/homesolution/1HB Hero.png')}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "80%"}}>
                                        <div className="slide__wrapper container_s">
                                            <div className="slide__text">
                                                <div className="slide__sub text-heading-6">
                                                    Empowering families and businesses since 2008
                                                </div>
                                                <div className="slide__title text-heading-1">
                                                    Keep powering on, no matter what
                                                </div>
                                                <div className="slide__desc text-heading-4">
                                                    Protect against grid failure and peak prices with a battery system
                                                </div>
                                                <div className="slide__cta text-heading-6">
                                                    <button className=" text-heading-6" onClick={props.toggleForm} >Get a free energy assessment</button>
                                                </div>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide style={{backgroundImage: `url(${require('../../assets/media/pictures/static/homesolution/1Hero.jpg')})`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                                        <div className="slide__wrapper container">
                                            <div className="slide__text">
                                                {/*<div className="slide__sub text-heading-6">*/}
                                                {/*    For Home owners*/}
                                                {/*</div>*/}
                                                <div className="slide__title text-heading-1">
                                                    Empowering families since 2008
                                                </div>
                                                <div className="slide__desc text-heading-4">
                                                    Take back control of your power and cut your energy bill by 90% or more
                                                </div>
                                                <div className="slide__cta text-heading-6">
                                                    <Link to={'/homeowners/solar'} className=" text-heading-6" onClick={props.handleLink}>Learn more</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                </SplideTrack>
                                {/*<button className="splide__toggle" type="button">*/}
                                {/*    <span className="splide__toggle__play">Play</span>*/}
                                {/*    <span className="splide__toggle__pause">Pause</span>*/}
                                {/*</button>*/}
                                <div className="splide__progress">
                                    <div className="splide__progress__bar"/>
                                </div>

                            </Splide>
                        </div>
                    </div>
                </div>
            </section>

            <section className="solutions__intro_light" style={{background: `linear-gradient(179deg, white 30.12%, rgba(255, 255, 255, 0.00) 92%), url(${require('../../assets/media/pictures/static/h-battery-intro.jpeg')}) no-repeat center`}}>
                <div className="container">
                    <div className="solutions__intro_wrapper">
                        <div className="solutions__intro_title text-heading-4 mb-1">
                            Total power independence
                        </div>
                        <div className="solutions__intro_desc text-body-1">
                            Our top-brand battery systems offer more than just storage: they redefine how you use and save energy.
                        </div>
                    </div>
                </div>
            </section>

            <section className={'solutions__single_light'}>
                {/*<i className="ri-hard-drive-2-fill decor__icon"></i>*/}
                {/*<i className="ri-hard-drive-2-fill decor__icon2"></i>*/}
                <div className="container">
                    <div className="solutions__wrapper mt-5 mb-3">
                        <div className="solutions__text mb-3">
                            <div className="solutions__title section__title text-heading-4 mb-1">
                                One battery, three uses
                            </div>
                        </div>
                        <ul className="solutions__benefits">
                            <li data-tabindex={0} onClick={event => handleSolutionsTab(event)} className="solution__benefit">
                                <div> <p className={'tab__title text-heading-6'} data-tabindex={0}>Backup power</p>
                                    <div className={solutionsIndex !== "0" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="benefits__sub_wrapper" style={{backgroundImage: `url('${require('../../assets/media/pictures/static/homesolution/3.1 Backup power (New).jpg')}')`, backgroundSize: "cover", backgroundPosition: "center"}}>
                                            <div className="benefits__sub_info">
                                                <div className="tab__text">
                                                    <div className="tab__single_icon mb-2">
                                                        <i className="ri-lightbulb-flash-fill"></i>
                                                    </div>
                                                    <div className="tab__single_title mb-2 text-heading-4">
                                                        Store power for later
                                                    </div>
                                                    <div className="tab__single_desc mb-2">
                                                        Eliminate blackouts and enhance your autonomy with reliable, round-the-clock power
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-tabindex={1} onClick={event => handleSolutionsTab(event)} className="solution__benefit">
                                <div> <p className={'tab__title text-heading-6'} data-tabindex={1}>Cut energy costs</p>
                                    <div className={solutionsIndex !== "1" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="benefits__sub_wrapper" style={{backgroundImage: `url('${require('../../assets/media/pictures/static/homesolution/ho_battery_uses_2.png')}')`, backgroundSize: "cover", backgroundPosition: "right"}}>
                                            <div className="benefits__sub_info">
                                                <div className="tab__text">
                                                    <div className="tab__single_icon mb-2">
                                                        <i className="ri-pie-chart-fill"></i>
                                                    </div>
                                                    <div className="tab__single_title mb-2 text-heading-4">
                                                        Optimize power costs
                                                    </div>
                                                    <div className="tab__single_desc mb-2">
                                                        Reduce electricity costs by using stored energy during the most expensive tariff periods
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-tabindex={2} onClick={event => handleSolutionsTab(event)} className="solution__benefit">
                                <div> <p className={'tab__title text-heading-6'} data-tabindex={2}>Reduce carbon tax</p>
                                    <div className={solutionsIndex !== "2" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="benefits__sub_wrapper" style={{backgroundImage: `url('${require('../../assets/media/pictures/static/homesolution/3.3 Make a profit.jpg')}')`, backgroundSize: "cover", backgroundPosition: "center"}}>
                                            <div className="benefits__sub_info">
                                                <div className="tab__text">
                                                    <div className="tab__single_icon mb-2">
                                                        <i className="ri-money-dollar-circle-fill"></i>
                                                    </div>
                                                    <div className="tab__single_title mb-2 text-heading-4">
                                                        Minimize carbon taxes
                                                    </div>
                                                    <div className="tab__single_desc mb-2">
                                                        Protect against future carbon tax increases by using solar energy stored during the day
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li><div style={{transform:`translateX(${solutionsIndex*100}%)`}} className={'tab__active'}></div></li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="solutions__benefits_single_light">
                <div className="container">
                    <div className="solutions__benefits_wrapper">
                        <div className="solutions__benefits_title text-heading-4 mb-1">Maximize your system's potential</div>
                        <div className="solutions__benefits_desc text-body-1 mb-4">From cost savings to increased energy independence, a backup battery unlocks the most benefits your system offers</div>
                        <div className="solutions__benefits_elements">
                            <div className="solutions__benefits_element">
                                <div className="benefit__elements_icon">
                                    <i className="ri-bar-chart-2-line"></i>
                                </div>
                                <div className="benefit__elements_title text-heading-5 mb-1">No peak energy costs</div>
                                <div className="benefit__elements_desc text-body-1">Avoid expensive peak times by using the power you stored up earlier</div>
                            </div>
                            <div className="solutions__benefits_element">
                                <div className="benefit__elements_icon">
                                    <i className="ri-shield-flash-fill"></i>
                                </div>
                                <div className="benefit__elements_title text-heading-5 mb-1">Enhanced energy security</div>
                                <div className="benefit__elements_desc text-body-1">Keep your lights on and your appliances running, even during power outages</div>
                            </div>
                            <div className="solutions__benefits_element">
                                <div className="benefit__elements_icon">
                                    <i className="ri-tree-fill"></i>
                                </div>
                                <div className="benefit__elements_title text-heading-5 mb-1">Greener than just solar</div>
                                <div className="benefit__elements_desc text-body-1">Reduce your carbon footprint further by storing clean, solar energy to use around the clock</div>
                            </div>
                            <div className="solutions__benefits_element">
                                <div className="benefit__elements_icon">
                                    <i className="ri-line-chart-fill"></i>
                                </div>
                                <div className="benefit__elements_title text-heading-5 mb-1">Higher property values</div>
                                <div className="benefit__elements_desc text-body-1">Homes equipped with battery storage systems have higher market appeal and prices</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="company__benefits">
                <div className="container">
                    <div className="company__benefits_wrapper">
                        <div className="company__benefits_intro">
                            <div className="company__intro_text">
                                <div className="company__benefits_title text-heading-4 mb-3">More power means more benefits</div>
                                <div className="company__benefits_desc text-heading-6">Battery systems double down on the benefits you get from going solar</div>
                            </div>
                            <div className="company__intro_image">
                                <img src={require('../../assets/media/pictures/static/homesolution/4. More power more benefits.png')} alt=""/>
                            </div>
                        </div>
                        <div className="company__benefits_numbers">
                            <div className="benefit__number">
                                <div className="benefit__number_icon"><i className="ri-funds-fill"></i> <span className={'text-body-1'}>Lower monthly bills</span></div>
                                <div className="benefit__number_desc text-heading-5">Batteries can reduce energy bills by 30% more</div>
                            </div>
                            <div className="benefit__number">
                                <div className="benefit__number_icon"><i className="ri-funds-fill"></i> <span className={'text-body-1'}>Better appeal</span></div>
                                <div className="benefit__number_desc text-heading-5">Solar homes sell up to 20% faster and for 5%  more</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="calculator__cta">
                <div className="container">
                    <div className="calculator__cta_wrapper">
                        <form className="calculator__cta_interact" method={'POST'} onSubmit={event => handleSubmitCta(event)}>
                            <div className="calculator__cta_title text-heading-3 mb-1">Unlock your savings potential</div>
                            <div className="calculator__cta_desc text-body-1">Solar calculator to assess and optimize your business energy savings.</div>
                            <div className="calculator__cta_input">
                                <input type="range" min="0" max="1000" value={sliderValue} id="slider" step={'10'} onInput={event =>handleInput(event) }/>
                                <div id="selector">
                                    <div className="selectBtn"></div>
                                    <div id="selectValue" className={"text-body-1"}></div>
                                </div>
                                <div id="progressBar"></div>
                                <div id="progressBackground"></div>
                                <div className="input__range"><span>$0</span><span>$1000</span></div>
                            </div>
                            <div className="calculator__cta_cta"><button className={'text-heading-7'} type="submit">Calculate</button></div>
                        </form>
                        <div className="calculator__cta_image">
                            <img src={`${require('../../assets/media/pictures/static/homesolution/8. Potential Savings.jpg')}`} alt=""/>
                        </div>
                    </div>
                </div>
            </section>

            <section className={'rebates__global'}>
                <div className="container">
                    <div className="rebates__wrapper">
                        <div className="rebates__title text-heading-4" style={{fontWeight: "bold"}}>
                            Special offers
                        </div>
                        <div className="rebates__desc text-body-1">
                            <Link to={'/special-offers'}>View all our promotional offers <i className="ri-arrow-right-line"></i></Link>
                        </div>
                        <div className="rebates__offers">
                            <div className="rebates__offer">
                                <div className="rebates__offer_text">
                                    <div className="rebates__offer_title text-heading-3">
                                        Save up to 30% off
                                    </div>
                                    <div className="rebates__offer_desc text-body-1">
                                        Federal tax credits can take up to 30% off the total cost of your system. Lock your system today because these credits could change soon.
                                    </div>
                                    <div className="rebates__offer_cta">
                                        <a href="">Learn more</a>
                                    </div>

                                </div>
                                <div className="rebates__referral_picture">
                                    <img src={require('../../assets/media/pictures/static/homesolution/7.1-Tax-Credit.jpeg')} alt=""/>
                                </div>
                            </div>
                            <div className="rebates__offer_referral">
                                <div className="rebates__referral_text">
                                    <div className="rebates__offer_title text-heading-3">
                                        Go solar for less than $40/month
                                    </div>
                                    <div className="rebates__offer_desc text-body-1">
                                        Enjoy our best deal on our premier panels & microinverters with systems starting as low as $39.83 per month!
                                    </div>
                                    <div className="rebates__offer_cta">
                                        <Link to={'/special-offers'} onClick={props.handleLink}>Learn more</Link>
                                    </div>
                                </div>
                                <div className="rebates__referral_picture">
                                    <img src={require('../../assets/media/pictures/static/homesolution/7.2 40 deal image.jpg')} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Global debugValue={props.debugValue}/>


        </>
    );
}

export default HomeOwnerBattery;