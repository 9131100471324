import React from "react";

export async function gen_router_element(componentList, props) {
    let routes = []
    for (const component of componentList) {
        let comp = await gen_component(component.element, props)
        routes.push({
            path: `${component.path}`,
            element: comp,
        })
    }
    return routes
}

export async function gen_component(component, props) {
    return  React.createElement(component, props)
}