import React from 'react';
import Logo from '../../assets/media/pictures/logo.png'
import {Link} from "react-router-dom";

function Footer() {
    const handleLink = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    return (
        <>
            <footer>
                <div className="container_s">
                    <div className="footer__wrapper">
                        <div className="footer__credentials">
                            <div className="footer__logo">
                                <img src={Logo} alt=""/>
                            </div>
                            <div className="footer__info">
                                <div className="footer__title text-heading-7">SolarMax Technology, Inc.</div>
                                <div className="footer__address text-body-2">3080 12th Street <br/> Riverside, CA 92507
                                </div>
                                <div className="footer__number text-body-2"><i className="ri-phone-fill"></i> (951)
                                    221-8172
                                </div>
                                <div className="footer__rating text-body-2">
                                    <i className="ri-star-fill"></i>
                                    <i className="ri-star-fill"></i>
                                    <i className="ri-star-fill"></i>
                                    <i className="ri-star-fill"></i>
                                    <i className="ri-star-half-fill"></i>4.8 of 5 <br/> based on 45 reviews
                                </div>
                            </div>
                        </div>
                        <div className="footer__elements">
                            <div className="footer__element">
                                <div className="footer__element_title text-heading-5">Solutions</div>
                                <ul>
                                    <li className={'footer__element_sub text-heading-6'}>Homeowners</li>
                                    <li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={'/homeowners/solar'}>Solar systems</Link></li>
                                    <li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={'/homeowners/battery'}>Backup battery power</Link></li>
                                </ul>
                                <ul>
                                    <li className={'footer__element_sub text-heading-6'}>Businesses</li>
                                    <li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={'/businesses/solar'}>Commercial solar energy</Link></li>
                                    <li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={'/businesses/battery'}>Energy storage systems</Link></li>
                                    {/*<li className={'text-body-2 footer__single_link'}><a href="">EV charging</a></li>*/}
                                </ul>
                                <ul>
                                    <li className={'footer__element_sub text-heading-6'}>Communities</li>
                                    <li className={'text-body-2 footer__single_link'}><a href={'https://solarmaxled.com/'}>LED lighting & signs</a></li>
                                    {/*<li className={'text-body-2 footer__single_link'}><a href="">EV charging</a></li>*/}
                                </ul>
                            </div>
                            <div className="footer__element">
                                <div className="footer__element_title text-heading-5">Resources</div>
                                <ul>
                                    <li className={'footer__element_sub text-heading-6'}>Explore SolarMax</li>
                                    <li className={'text-body-2 footer__single_link'}><Link onClick={handleLink}  to={'/financing'}>Financing</Link></li>
                                    <li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={'/warranties'}>Warranties</Link></li>
                                    <li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={'/special-offers'}>Special offers</Link></li>
                                    {/*<li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={''}>Referral awards</Link>*/}
                                    {/*</li>*/}
                                </ul>
                                <ul>
                                    <li className={'footer__element_sub text-heading-6'}>News & media</li>
                                    {/*<li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={''}>Events</Link></li>*/}
                                    {/*<li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={''}>Media center</Link></li>*/}
                                    <li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={'/blog'}>Blogs</Link></li>
                                    <li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={'/reviews'}>Reviews</Link></li>
                            </ul>
                            </div>
                            <div className="footer__element">
                                <div className="footer__element_title text-heading-5">About</div>
                                <ul>
                                    <li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={'/about-us'}>Our company</Link></li>
                                    {/*<li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={'/sustainability'}>Sustainability</Link></li>*/}
                                    <li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={'/service-areas'}>Service areas <i className="ri-external-link-line"></i></Link></li>
                                    <li className={'text-body-2 footer__single_link'}><a href='https://irsites.com/smxt/'>Investor relations</a>
                                    </li>
                                    {/*<li className={'text-body-2 footer__single_link'}><Link onClick={handleLink} to={''}>Careers</Link></li>*/}
                                </ul>
                            </div>
                        </div>
                        <div className="footer__legal">
                            <div className="footer__legal_sources text-body-2">
                                <p>© 2013 - 2024 SolarMax Renewable Energy Provider, Inc. | LIC #972048</p>
                                <a href="#">• Privacy</a>
                                <a href="#">• Site map</a>
                            </div>
                            <div className="footer__legal_sm">
                                <div className="footer__sm">
                                    <a href=""><i className="ri-facebook-circle-fill"></i></a>
                                </div>
                                <div className="footer__sm">
                                    <a href=""><i className="ri-instagram-line"></i></a>
                                </div>
                                <div className="footer__sm">
                                    <a href=""><i className="ri-twitter-x-line"></i></a>
                                </div>
                                <div className="footer__sm">
                                    <a href=""><i className="ri-linkedin-box-fill"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </footer>

        </>
    );
}

export default Footer;

