import Logo from '../../assets/media/pictures/logo.png'
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import FormQuote from "../../components/form/form-quote";

const Navbar = (props) =>{
    const [scrollData, setScrollData] = useState({
        y: 0,
        lastY: 0
    })

    const [burger, setBurger] = useState(false)

    const handleMobileLink = () =>{
        setBurger(false)
        document.getElementById('burger__check').checked = false
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    const handleLink = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'})
    }


    const handleBurger = () =>{
        setBurger(!burger)
    }

    const [showHeader, setShowHeader] = useState(true)

    useEffect(()=>{
        const handleScroll = () =>{
            setScrollData(old=>{
                return{y: window.scrollY, lastY: old.y}
            })
        }
        window.addEventListener('scroll', handleScroll)


        return () =>window.removeEventListener('scroll', handleScroll)
    }, [])

    useEffect(()=>{
        if (scrollData.lastY === 0 && scrollData.y === 0){
            setShowHeader(false)
        }
        else if (scrollData.lastY > scrollData.y){
            setShowHeader(false)
        }else{
            setShowHeader(true)
        }
    }, [scrollData])

    return(
        <div id={'main__header'} className={` ${props.path.includes('businesses') ? "navigation__dark" : "navigation"} ${showHeader ? 'header__scroll_up' : ''}`}>
            <div className={'container_s'}>

                <div className={'navigation__wrapper'}>
                    <div className="nav__logo">
                        <Link onClick={handleMobileLink} id={'navigation-home'} to="/"><img src={Logo} alt=""/></Link>
                    </div>
                    <div className="nav__segments">
                        <ul className={'segment__list'}>
                            <li className="nav__segment">
                                <div className={'nav__title text-heading-7'}>Solutions <i className="ri-arrow-down-s-line"></i>
                                    <div className={'sub__nav_element'}>
                                        <div className={"sub__nav_wrapper"}>
                                            <div className="navigation__part">
                                                <ul className={'column__333'}>
                                                    <li className={'sub__nav_title'}><div>Homeowners</div></li>
                                                    <li className={'pt-li-1'} id={'navigation-solar_system'}><Link onClick={handleLink} to={'/homeowners/solar'}>Solar systems</Link></li>
                                                    <li className={'pt-li-1'} id={'navigation-backup_battery'}><Link onClick={handleLink} to={'/homeowners/battery'}>Backup battery power</Link></li>
                                                </ul>
                                                <ul className={'column__333'}>
                                                    <li className={'sub__nav_title'}><div>Businesses</div></li>
                                                    <li className={'pt-li-1'} id={'navigation-commercial_solar'}><Link onClick={handleLink} to={'/businesses/solar'}>Commercial solar energy</Link></li>
                                                    <li className={'pt-li-1'} id={'navigation-ess'}><Link onClick={handleLink} to={'/businesses/battery'}>Energy storage systems</Link></li>
                                                    {/*<li className={'pt-li-1'}><a href="#">EV charger</a></li>*/}
                                                </ul>
                                                <ul className={'column__333'}>
                                                    <li className={'sub__nav_title'}><div>Communities</div></li>
                                                    <li className={'pt-li-1'} id={'navigation-led'}><a href={'https://solarmaxled.com/'}>LED lighting & signs <i
                                                        className="ri-external-link-line"></i></a></li>
                                                    {/*<li className={'pt-li-1'}><a href="#">EV charger</a></li>*/}
                                                </ul>
                                                {/*<ul className={'column__333'}>*/}
                                                {/*    <li className={'sub__nav_title'}><div>Utility</div></li>*/}
                                                {/*    <li className={'pt-li-1'}><a href="#">Solar</a></li>*/}
                                                {/*    <li className={'pt-li-1'}><a href="#">So_battery</a></li>*/}
                                                {/*</ul>*/}
                                            </div>
                                            <div className="informative__part">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/*<li className="nav__segment">*/}
                            {/*    <div className={'nav__title text-heading-7'}>Products <i className="ri-arrow-down-s-line"></i>*/}
                            {/*        <div className={'sub__nav_element'}>*/}
                            {/*            <div className={"sub__nav_wrapper"}>*/}
                            {/*                <div className="navigation__part">*/}
                            {/*                    <ul className={'column__333'}>*/}
                            {/*                        <li className={'sub__nav_title'}><div>Residential</div></li>*/}
                            {/*                        <li className={'pt-li-1'} id={'navigation-solar_system'}><Link onClick={handleLink} to={'/homeowners/solar'}>Solar panel</Link></li>*/}
                            {/*                        <li className={'pt-li-1'} id={'navigation-solar_system'}><Link onClick={handleLink} to={'/homeowners/solar'}>Battery</Link></li>*/}
                            {/*                        <li className={'pt-li-1'} id={'navigation-solar_system'}><Link onClick={handleLink} to={'/homeowners/solar'}>Solar systems</Link></li>*/}
                            {/*                        <li className={'pt-li-1'} id={'navigation-backup_battery'}><Link onClick={handleLink} to={'/homeowners/battery'}>Backup battery power</Link></li>*/}
                            {/*                    </ul>*/}
                            {/*                    <ul className={'column__333'}>*/}
                            {/*                        <li className={'sub__nav_title'}><div>Commercial</div></li>*/}
                            {/*                        <li className={'pt-li-1'} id={'navigation-commercial_solar'}><Link onClick={handleLink} to={'/businesses/solar'}>Commercial solar energy</Link></li>*/}
                            {/*                        <li className={'pt-li-1'} id={'navigation-ess'}><Link onClick={handleLink} to={'/businesses/battery'}>Energy storage systems</Link></li>*/}
                            {/*                        /!*<li className={'pt-li-1'}><a href="#">EV charger</a></li>*!/*/}
                            {/*                    </ul>*/}
                            {/*                </div>*/}
                            {/*                <div className="informative__part">*/}

                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</li>*/}

                            <li className="nav__segment">
                                <div className={'nav__title text-heading-7'}>Resources <i className="ri-arrow-down-s-line"></i>
                                    <div className={'sub__nav_element'}>
                                        <div className={"sub__nav_wrapper"}>
                                            <div className="navigation__part">
                                                <ul className={'column__333'}>
                                                    <li className={'sub__nav_title'}>
                                                        <div>Explore SolarMax</div>
                                                    </li>
                                                    <li className={'pt-li-1'} id={'navigation-financing'}><Link
                                                        onClick={handleLink} to={'/financing'}>Financing</Link></li>
                                                    <li className={'pt-li-1'} id={'navigation-warranties'}><Link
                                                        onClick={handleLink} to={'/warranties'}>Warranties</Link></li>
                                                    <li className={'pt-li-1'} id={'navigation-special_offers'}><Link
                                                        onClick={handleLink} to={'/special-offers'}>Special
                                                        offers</Link></li>
                                                    <li className={'pt-li-1'}><Link to={'/referral'}>Referral
                                                        awards</Link></li>
                                                </ul>
                                                <ul className={'column__333'}>
                                                    <li className={'sub__nav_title'}>
                                                        <div>News & media</div>
                                                    </li>
                                                    {/*<li className={'pt-li-1'}><Link to={'/businesses/solar'}>Media Center</Link></li>*/}
                                                    <li className={'pt-li-1'} id={'navigation-blog'}><Link
                                                        onClick={handleLink} to={'/blog'}>Blog</Link></li>
                                                    <li className={'pt-li-1'} id={'navigation-reviews'}><Link
                                                        onClick={handleLink} to={'/reviews'}>Reviews</Link></li>
                                                </ul>
                                                <ul className={'column__333'}>
                                                    <li className={'sub__nav_title'}>
                                                        <div>Tools</div>
                                                    </li>
                                                    {/*<li className={'pt-li-1'} id={'navigation-blog'}><Link*/}
                                                    {/*    onClick={handleLink} to={'/solar-estimate'}>Solar calculator</Link></li>*/}
                                                </ul>

                                            </div>
                                            <div className="informative__part">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav__segment">
                                <div className={'nav__title text-heading-7'}>About <i
                                    className="ri-arrow-down-s-line"></i>
                                    <div className={'sub__nav_element'}>
                                        <div className={"sub__nav_wrapper"}>
                                        <div className="navigation__part">
                                                <ul className={'column__333'}>
                                                    <li className={'sub__nav_title'}><div>About SolarMax</div></li>
                                                    <li className={'pt-li-1'} id={'navigation-our_company'}><Link onClick={handleLink} to={'/about-us'}>Our company</Link></li>
                                                    <li className={'pt-li-1'} id={'navigation-service_areas'}><Link onClick={handleLink} to={'/service-areas'}>Service areas</Link></li>
                                                    <li className={'pt-li-1'} id={'navigation-investor_relations'}><a target={"_blank"} href="https://irsites.com/smxt/">Investor relations <i
                                                        className="ri-external-link-line"></i></a></li>
                                                    {/*<li className={'pt-li-1'}><Link onClick={handleLink} to={''}>Sustainability</Link></li>*/}
                                                    {/*<li className={'pt-li-1'}><a href="#">Careers</a></li>*/}
                                                </ul>
                                            </div>
                                            <div className="informative__part">

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav__segment">
                                <div className={'nav__title text-heading-7'} id={'navigation-contact_us'}><Link onClick={handleLink} to={'/contact-us'}>Contact</Link></div>

                            </li>
                        </ul>
                    </div>
                    <div className="nav__supports">
                        <ul className="support__list">
                            {/*<li className="nav__support nav__support_tmp text-heading-7">*/}
                            {/*    <a href="">Become a dealer</a>*/}
                            {/*</li>*/}
                            {/*<div className={'nav__separator nav__support_tmp'}></div>*/}
                            <li className="nav__support nav__support_tmp text-heading-7" id={'navigation-phone'}><a href="tel:(951) 221-8172"><i className="ri-phone-fill"></i> (951) 221-8172</a></li>
                            <li className="nav__support text-heading-7 nav__button_wrapper" id={'navigation-global-quotation'}><button className={'nav__button_link'} onClick={props.toggleForm} >{props.showForm ? <i className="ri-close-fill"></i>: 'Get a quote' }</button></li>
                            <li className="nav__support text-heading-7 nav__button_wrapper burger__menu">
                                <div className={`menuToggle ${props.path.includes('businesses') ? "menuToggle_dark" : ""}`}>
                                    <input type="checkbox"  id={'burger__check'} onChange={event =>handleBurger(event) }/>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
            <div className={`mobile__navbar ${burger? 'navbar__mobile_active' : 'navbar__mobile'}  ${props.path.includes('businesses') ? "mobile__navbar_dark" : ""}`}>
                <ul className="menuItem">
                    <li>
                        <label htmlFor="mobile__navbar-solutions">
                            <p>Solutions <i className="ri-arrow-down-s-line"></i></p>
                            <input type="checkbox" id="mobile__navbar-solutions"/>
                            <div className="navigation__item-product navigation__item">
                                <ul className={''}>
                                    <li className={'sub__nav_title'}>
                                        <div>Home owners</div>
                                    </li>
                                    <li className={'pt-li-1'} onClick={handleMobileLink} id={'navigation-mobile-solar_system'}><Link to={'/homeowners/solar'}>Solar systems</Link></li>
                                    <li className={'pt-li-1'} onClick={handleMobileLink} id={'navigation-mobile-backup_battery'}><Link to={'/homeowners/battery'}>Backup battery power</Link></li>
                                </ul>
                                <ul className={''}>
                                    <li className={'sub__nav_title'}>
                                        <div>Business owners</div>
                                    </li>
                                    <li className={'pt-li-1'} onClick={handleMobileLink} id={'navigation-mobile-commercial_solar'}><Link to={'/businesses/solar'}>Commercial solar energy</Link></li>
                                    <li className={'pt-li-1'} onClick={handleMobileLink} id={'navigation-mobile-ess'}><Link to={'/businesses/battery'}>Energy storage systems
                                    </Link></li>
                                    {/*<li className={'pt-li-1'} onClick={handleMobileLink}><a href="#">EV charger</a></li>*/}
                                </ul>
                                <ul className={''}>
                                    <li className={'sub__nav_title'}>
                                        <div>Communities</div>
                                    </li>
                                    <li className={'pt-li-1'} id={'navigation-mobile-led'}><a href={'https://solarmaxled.com/'}>LED lighting & signs <i
                                        className="ri-external-link-line"></i></a></li>
                                    {/*<li className={'pt-li-1'} onClick={handleMobileLink}><a href="#">EV charger</a></li>*/}
                                </ul>
                            </div>
                        </label>
                    </li>
                    <li>
                        <label htmlFor="mobile__navbar-resources"><p>Resources <i className="ri-arrow-down-s-line"></i></p>
                            <input type="checkbox" id="mobile__navbar-resources"/>
                            <div className="navigation__item-product navigation__item">
                                <ul className={''}>
                                    <li className={'sub__nav_title'}>
                                        <div>Explore SolarMax</div>
                                    </li>
                                    <li className={'pt-li-1'} onClick={handleMobileLink}
                                        id={'navigation-mobile-financing'}><Link to={'/financing'}>Financing</Link></li>
                                    <li className={'pt-li-1'} onClick={handleMobileLink}
                                        id={'navigation-mobile-warranties'}><Link to={'/warranties'}>Warranties</Link>
                                    </li>
                                    <li className={'pt-li-1'} onClick={handleMobileLink}
                                        id={'navigation-mobile-special_offers'}><Link to={'/special-offers'}>Special
                                        offers</Link>
                                    </li>
                                    <li className={'pt-li-1'} onClick={handleMobileLink}><Link to={'/referral'}>Referral
                                        awards</Link></li>
                                </ul>
                                <ul className={''}>
                                    <li className={'sub__nav_title'}>
                                        <div>News & media</div>
                                    </li>
                                    {/*<li className={'pt-li-1'} onClick={handleMobileLink}><Link to={'/businesses/solar'}>Media Center</Link></li>*/}
                                    <li className={'pt-li-1'} onClick={handleMobileLink} id={'navigation-mobile-blog'}>
                                        <Link to={'/blog'}>Blog</Link></li>
                                    <li className={'pt-li-1'} onClick={handleMobileLink}
                                        id={'navigation-mobile-reviews'}><Link to={'/reviews'}>Reviews</Link></li>
                                </ul>
                                <ul className={''}>
                                    <li className={'sub__nav_title'}>
                                        <div>Tools</div>
                                    </li>
                                    {/*<li className={'pt-li-1'} onClick={handleMobileLink} id={'navigation-mobile-blog'}>*/}
                                    {/*    <Link to={'/solar-estimate'}>Solar Estimate</Link></li>*/}
                                </ul>
                            </div>
                        </label>
                    </li>
                    <li>
                        <label htmlFor="mobile__navbar-about"><p>About <i className="ri-arrow-down-s-line"></i></p>
                            <input type="checkbox" id="mobile__navbar-about"/>
                            <div className="navigation__item-product navigation__item">
                                <ul className={''}>
                                    <li className={'pt-li-1'} onClick={handleMobileLink}
                                        id={'navigation-mobile-our_company'}><Link to={'/about-us'}>Our company</Link>
                                    </li>
                                    <li className={'pt-li-1'} onClick={handleMobileLink}
                                        id={'navigation-mobile-service_areas'}><Link to={'/service-areas'}>Service areas</Link></li>
                                    <li className={'pt-li-1'} onClick={handleMobileLink} id={'navigation-mobile-investor_relations'}><Link to={'https://irsites.com/smxt/'}>Investor relations <i
                                        className="ri-external-link-line"></i></Link></li>
                                    {/*<li className={'pt-li-1'} onClick={handleMobileLink}><Link to={''}>Sustainability</Link></li>*/}
                                    {/*<li className={'pt-li-1'} onClick={handleMobileLink}><Link to={''}>Careers</Link></li>*/}
                                </ul>
                            </div>
                        </label>
                    </li>
                    <li onClick={handleMobileLink} id={'navigation-mobile-contact_us'}><Link to="/contact-us">Contact</Link></li>
                    <li id={'navigation-mobile-phone'}>
                        <a href="tel:(951) 221-8172"><i className="ri-phone-fill"></i> (951) 221-8172</a>
                    </li>
                </ul>
            </div>
            <div className={`get__quote ${props.showForm? 'get__quote_active' : ''}`}>
                <div className={`get__quote_form_wrapper`} style={{background: `${props.path.includes('businesses') ? "#03141B" : "white"}`}}>
                    <div className="get__quote_form">

                        {/*<iframe className={"form__holder"}*/}
                        {/*        style={{width: '100%', height: '740px', position: 'sticky', top: '80px'}}*/}
                        {/*        id={'solarmax__form'}*/}
                        {/*        src={`https://4345356.extforms.netsuite.com/app/site/crm/externalleadpage.nl?compid=4345356${props.form_source}`}*/}
                        {/*        frameBorder="0"></iframe>*/}

                        <FormQuote
                            debugValue={props.debugValue}
                            bd={props.bd}
                            formSource={"quotation"}
                        />
                    </div>
                </div>
                <div className="get__quote_wrapper" onClick={props.toggleForm}></div>
            </div>
        </div>
    )
}

export default Navbar