import React, {useEffect, useState} from "react";
import {Form, Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import ReferralInvite from "./referral-invite";
import ReferralProfile from "./referral-profile";
import {createReferral, deleteReferral, ReferralStorage} from "../../fetch/referral";

const ReferralDashboard = (props) =>{
    const [error, setError] = useState(null);
    const [referralProfile, setReferralProfile] = useState(null);
    const [referredProfiles, setReferredProfiles] = useState(null);
    const [loading, setLoading] = useState(false);
    const [referralInfo, setReferralInfo] = React.useState({
        referral__firstname: "",
        referral__lastname: "",
        referral__email: ""
    })

    const handleInput = (e) => {
        setReferralInfo({...referralInfo, [e.target.name]: e.target.value})
    }

    const changeUser = () =>{
        deleteReferral()
        setReferralProfile(null)
    }

    useEffect(()=>{
        if (!localStorage.getItem('referral')){

        }else {
            setLoading(true)
            let referralInfo = JSON.parse(localStorage.getItem('referral'));
            referralInfo ? setReferralProfile(referralInfo)
                : setError({
                    message:'Something went wrong in the process',
                    description: 'This may appear only if the email is already used in the system, or if the invitation link is corrupt.'})
            setLoading(false)
        }
    }, [])

    const handleProfileSubmit = (e) =>{
        e.preventDefault();
        setLoading(true)
        createReferral(
            referralInfo.referral__firstname,
            referralInfo.referral__lastname,
            referralInfo.referral__email,
            props).then(rl => {
            if (rl) {
                rl.referral ? localStorage.setItem('referral', JSON.stringify(new ReferralStorage(rl.referral)))
                    : setError({
                        message: 'Something went wrong in the process',
                        description: 'This may appear only if the email is already used in the system, or if the invitation link is corrupt.'
                    })
                let referralInfo = JSON.parse(localStorage.getItem('referral'));
                referralInfo ? setReferralProfile(referralInfo)
                    : setError({
                        message:'Something went wrong in the process',
                        description: 'This may appear only if the email is already used in the system, or if the invitation link is corrupt.'})
                setLoading(false)
            } else {
                setError({
                    message: 'Something went wrong in the process',
                    description: 'This may appear only if the email is already used in the system, or if the invitation link is corrupt.'
                })
            }
        })
    }

    const copyToClipboard = (e) => {
        let value = e.target.value;
        e.target.select();
        e.target.setSelectionRange(0, 99999); // For mobile devices
        navigator.clipboard.writeText(value).then(()=> console.log('Copied to clipboard'));
    }

    return(
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Referral dashboard</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/referral/dashboard`}/>
                </Helmet>
            </HelmetProvider>


            <section className="global__hero_single" style={{
                backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url('${require('../../assets/media/pictures/static/my-referral-hero.png')}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
                <div className="container_s" style={{height: '100%'}}>
                    <div className="single__hero_wrapper">
                        <div className="single__hero_text">
                            {error && !referralProfile &&
                                <>
                                    <div className="single__hero_title text-heading-3">{error.message}</div>
                                    <div className="single__hero_desc  text-heading-5 mb-2">{error.description}
                                    </div>
                                </>

                            }
                            {!referralProfile &&
                                <>
                                    <Form
                                        onSubmit={e => handleProfileSubmit(e)} style={{padding: '24px'}}
                                        id={"referral-dashboard-form-custom"}
                                    >
                                        <div className="single__hero_title text-heading-3">Confirm the email</div>
                                        <div className="single__hero_desc  text-heading-5 mb-2">Please enter the email
                                            that the referral is attached / created with.
                                        </div>
                                        <input className={'text-body-1'} type="text" name={"referral__firstname"}
                                               placeholder="First name"
                                               onChange={e => handleInput(e)} value={referralInfo.referral__firstname}/>
                                        <input className={'text-body-1'} type="text" name={"referral__lastname"}
                                               placeholder="Last name"
                                               onChange={e => handleInput(e)} value={referralInfo.referral__lastname}/>
                                        <input className={'text-body-1'} type="email" name={"referral__email"}
                                               placeholder="Email"
                                               onChange={e => handleInput(e)} value={referralInfo.referral__email}/>
                                        <div className="single__hero_cta" style={{marginTop: '30px'}}>
                                            {loading &&
                                                <button disabled className={'text-body-1 mb-1'}
                                                        style={{
                                                            fontWeight: 'bold',
                                                            cursor: 'crosshair',
                                                            pointerEvents: "none"
                                                        }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="15"
                                                         viewBox="0 0 135 140" fill="#fff">
                                                        <rect y="10" width="15" height="120" rx="6">
                                                            <animate attributeName="height" begin="0.5s" dur="1s"
                                                                     values="120;110;100;90;80;70;60;50;40;140;120"
                                                                     calcMode="linear" repeatCount="indefinite"/>
                                                            <animate attributeName="y" begin="0.5s" dur="1s"
                                                                     values="10;15;20;25;30;35;40;45;50;0;10"
                                                                     calcMode="linear" repeatCount="indefinite"/>
                                                        </rect>
                                                        <rect x="30" y="10" width="15" height="120" rx="6">
                                                            <animate attributeName="height" begin="0.25s" dur="1s"
                                                                     values="120;110;100;90;80;70;60;50;40;140;120"
                                                                     calcMode="linear" repeatCount="indefinite"/>
                                                            <animate attributeName="y" begin="0.25s" dur="1s"
                                                                     values="10;15;20;25;30;35;40;45;50;0;10"
                                                                     calcMode="linear" repeatCount="indefinite"/>
                                                        </rect>
                                                        <rect x="60" width="15" height="140" rx="6">
                                                            <animate attributeName="height" begin="0s" dur="1s"
                                                                     values="120;110;100;90;80;70;60;50;40;140;120"
                                                                     calcMode="linear" repeatCount="indefinite"/>
                                                            <animate attributeName="y" begin="0s" dur="1s"
                                                                     values="10;15;20;25;30;35;40;45;50;0;10"
                                                                     calcMode="linear" repeatCount="indefinite"/>
                                                        </rect>
                                                        <rect x="90" y="10" width="15" height="120" rx="6">
                                                            <animate attributeName="height" begin="0.25s" dur="1s"
                                                                     values="120;110;100;90;80;70;60;50;40;140;120"
                                                                     calcMode="linear" repeatCount="indefinite"/>
                                                            <animate attributeName="y" begin="0.25s" dur="1s"
                                                                     values="10;15;20;25;30;35;40;45;50;0;10"
                                                                     calcMode="linear" repeatCount="indefinite"/>
                                                        </rect>
                                                        <rect x="120" y="10" width="15" height="120" rx="6">
                                                            <animate attributeName="height" begin="0.5s" dur="1s"
                                                                     values="120;110;100;90;80;70;60;50;40;140;120"
                                                                     calcMode="linear" repeatCount="indefinite"/>
                                                            <animate attributeName="y" begin="0.5s" dur="1s"
                                                                     values="10;15;20;25;30;35;40;45;50;0;10"
                                                                     calcMode="linear" repeatCount="indefinite"/>
                                                        </rect>
                                                    </svg>
                                                </button>
                                            }
                                            {!loading &&
                                                <button className={'text-body-1 mb-1'}
                                                        style={{fontWeight: 'bold'}}>Confirm
                                                    my
                                                    referral bonus
                                                </button>
                                            }
                                        </div>
                                        <p className={'text-body-3'}>We won't share your email with anyone, and you can
                                            unsubscribe at any time. Learn more in our <Link
                                                style={{color: 'var(--branding-secondary)'}} to={'/privacy'}>privacy
                                                policy</Link>.</p>
                                    </Form>
                                </>
                            }

                            <ReferralProfile referralProfile={referralProfile} copyToClipboard={copyToClipboard} changeUser={changeUser}/>
                        </div>
                    </div>
                </div>
            </section>
            {referralProfile &&
                <>
                    <section className="invite__referrals">
                        <div className="container_s">
                            <div className="invite__referrals_wrapper">

                                <div className="invite__referrals_form">
                                    <ReferralInvite data={props} loading={loading}/>
                                </div>

                                <div className="social__referral_wrapper">
                                <div className="social__referral_title">
                                        <p className={'text-heading-4'}>Refer via social media</p>
                                    </div>
                                    <div className="social__referral_elements">
                                        <a href={''} className="social__referral_element">
                                            <i className="ri-instagram-line"></i>
                                        </a>
                                        <a href={''} className="social__referral_element">
                                            <i className="ri-facebook-fill"></i>
                                        </a>
                                        <a href={''} className="social__referral_element">
                                            <i className="ri-twitter-x-line"></i>
                                        </a>
                                    </div>
                                    <div className="social__referral_link">
                                        <input type="text" style={{cursor: "pointer"}} onClick={event => copyToClipboard(event)}
                                               defaultValue={`${props.debugValue}/my-referral/${referralProfile.rlC}`}/>
                                    </div>
                                </div>


                                {/*<div className="invite__referrals_img">*/}
                                {/*    <img src={require('../../assets/media/pictures/static/financing_hero.jpg')}*/}
                                {/*         alt="#"/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </section>
                    <section className="referral__activity">
                        <div className="container_s">
                            <div className="referral__activity_wrapper">
                                <div className="referral__activity_text">
                                    <div className="referral__activity_title text-heading-4">
                                        Referral activity
                                    </div>
                                </div>
                                <div className="referral__activity_results">
                                    <div className="referral__activity_result">
                                        <div className="referral__activity_title text-heading-5">
                                            Total Attracted
                                        </div>
                                        <div className="referral__activity_numbers text-heading-4">
                                            {referredProfiles ? referredProfiles.length : 0}
                                        </div>
                                    </div>
                                    <div className="separator"></div>
                                    <div className="referral__activity_result">
                                        <div className="referral__activity_title text-heading-5">
                                            Confirmed
                                        </div>
                                        <div className="referral__activity_numbers text-heading-4">
                                            {referredProfiles ? referredProfiles.filter(item=>item.u_data).length : 0}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={'referred__table'}>
                        <div className="container">
                            <div className="referred__table_wrapper">
                                <div className="referred__table_title text-heading-4">
                                    Friends referred by email
                                </div>
                                <div className="referred__table_table">
                                    <table>
                                        <thead>
                                        <tr className={'text-heading-5'}>
                                            <td>Name</td>
                                            <td>Email</td>
                                            <td>Sent date</td>
                                            <td>Status</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {referredProfiles && referredProfiles.map((referredProfile, index) => (
                                            <tr key={index} className={'text-body-1'}>
                                                <td>{referredProfile.firstName ? referredProfile.firstName : `User #${index}`}</td>
                                                <td>{referredProfile.u_data? referredProfile.u_data : 'Not confirmed'}</td>
                                                <td>{referredProfile.created_at ? `${new Date(parseInt(referredProfile.created_at)).toLocaleDateString()}` : ``}</td>
                                                <td style={{color: `${referredProfile.u_data? 'var(--branding-primary)' : 'var(--branding-secondary)'}`}}>{referredProfile.u_data ? 'Received' : 'Pending'}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
        </>
    )
}

export default ReferralDashboard;