import React, {useState} from 'react';
import '../../assets/css/home.css'
import Global from "../shared/global";
import {Link} from "react-router-dom";
import Regions from "../shared/regions";
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
import {Helmet, HelmetProvider} from "react-helmet-async";

function HomeMain(props) {

    const [solutionsIndex, setSolutionsIndex] = useState('0')

    const handleLink = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    const handleSolutionsTab = (e) =>{
        const index = e.target.dataset.tabindex
        if (index){
            setSolutionsIndex(index)
        }
    }

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>SolarMax Technology | Leading Solar Energy Experts in California</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/`}/>
                    <link rel="preload" href="../../assets/media/pictures/static/home-hero.webp" as={'image'}/>
                </Helmet>
            </HelmetProvider>
            <section className={'hero hero___main'}>
                <div className="container_s">
                    <div className="hero__wrapper">
                        <div className="hero__text">
                            <div className="hero__text_sub text-heading-6 mb-2">
                                Empowering families and businesses since 2008
                            </div>
                            <div className="hero__text_title text-heading-1 mb-2">
                                Take your power back
                            </div>
                            <div className="hero__text_desc text-heading-4 mb-4">
                                Cut your bill, prevent black outs, and become energy independent. See how much solar can
                                save you
                            </div>
                        </div>
                        <div className="hero__cta">
                            <button className={'text-heading-6'} onClick={props.toggleForm}>Get your quote</button>
                        </div>
                    </div>

                </div>
            </section>

            <section className={'intro__home'}>
                <div className="container_s">
                    <div className="intro__wrapper">
                        <div className="intro__text mt-5">
                            <div className="intro__title section__title text-heading-4 mb-1">
                                SolarMax does solar different
                            </div>
                            <div className="intro__desc section__desc text-body-1">
                                We are publicly listed renewable energy company that has not lost our local touch. <br/> We
                                don't just sell solar systems; we show you how to power your life and get free from
                                rising energy costs.
                            </div>
                        </div>
                        <div className="intro__home_pros mt-5">
                            <div className="intro__home_pro">
                                <div className="home__pro_icon">
                                    <div className="pro__icon_inner">
                                        <i className="ri-time-line"></i>
                                    </div>
                                    <div className="pro__icon_outer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="50"
                                             viewBox="0 0 50 50" fill="none">
                                            <circle cx="25" cy="25" r="24" stroke="#ECBC14" strokeWidth="2"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="home__pro_title text-heading-5">
                                    16 years
                                </div>
                                <div className="home__pro-desc text-body-1">
                                    installing solar
                                </div>
                            </div>
                            <div className="intro__home_pro">
                                <div className="home__pro_icon">
                                    <div className="pro__icon_inner">
                                        <i className="ri-chat-heart-line"></i>
                                    </div>
                                    <div className="pro__icon_outer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="54"
                                             viewBox="0 0 50 54" fill="none">
                                            <path
                                                d="M1 2V51L14.2269 40.5004C14.4038 40.36 14.6229 40.2836 14.8487 40.2836H48C48.5523 40.2836 49 39.8359 49 39.2836V2C49 1.44772 48.5523 1 48 1H2C1.44772 1 1 1.44771 1 2Z"
                                                stroke="#ECBC14" strokeWidth="2"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="home__pro_title text-heading-5">
                                    15,000+
                                </div>
                                <div className="home__pro-desc text-body-1">
                                    satisfied clients
                                </div>
                            </div>
                            <div className="intro__home_pro">
                                <div className="home__pro_icon">
                                    <div className="pro__icon_inner">
                                        <i className="ri-star-line"></i>
                                    </div>
                                    <div className="pro__icon_outer" style={{top: '-2px', left: '-5px'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="58" height="56"
                                             viewBox="0 0 64 61" fill="none">
                                            <path
                                                d="M23.9444 21.6667L32 3L40.0556 21.6667L61 24.1014L46.5 38.7101L50.5278 59L32 48.4493L13.4722 59L17.5 38.7101L3 24.1014L23.9444 21.6667Z"
                                                stroke="#ECBC14" strokeWidth="2"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="home__pro_title text-heading-5">
                                    4.8 rating
                                </div>
                                <div className="home__pro-desc text-body-1">
                                    from our clients
                                </div>
                            </div>
                            <div className="intro__home_pro">
                                <div className="home__pro_icon">
                                    <div className="pro__icon_inner">
                                        <i className="ri-seedling-line" ></i>
                                    </div>
                                    <div className="pro__icon_outer" style={{top: '5px'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="42"
                                             viewBox="0 0 48 44" fill="none">
                                            <path
                                                d="M15.5 1.5C18.5 2 24.5 4.5 27 11.5C32.5436 8.20588 38.2676 9.5 40 9.5C41.7324 9.5 46 10 46 10C46 10 47 10.5 46.5 20.5C46 30.5 34.4671 32.6961 28.5 32.5V42.5L19.5 42.5167V27.0167C6.21832 27.6049 2 17 1.5 14C1 11 1 2.56863 1 1C1 1 12.5 1 15.5 1.5Z"
                                                stroke="#ECBC14" strokeWidth="2"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="home__pro_title text-heading-5">
                                    25 Mil. tons
                                </div>
                                <div className="home__pro-desc text-body-1">
                                    of CO2 removed
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={'solutions'}>
                <div className="container_s">
                    <div className="solutions__wrapper mt-5 mb-3">
                        <div className="solutions__text mb-3">
                            <div className="solutions__title section__title text-heading-4 mb-1">
                                Whatever the need, we've got you covered
                            </div>
                        </div>
                        <Splide aria-label="" hasTrack={false} className={'articles__dt'}
                                options={{
                                    arrows: true,
                                    autoPlay: false,
                                    loop: false,
                                    perPage: 1,
                                    gap: '32px',
                                    pagination: true
                                }}>
                            <SplideTrack className="solutions__benefits solutions__benefits_mobile">
                                <SplideSlide className={'article'}>
                                    <div className="solution__benefit">
                                        <div><p className={'tab__title text-heading-6'}>Home energy solutions</p>
                                            <div className="benefits__sub_active">
                                                <div className="benefits__sub_wrapper">
                                                    <div className="benefits__sub_img">
                                                        <img
                                                            src={require('../../assets/media/pictures/static/home/3.1-Covered-HES.webp')}
                                                            alt=""/>
                                                    </div>
                                                    <div className="benefits__sub_info">
                                                        <div className="tab__text">
                                                            <div className="tab__single_title mb-2">
                                                                Solar panels for Home
                                                            </div>
                                                            <div className="tab__single_desc mb-2">
                                                                Whether you want to cut energy costs, prevent blackouts,
                                                                or do your part to reduce carbon emissions, solar can
                                                                help power your life well into the future.
                                                            </div>
                                                            <div className="tab__cta">
                                                                <Link onClick={handleLink} to={'/homeowners/solar'}>Explore
                                                                    home solar</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide className={'article'}>
                                    <div className="solution__benefit">
                                        <div><p className={'tab__title text-heading-6'}>Commercial systems</p>
                                            <div className="benefits__sub_active">
                                                <div className="benefits__sub_wrapper">
                                                    <div className="benefits__sub_img">
                                                        <img
                                                            src={require('../../assets/media/pictures/static/home/3.2-Covered-Busi.webp')}
                                                            alt=""/>
                                                    </div>
                                                    <div className="benefits__sub_info">
                                                        <div className="tab__text">
                                                            <div className="tab__single_title mb-2">
                                                                Supercharge your bottomline
                                                            </div>
                                                            <div className="tab__single_desc mb-2">
                                                                If you need to reduce power costs, enhance operational
                                                                reliability, or demonstrate your brand's commitment to
                                                                sustainability, our clean energy solutions can
                                                                supercharge your business.
                                                            </div>
                                                            <div className="tab__cta">
                                                                <Link onClick={handleLink} to={'/businesses/solar'}>Explore
                                                                    commercial solar</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide className={'article'}>
                                    <div className="solution__benefit">
                                        <div><p className={'tab__title text-heading-6'}>Battery power storage</p>
                                            <div className="benefits__sub_active">
                                                <div className="benefits__sub_wrapper">
                                                    <div className="benefits__sub_img">
                                                        <img
                                                            src={require('../../assets/media/pictures/static/home/3.3-Covered-Battery.webp')}
                                                            alt=""/>
                                                    </div>
                                                    <div className="benefits__sub_info">
                                                        <div className="tab__text">
                                                            <div className="tab__single_title mb-2">
                                                                Invest in peace of mind
                                                            </div>
                                                            <div className="tab__single_desc mb-2">
                                                                Are you tired of peak power rates and power outages?
                                                                Backup power storage can ensure you have a reliable
                                                                supply of energy even when the grid goes down.
                                                            </div>
                                                            <div className="tab__cta">
                                                                <Link onClick={handleLink} to={'/homeowners/solar'}>Explore
                                                                    batteries</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide className={'article'}>
                                    <div className="solution__benefit">
                                        <div><p className={'tab__title text-heading-6'}>EV charging</p>
                                            <div className="benefits__sub_active">
                                                <div className="benefits__sub_wrapper">
                                                    <div className="benefits__sub_img">
                                                        <img
                                                            src={require('../../assets/media/pictures/static/home/3.4-Covered-EV.webp')}
                                                            alt=""/>
                                                    </div>
                                                    <div className="benefits__sub_info">
                                                        <div className="tab__text">
                                                            <div className="tab__single_title mb-2">
                                                                Fully charged in no time
                                                            </div>
                                                            <div className="tab__single_desc mb-2">
                                                                New EV chargers offer fast, cost-effective, reliable
                                                                power for any electric vehicle. Help ensure your fleet,
                                                                family, or customer is topped up and ready for the
                                                                journey ahead.
                                                            </div>
                                                            <div className="tab__cta">
                                                                <Link onClick={handleLink}
                                                                      to={'/businesses/ev-charger'}>Explore
                                                                    chargers</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide className={'article'}>
                                    <div className="solution__benefit">
                                        <div><p className={'tab__title text-heading-6'}>Solar LED</p>
                                            <div className="benefits__sub_active">
                                                <div className="benefits__sub_wrapper">
                                                    <div className="benefits__sub_img">
                                                        <img
                                                            src={require('../../assets/media/pictures/static/home/3.5-Covered-LED.webp')}
                                                            alt=""/>
                                                    </div>
                                                    <div className="benefits__sub_info">
                                                        <div className="tab__text">
                                                            <div className="tab__single_title mb-2">
                                                                Illuminate the path to sustainably
                                                            </div>
                                                            <div className="tab__single_desc mb-2">
                                                                Enhance visibility and safety while reducing energy
                                                                costs with highly efficient and highly visible LED
                                                                signage and solar powered street lighting. Our LED
                                                                division offers reliable, eco-friendly solutions that
                                                                demonstrate your commitment to a brighter future.
                                                            </div>
                                                            <div className="tab__cta">
                                                                <a target="_blank" rel="noreferrer" href="https://solarmaxled.com/">Explore
                                                                    LED solutions</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>

                            </SplideTrack>
                        </Splide>
                        <ul className="solutions__benefits solutions__benefits_desktop">
                            <li data-tabindex={0} onClick={event => handleSolutionsTab(event)}
                                className="solution__benefit">
                                <div><p className={'tab__title text-heading-6'} data-tabindex={0}>Home energy
                                    solutions</p>
                                    <div
                                        className={solutionsIndex !== "0" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="benefits__sub_wrapper">
                                            <div className="benefits__sub_img">
                                                <img
                                                    src={require('../../assets/media/pictures/static/home/3.1-Covered-HES.webp')}
                                                    alt=""/>
                                            </div>
                                            <div className="benefits__sub_info">
                                                <div className="tab__text">
                                                    <div className="tab__single_title mb-2">
                                                        Solar panels for Home
                                                    </div>
                                                    <div className="tab__single_desc mb-2">
                                                        Whether you want to cut energy costs, prevent blackouts, or do
                                                        your part to reduce carbon emissions, solar can help power your
                                                        life well into the future.
                                                    </div>
                                                    <div className="tab__cta">
                                                        <Link onClick={handleLink} to={'/homeowners/solar'}>Explore home
                                                            solar</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-tabindex={1} onClick={event => handleSolutionsTab(event)}
                                className="solution__benefit">
                                <div><p className={'tab__title text-heading-6'} data-tabindex={1}>Commercial systems</p>
                                    <div
                                        className={solutionsIndex !== "1" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="benefits__sub_wrapper">
                                            <div className="benefits__sub_img">
                                                <img
                                                    src={require('../../assets/media/pictures/static/home/3.2-Covered-Busi.webp')}
                                                    alt=""/>
                                            </div>
                                            <div className="benefits__sub_info">
                                                <div className="tab__text">
                                                    <div className="tab__single_title mb-2">
                                                        Supercharge your bottomline
                                                    </div>
                                                    <div className="tab__single_desc mb-2">
                                                        If you need to reduce power costs, enhance operational
                                                        reliability, or demonstrate your brand's commitment to
                                                        sustainability, our clean energy solutions can supercharge your
                                                        business.
                                                    </div>
                                                    <div className="tab__cta">
                                                        <Link onClick={handleLink} to={'/businesses/solar'}>Explore
                                                            commercial solar</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-tabindex={2} onClick={event => handleSolutionsTab(event)}
                                className="solution__benefit">
                                <div><p className={'tab__title text-heading-6'} data-tabindex={2}>Battery power
                                    storage</p>
                                    <div
                                        className={solutionsIndex !== "2" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="benefits__sub_wrapper">
                                            <div className="benefits__sub_img">
                                                <img
                                                    src={require('../../assets/media/pictures/static/home/3.3-Covered-Battery.webp')}
                                                    alt=""/>
                                            </div>
                                            <div className="benefits__sub_info">
                                                <div className="tab__text">
                                                    <div className="tab__single_title mb-2">
                                                        Invest in peace of mind
                                                    </div>
                                                    <div className="tab__single_desc mb-2">
                                                        Are you tired of peak power rates and power outages? Backup
                                                        power storage can ensure you have a reliable supply of energy
                                                        even when the grid goes down.
                                                    </div>
                                                    <div className="tab__cta">
                                                        <Link onClick={handleLink} to={'/homeowners/battery'}>Explore
                                                            batteries</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-tabindex={3} onClick={event => handleSolutionsTab(event)}
                                className="solution__benefit">
                                <div><p className={'tab__title text-heading-6'} data-tabindex={3}>EV charging</p>
                                    <div
                                        className={solutionsIndex !== "3" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="benefits__sub_wrapper">
                                            <div className="benefits__sub_img">
                                                <img
                                                    src={require('../../assets/media/pictures/static/home/3.4-Covered-EV.webp')}
                                                    alt=""/>
                                            </div>
                                            <div className="benefits__sub_info">
                                                <div className="tab__text">
                                                    <div className="tab__single_title mb-2">
                                                        Fully charged in no time
                                                    </div>
                                                    <div className="tab__single_desc mb-2">
                                                        New EV chargers offer fast, cost-effective, reliable power for
                                                        any electric vehicle. Help ensure your fleet, family, or
                                                        customer is topped up and ready for the journey ahead.
                                                    </div>
                                                    <div className="tab__cta">
                                                        <Link onClick={handleLink} to={'/businesses/ev-charger'}>Explore
                                                            chargers</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-tabindex={4} onClick={event => handleSolutionsTab(event)}
                                className="solution__benefit">
                                <div><p className={'tab__title text-heading-6'} data-tabindex={4}>Solar LED</p>
                                    <div
                                        className={solutionsIndex !== "4" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="benefits__sub_wrapper">
                                            <div className="benefits__sub_img">
                                                <img
                                                    src={require('../../assets/media/pictures/static/home/3.5-Covered-LED.webp')}
                                                    alt=""/>
                                            </div>
                                            <div className="benefits__sub_info">
                                                <div className="tab__text">
                                                    <div className="tab__single_title mb-2">
                                                        Illuminate the path to sustainably
                                                    </div>
                                                    <div className="tab__single_desc mb-2">
                                                        Enhance visibility and safety while reducing energy costs with
                                                        solar-powered LED signs and street lighting. Our LED team offers
                                                        reliable, eco-friendly solutions that demonstrate your
                                                        commitment to a brighter future.
                                                    </div>
                                                    <div className="tab__cta">
                                                        <a target={'_blank'} rel="noreferrer" href="https://solarmaxled.com/">Explore LED
                                                            solutions</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div style={{transform: `translateX(${solutionsIndex * 100}%)`}}
                                     className={'tab__active'}></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className={'why__home'}>
                <div className="container_s">
                    <div className="why__home_wrapper">
                        <div className="why__home_interactive">
                            <div className="why__interactive_title text-heading-4 mb-1">
                                Why customers love us
                            </div>
                            <div className="why__interactive_cta text-heading-7">
                                <Link to={'/reviews'} onClick={handleLink}>Read reviews <i
                                    className="ri-arrow-right-line"></i></Link>
                            </div>
                            <div className="why__interactive_img text-heading-7">
                                <img
                                    src={require('../../assets/media/pictures/static/home/4-Why-customers-love-us-(B).webp')}
                                    alt=""/>
                                <div className={'why__interactive_img_wrapper'}></div>
                            </div>
                        </div>

                        <div className="why__home_informative">
                            <div className="why__home_info">
                                <div className="why__info_icon">
                                    <i className="ri-checkbox-circle-line"></i>
                                </div>
                                <div className="why__info_text">
                                    <div className="why__info_title text-heading-5">
                                        Transparent offerings
                                    </div>
                                    <div className="why__info_desc text-body-1">
                                        There is a lot to consider when choosing to go solar, so we make sure the
                                        benefits, price and savings are clear from the start.
                                    </div>
                                </div>
                            </div>
                            <div className="why__home_info">
                                <div className="why__info_icon">
                                    <i className="ri-checkbox-circle-line"></i>
                                </div>
                                <div className="why__info_text">
                                    <div className="why__info_title text-heading-5">
                                        Top-quality products
                                    </div>
                                    <div className="why__info_desc text-body-1">
                                        From Enphase to Tesla, we are certified to install the best brands on the
                                        market, and our own high efficiency solar panels are assembled right here, in
                                        our historic Riverside headquarters.
                                    </div>
                                </div>
                            </div>
                            <div className="why__home_info">
                                <div className="why__info_icon">
                                    <i className="ri-checkbox-circle-line"></i>
                                </div>
                                <div className="why__info_text">
                                    <div className="why__info_title text-heading-5">
                                        Friendly, local service
                                    </div>
                                    <div className="why__info_desc text-body-1">
                                        Our systems experts are ready to answer any question or solve any issue. We are
                                        just a call away, or you can always stop by and say hi!
                                    </div>
                                </div>
                            </div>
                            <div className="why__home_info">
                                <Link to={'/about-us'} onClick={handleLink}>View company profile</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Regions/>
            <section className={'rebates__global'}>
                <div className="container">
                    <div className="rebates__wrapper">
                        <div className="rebates__title text-heading-4" style={{fontWeight: "bold"}}>
                            Special offers
                        </div>
                        <div className="rebates__desc text-body-1">
                            <Link to={'/special-offers'}>View all our promotional offers <i
                                className="ri-arrow-right-line"></i></Link>
                        </div>
                        <div className="rebates__offers">
                            <div className="rebates__offer">
                                <div className="rebates__offer_text">
                                    <div className="rebates__offer_title text-heading-3">
                                        Save up to 30% off
                                    </div>
                                    <div className="rebates__offer_desc text-body-1">
                                        Federal tax credits can take up to 30% off the total cost of your system. Lock
                                        your system today because these credits could change soon.
                                    </div>
                                    <div className="rebates__offer_cta">
                                        <a download href={'../assets/media/documents/tax-credit-brochure.pdf'}>Learn
                                            more</a>
                                    </div>

                                </div>
                                <div className="rebates__referral_picture">
                                    <img
                                        src={require('../../assets/media/pictures/static/homesolution/7.1-Tax-Credit.webp')}
                                        alt=""/>
                                </div>
                            </div>
                            <div className="rebates__offer_referral">
                                <div className="rebates__referral_text">
                                    <div className="rebates__offer_title text-heading-3">
                                        Go solar for less than $40/month
                                    </div>
                                    <div className="rebates__offer_desc text-body-1">
                                        Enjoy our best deal on our premier panels & microinverters with systems starting
                                        as low as $39.83 per month!
                                    </div>
                                    <div className="rebates__offer_cta">
                                        <Link to={'/special-offers'} onClick={handleLink}>Learn more</Link>
                                    </div>
                                </div>
                                <div className="rebates__referral_picture">
                                    <img
                                        src={require('../../assets/media/pictures/static/homesolution/7.2 40 deal image.webp')}
                                        alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Global debugValue={props.debugValue}/>

            <section className="homepage__cta">
                <div className="container">
                    <div className="cta__wrapper">
                        <div className="cta__title text-heading-3">
                            Start your solar journey with a free quote
                        </div>
                        <div className="cta__desc text-body-1">
                            SolarMax is dedicated to providing the highest quality energy alternatives at affordable
                            prices. Enjoy easy financing options and low monthly payments that make shifting to solar
                            energy simple and stress-free.
                        </div>
                        <div className="cta__cta text-heading-6">
                            <button className={'text-heading-6'} onClick={props.toggleForm}>Get a free quote</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    );
}

export default HomeMain;