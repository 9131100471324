import React, {useState} from 'react';

function WhyChooseSolarmax(props) {
    const [ctaHover, setCtaHover] = useState(false);

    const containerStyle = {
        fontFamily: "'Poppins', sans-serif",
        margin: 0,
        padding: 0,
        backgroundColor: "#f4f4f4",
        color: "#222",
    };

    const mainContentStyle = {
        maxWidth: "1440px",
        margin: "40px auto",
        background: "#fff",
        padding: "30px",
        borderRadius: "15px",
        boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
    };

    const headerStyle = {
        color: "#003366",
        textAlign: "center",
    };

    const textStyle = {
        lineHeight: "1.8",
        fontSize: "18px",
    };

    const ulStyle = {
        paddingLeft: "25px",
        lineHeight: "1.8",
        fontSize: "18px",
    };


    const ctaStyle = {
        display: "block",
        textAlign: "center",
        background: ctaHover
            ? "linear-gradient(135deg, #ff3c00, #ff8c00)"
            : "linear-gradient(135deg, #ff8c00, #ff3c00)",
        color: "white",
        padding: "18px 35px",
        textDecoration: "none",
        fontSize: "22px",
        borderRadius: "10px",
        margin: "40px auto",
        width: "300px",
        fontWeight: "bold",
        transition: "0.3s",
    };

    const contentSectionStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "40px 0",
        gap: "30px",
    };

    const contentSectionImgStyle = {
        width: "50%",
        height: "300px",
        objectFit: "cover",
        borderRadius: "10px",
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
    };

    const contentSectionPStyle = {
        width: "45%",
        fontSize: "20px",
    };

    const fullWidthImageStyle = {
        width: "100%",
        height: "350px",
        background: "linear-gradient(135deg, #ddd, #ccc)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "22px",
        color: "#444",
        margin: "40px 0",
        borderRadius: "10px",
    };


    return (
        <div style={containerStyle}>
            <div id="main-content" style={mainContentStyle}>
                <h1 style={headerStyle}>Power Your Future with SolarMax Technology</h1>
                <div style={fullWidthImageStyle}></div>
                <p style={textStyle}>
                    Discover why thousands of homeowners and businesses trust SolarMax for reliable,
                    affordable, and cutting-edge solar energy solutions.
                </p>

                <h2 style={headerStyle}>Why SolarMax?</h2>
                <div style={contentSectionStyle}>
                    <p style={contentSectionPStyle}>
                        Unlike other solar companies, SolarMax handles every step of the process in-house.
                        No middlemen, no unexpected costs—just premium solar technology at the best price.
                    </p>
                    <img
                        src="https://via.placeholder.com/500"
                        alt="Solar Panels"
                        style={contentSectionImgStyle}
                    />
                </div>

                <h2 style={headerStyle}>Unmatched Quality &amp; Reliability</h2>
                <div style={fullWidthImageStyle}></div>
                <ul style={ulStyle}>
                    <li>
                        One of only four US companies rated in the world's top tier of solar panel reliability.
                    </li>
                    <li>25-year solar panel power production guarantee.</li>
                    <li>
                        Assembled and engineered in our Southern California headquarters for total quality
                        control.
                    </li>
                    <li>25-year limited workmanship warranty.</li>
                    <li>
                        If your SolarMax system underperforms, we pay your utility charges. Guaranteed.
                    </li>
                </ul>

                <h2 style={headerStyle}>Real Customers, Real Satisfaction</h2>
                <div style={{ ...contentSectionStyle, flexDirection: "row-reverse" }}>
                    <p style={contentSectionPStyle}>
                        “From contract signing to activation, every step was seamless.”
                        <br />
                        <strong>— Joe Aceves, Corona</strong>
                    </p>
                    <img
                        src="https://via.placeholder.com/500"
                        alt="Happy Customer"
                        style={contentSectionImgStyle}
                    />
                </div>

                <h2 style={headerStyle}>Exclusive Offer – Lock in Your Savings</h2>
                <p style={textStyle}>
                    Solar panel system with inverter for just $59.99/month – Plus NEM 2.0 savings if you act now!
                </p>
                <a
                    href="#"
                    style={ctaStyle}
                    onMouseEnter={() => setCtaHover(true)}
                    onMouseLeave={() => setCtaHover(false)}
                >
                    GET YOUR FREE CONSULTATION
                </a>
            </div>
        </div>
    );
}

export default WhyChooseSolarmax
