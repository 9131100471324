import React from 'react';
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";
import Regions from "../../components/shared/regions";
import Global from "../../components/shared/global";
import FormQuote from "../../components/form/form-quote";

function SoNew4KwSystem(props) {

    return (
        <div>
            <Helmet>
                <title>Get a 4kW Solar System & 5kWh Battery for $79/Month | Best Solar Offer</title>
                <meta
                    name="description"
                    content="Lock in our best solar offer ever! Enjoy a premium 4kW solar system with a 5kWh battery for only $79/month, and we will cover the first year of monthly payments for FREE!"
                />
            </Helmet>
            <section className="article__main">
                <div className="container_s">
                    <div className="article__wrapper">
                        <div className="article__info">

                            <div className={'mb-2'}><Link to={'/special-offers'}><i className="ri-arrow-left-line"></i> Go
                                back</Link></div>
                                    <h1>Our Best Deal Ever: 4kW Solar System & 5kWh Battery for Just $79/Month</h1>

                            <div>
                                <p><img src="../../assets/media/pictures/static/offers/so-new4kw-system-2.jpg" alt=""/>
                                </p>
                                <p>If you have ever considered getting a solar system, now is the time to make your move
                                    by taking advantage of our best solar deal ever!</p>

                                <p>Get a premium 4kW solar system paired with a 5kWh battery for only $79 per month.
                                    Our system uses top-tier solar panels and high-efficiency microinverters to ensure
                                    your home is powered by clean, green, reliable energy. With the included 5kWh
                                    battery, you can store excess energy and use it during peak times or after dark to
                                    save even more.</p>

                                <p>Powering your home with clean energy and fighting climate change isn’t the only
                                    benefit; you’ll keep saving money every month by locking in your energy rate at just
                                    $0.145/kWh while utility companies keep constantly raising their prices.</p>

                                <p><b>But that’s not all… </b></p>

                                <p>We want as many people as possible to lock in this rate while it’s low, so but we are
                                    sweetening the deal by offering <b>the first 12 months of payments for FREE. </b>
                                </p>

                                <p>Here’s what you’ll get with this limited-time offer: </p>
                                <ul style={{listStyleType: "unset", paddingLeft: "25px"}}>
                                    <li style={{listStyleType: "unset"}}><p><b>4kW Solar System</b> featuring premium
                                        panels and microinverters, ensuring
                                        maximum energy production for your home. </p></li>
                                    <li style={{listStyleType: "unset"}}><p><b>5kWh Battery</b> to store energy for use
                                        during peak times or after dark, giving
                                        you more control over your energy use.</p></li>
                                    <li style={{listStyleType: "unset"}}><p><b>Lock in Your Rate</b> at just $0.145/kWh,
                                        significantly lower than the utility’s
                                        average rate of $0.40 to $0.70/kWh. </p></li>
                                    <li style={{listStyleType: "unset"}}><p><b>12 Months of FREE</b> payments: After the
                                        project is completed, SolarMax will
                                        send you a check covering your first year of payments—making the first 12 months
                                        of clean energy absolutely free.</p></li>
                                    <li style={{listStyleType: "unset"}}><p>Special <b> $0 down leasing agreements </b>
                                        available for qualified customers.</p></li>
                                </ul>
                                <p><img src="../../assets/media/pictures/static/offers/so-new4kw-system-3.jpg" alt=""/>
                                </p>

                                <p>Lock in this incredible offer today and start saving on energy costs in the months,
                                    years, and decades to come.
                                    <b> Just fill in the form on the right or give us a call to learn more.</b>
                                </p>

                                <b>Terms and Conditions: </b>
                                <ul style={{listStyleType: "unset", paddingLeft: "25px"}}>
                                    <li style={{listStyleType: "unset"}}><p><b>Site Evaluation and Additional Costs</b>:
                                        Additional costs may be required based on preexisting site conditions and will
                                        be subject to reassessment following the completion of a site evaluation. Any
                                        additional costs will be outlined before proceeding with the project. </p></li>
                                    <li style={{listStyleType: "unset"}}><p><b>Credit Approval Requirement</b>: Customer
                                        eligibility for this promotion is contingent upon passing a credit approval
                                        process.</p></li>
                                    <li style={{listStyleType: "unset"}}><p><b>Offer Restrictions</b>: This offer cannot
                                        be combined with other discounts, promotions, or specials unless explicitly
                                        stated otherwise. </p></li>
                                </ul>
                            </div>

                        </div>
                        <div className="article__interaction">
                            {/*<iframe className={"form__holder"}*/}
                            {/*        style={{width: '100%', height: '740px', position: 'sticky', top: '80px'}}*/}
                            {/*        id={'solarmax__form'}*/}
                            {/*        src={`https://4345356.extforms.netsuite.com/app/site/crm/externalleadpage.nl?compid=4345356${props.form_source}`}*/}
                            {/*        frameBorder="0"></iframe>*/}
                            <FormQuote
                                debugValue={props.debugValue}
                                bd={props.bd}
                                formSource={"contact"}
                            />
                            {/*<FormQuote/>*/}

                        </div>
                    </div>
                </div>
            </section>

            <Regions/>

            <Global debugValue={props.debugValue}/>

            <section className="homepage__cta">
                <div className="container_s">
                    <div className="cta__wrapper">
                        <div className="cta__title text-heading-3">
                            Start today with a free quote from our solar experts
                        </div>
                        <div className="cta__desc text-body-1">
                            SolarMax is dedicated to providing the highest quality energy alternatives at affordable
                            prices. Enjoy easy financing options and low monthly payments that make reinforcing your
                            solar energy systems simple and stress-free.
                        </div>
                        <div className="cta__cta text-heading-6">
                            <button className={'text-heading-6'} onClick={props.toggleForm}>Get your quote</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SoNew4KwSystem;