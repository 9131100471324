'use client';

import axios from 'axios';

export class ReferralStorage {
    constructor(referral) {
        this.rlC = referral.code;
        this.rlId = referral.id;
        this.rlE = referral.email;
        this.rlN = referral.firstname;
        this.rlS = referral.lastname;
        this.rrC = null;
    }

    static Create(referral){
        if (referral) {
            return new this(
                referral.code,
                referral.id,
                referral.email,
                referral.firstname,
                referral.lastname,
                null,
            )
        }
    }

}

export const getReferral = async function(props){
    let tmp_data = JSON.parse(localStorage.getItem('referral'))

    const formData = new FormData();
    formData.append('referral', JSON.stringify({
        u_data: tmp_data.rlEmail,
        firstname:tmp_data.rlName,
        lastname:tmp_data.rlSurname,
        informative: props.bd
    }));

    let res = await axios.post(`${props.debugValue}/api/referral/get-referral`, formData, {
        headers: {
            'Authorization': 'Bearer ' + props.bd.t,
            "Content-Type": "application/json"
        }
    }).catch(err=>alert("Something went wrong! Please try again."))

    if (res && res.data.status !== 400){
        return res.data
    }else{
        return null
    }
}

export const deleteReferral = () =>{
    localStorage.removeItem('referral')
}
export const createReferral = async function(firstName, lastName, email, props, referred_id){

    const formData = new FormData();
    formData.append('referral', JSON.stringify({
        u_data: email,
        firstname:firstName,
        lastname:lastName,
        informative: props.bd,
        referrer_id: referred_id ? referred_id : null
    }));

    let res = await axios.post(`${props.debugValue}/api/referral/create`, formData, {
        headers: {
            'Authorization': 'Bearer ' + props.bd.t,
            "Content-Type": "application/json"
        }
    }).catch(err=>alert("Something went wrong! Please try again."))

    if (res && res.data.status !== 400){
        return res.data
    }else{
        return null
    }
}

export const referFriend = async function(firstName, lastName, email, props) {
    let tmp_data = JSON.parse(localStorage.getItem('referral'))

    const referrer = tmp_data ? tmp_data.rlId : null
    if (referrer !== null){
        const formData = new FormData();
        formData.append('referral', JSON.stringify({
            u_data: email,
            firstname:firstName,
            lastname:lastName,
            referrer_id: referrer,
            informative: props.bd
        }));

        let res = await axios.post(`${props.debugValue}/api/referral/create`, formData, {
            headers: {
                'Authorization': 'Bearer ' + props.bd.t,
                "Content-Type": "application/json"
            }
        }).catch(err=>alert("Something went wrong! Please try again."))
        if (res && res.status === 200){
            return res
        }else{
            return null
        }

    }else{
        return null
    }
}

export const referFriendFull = async function(rInfoFull, props) {
    const {
        rlFirstName,
        rlLastName,
        rlEmail,
        rlPhone,
        firstName,
        lastName,
        email,
        phone
    } = rInfoFull.current
    const formData = new FormData();
    formData.append('referral', JSON.stringify({
        rl_u_data: rlEmail,
        rl_firstname:rlFirstName,
        rl_lastname:rlLastName,
        rl_phone:rlPhone,
        u_data: email,
        firstname:firstName,
        lastname:lastName,
        phone:phone,
        informative: props.bd
    }));

    let res = await axios.post(`${props.debugValue}/api/referral/create-full`, formData, {
        headers: {
            'Authorization': 'Bearer ' + props.bd.t,
            "Content-Type": "application/json"
        }
    }).catch(err=>alert("Something went wrong! Please try again."))
    if (res && res.status === 200){
        return res
    }else{
        return null
    }
}

export const checkReferral = async function(props, referral_id){

    const formData = new FormData();
    formData.append('referral', JSON.stringify({
        r_id: referral_id,
        informative: props.bd
    }));

    let res = await axios.post(`${props.debugValue}/api/referral/check-referral`, formData, {
        headers: {
            'Authorization': 'Bearer ' + props.bd.t,
            "Content-Type": "application/json"
        }
    }).catch(err=>alert("Something went wrong! Please try again."))

    if (res && res.data.status !== 400){
        return res.data
    }else{
        return null
    }
}