import React, {useEffect, useState} from 'react';
import Global from "../shared/global";
import {Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";

function Roofing(props) {
    const [benefitIndex, setBenefitIndex] = useState('0')
    const [sliderValue, setSliderValue] = useState("260")

    const setDefaultSlider = (e) =>{
        let slider = document.getElementById('slider')
        let selector = document.getElementById('selector')
        let selectValue = document.getElementById('selectValue')
        let progressBar = document.getElementById('progressBar')

        selectValue.innerHTML = "$ "+slider.value
        selector.style.left = slider.value/10 + '%'
        progressBar.style.width = slider.value/10 + '%'
    }
    useEffect(()=>{
        setDefaultSlider()
    },[])
    const handleInput = (e) =>{
        setSliderValue(e.target.value)
        setDefaultSlider()
    }

    const handleSubmitCta = (e) => {
        e.preventDefault()
    }
    const handleBenefitChange = (e) =>{
        let index = e.target.dataset.benefit_index
        setBenefitIndex(index)
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Homeowners - Roofing</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/roofing`}/>
                </Helmet>
            </HelmetProvider>
            <section className="global__hero_single" style={{backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url('${require('../../assets/media/pictures/static/roofing_hero.jpg')}')`, backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition:'center'}}>
                <div className="container_s" style={{height: '100%'}}>
                    <div className="single__hero_wrapper">
                        <div className="single__hero_text">
                            <div className="single__hero_title text-heading-3">Your Trusted Local Roofing Company</div>
                            <div className="single__hero_desc  text-heading-5">Enjoy top-rated roofing solutions, high-quality products, and unmatched customer service</div>
                            <div className="single__hero_cta">
                                <a href="">Explore more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="solution__intro_custom_light">
                <div className="container">
                    <div className="solution__intro_wrapper">
                        <div className="solution__intro_text">
                            <div className="solution__intro_title text-heading-4">Roof replacement by local experts</div>
                            <div className="solution__intro_desc text-body-1">SolarMax Technology offers top roof replacements and solar roofing in Southern California. As local industry experts, our solar roofing company helps homeowners get the best systems for their unique homes.</div>
                        </div>
                        <div className="solution__intro_img">
                            <img src={require('../../assets/media/pictures/static/roofing-intro.jpg')} alt=""/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="solution__advantages_light">
                <div className="container">
                    <div className="solution__advantages_wrapper">
                        <div className="solution__advantages_text mb-3">
                            <div className="solution__advantages_title text-heading-4 mb-1">Why choose our local roofing contractors</div>
                            <div className="solution__advantages_desc text-body-1">Protect your home and enhance your curb appeal with a durable new roof, expertly installed by our experienced local roofing contractors</div>

                        </div>
                        <div className="solution__advantages_elements">
                            <div className="solution__advantages_element">
                                <div className="advantages__element_icon"><i className="ri-home-gear-line"></i></div>
                                <div className="advantages__element_title text-heading-6 mb-1">Full-service care</div>
                                <div className="advantages__element_desc text-body-2">Throughout the entire process, our professionals provide premium products, services, and customer support.</div>
                            </div>
                            <div className="solution__advantages_element">
                                <div className="advantages__element_icon"><i className="ri-tools-line"></i></div>
                                <div className="advantages__element_title text-heading-6 mb-1">Industry expertise</div>
                                <div className="advantages__element_desc text-body-2">With more than ten years of roofing expertise, we're the trusted professionals for your project.</div>
                            </div>
                            <div className="solution__advantages_element">
                                <div className="advantages__element_icon"><i className="ri-hashtag"></i></div>
                                <div className="advantages__element_title text-heading-6 mb-1">Superior roofing systems</div>
                                <div className="advantages__element_desc text-body-2">We are proud to collaborate with top brands for high-quality roof systems, tailored to each customer's needs.</div>
                            </div>
                            {/*<div className="solution__advantages_element">*/}
                            {/*    <div className="advantages__element_icon"><i className="ri-lightbulb-flash-line"></i></div>*/}
                            {/*    <div className="advantages__element_title text-heading-6 mb-1">Energy independence</div>*/}
                            {/*    <div className="advantages__element_desc text-body-2">Avoid outages and costly peak times with an affordable power storage system</div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </section>

            <section className="solutions__benefits_custom">
                <div className="container">
                    <div className="solutions__benefits_custom_wrapper">
                        <div className="solutions__benefits_text">
                            <div className="solutions__benefits_title text-heading-4">Roofing plan that suits your home's unique requirements</div>
                            <div className="solutions__benefits_elements">
                                <div className={'benefits__progress'}></div>
                                <div data-benefit_index={'0'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '0' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'0'} className="benefits__element_num text-heading-6">1.</div>
                                    <div data-benefit_index={'0'} className="benefits__element_text">
                                        <div data-benefit_index={'0'} className="benefits__element_title text-heading-6">High-quality replacements</div>
                                        <div data-benefit_index={'0'}  className={`text-body-1 ${benefitIndex === '0' ? 'benefits__element_active' : 'benefits__element_hidden'} `}>
                                            SolarMax provides durable roofing systems for homeowners, ensuring reliability and long-lasting protection.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '0' ? 'icon__active' : 'benefits__element_icon'}`}>
                                        <i data-benefit_index={'0'} className="ri-arrow-down-s-line "></i>
                                    </div>
                                </div>
                                <div data-benefit_index={'1'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '1' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'1'} className="benefits__element_num text-heading-6">2.</div>
                                    <div data-benefit_index={'1'} className="benefits__element_text">
                                        <div data-benefit_index={'1'} className="benefits__element_title text-heading-6">Efficient solar solutions</div>
                                        <div data-benefit_index={'1'}  className={`text-body-1 ${benefitIndex === '1' ? 'benefits__element_active' : 'benefits__element_hidden'} `}>
                                            Southern California homeowners can rely on SolarMax's solar roofing for energy cost reduction.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '1' ? 'icon__active' : 'benefits__element_icon'}`}>
                                        <i data-benefit_index={'1'}  className="ri-arrow-down-s-line"></i>
                                    </div>
                                </div>
                                <div data-benefit_index={'2'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '2' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'2'} className="benefits__element_num text-heading-6">3.</div>
                                    <div data-benefit_index={'2'} className="benefits__element_text">
                                        <div data-benefit_index={'2'} className="benefits__element_title text-heading-6">Local expertise</div>
                                        <div data-benefit_index={'2'}  className={`text-body-1 ${benefitIndex === '2' ? 'benefits__element_active' : 'benefits__element_hidden'} `}>
                                            SolarMax understands the unique needs of Southern California's climate and architectural styles.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '2' ? 'icon__active' : 'benefits__element_icon'}`}>
                                        <i data-benefit_index={'2'}  className="ri-arrow-down-s-line"></i>
                                    </div>
                                </div>
                                <div data-benefit_index={'3'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '3' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'3'} className="benefits__element_num text-heading-6">4.</div>
                                    <div data-benefit_index={'3'}className="benefits__element_text">
                                        <div data-benefit_index={'3'} className="benefits__element_title text-heading-6">Enhanced appeal and value</div>
                                        <div data-benefit_index={'3'}  className={`text-body-1 ${benefitIndex === '3' ? 'benefits__element_active' : 'benefits__element_hidden'} `}>
                                            SolarMax helps homeowners boost their homes' attractiveness, value, and energy efficiency.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '3' ? 'icon__active' : 'benefits__element_icon'}`}>
                                        <i data-benefit_index={'3'}  className="ri-arrow-down-s-line"></i>
                                    </div>
                                </div>
                                <div data-benefit_index={'4'} onClick={event => handleBenefitChange(event)} className={`solutions__benefits_element  ${benefitIndex === '4' ? 'solutions__benefits_active' : 'solutions__benefits_inactive'}`}>
                                    <div data-benefit_index={'4'} className="benefits__element_num text-heading-6">5.</div>
                                    <div data-benefit_index={'4'}  className={`benefits__element_text `}>
                                        <div data-benefit_index={'4'} className="benefits__element_title text-heading-6">Trusted service</div>
                                        <div data-benefit_index={'4'}  className={`text-body-1 ${benefitIndex === '4' ? 'benefits__element_active' : 'benefits__element_hidden'}`}>
                                            SolarMax delivers professional and dependable roofing solutions to homeowners in Southern California.
                                        </div>
                                    </div>
                                    <div className={`${benefitIndex === '4' ? 'icon__active' : 'icon__inactive'}`}>
                                        <i data-benefit_index={'4'}  className="ri-arrow-down-s-line"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="solutions__benefits_img">
                            <img src={require(`../../assets/media/pictures/static/roofing/roofing_benefits_${benefitIndex}.jpeg`)} alt=""/>
                        </div>
                    </div>
                </div>
            </section>

            <section className={'rebates__global'}>
                <div className="container">
                    <div className="rebates__wrapper">
                        <div className="rebates__title text-heading-4" style={{fontWeight: "bold"}}>
                            Special offers
                        </div>
                        <div className="rebates__desc text-body-1">
                            <Link to={'/special-offers'}>View all our promotional offers <i className="ri-arrow-right-line"></i></Link>
                        </div>
                        <div className="rebates__offers">
                            <div className="rebates__offer">
                                <div className="rebates__offer_text">
                                    <div className="rebates__offer_title text-heading-3">
                                        Save up to 30% off
                                    </div>
                                    <div className="rebates__offer_desc text-body-1">
                                        Federal tax credits can take up to 30% off the total cost of your system. Lock your system today because these credits could change soon.
                                    </div>
                                    <div className="rebates__offer_cta">
                                        <a href="">Learn more</a>
                                    </div>

                                </div>
                                <div className="rebates__referral_picture">
                                    <img src={require('../../assets/media/pictures/static/homesolution/7.1-Tax-Credit.jpeg')} alt=""/>
                                </div>
                            </div>
                            <div className="rebates__offer_referral">
                                <div className="rebates__referral_text">
                                    <div className="rebates__offer_title text-heading-3">
                                        Go solar for less than $40/month
                                    </div>
                                    <div className="rebates__offer_desc text-body-1">
                                        Enjoy our best deal on our premier panels & microinverters with systems starting as low as $39.83 per month!
                                    </div>
                                    <div className="rebates__offer_cta">
                                        <a href="">Learn more</a>
                                    </div>
                                </div>
                                <div className="rebates__referral_picture">
                                    <img src={require('../../assets/media/pictures/static/homesolution/7.2 40 deal image.jpg')} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="calculator__cta">
                <div className="container">
                    <div className="calculator__cta_wrapper">
                        <form className="calculator__cta_interact" method={'POST'} onSubmit={event => handleSubmitCta(event)}>
                            <div className="calculator__cta_title text-heading-3 mb-1">Unlock your savings potential</div>
                            <div className="calculator__cta_desc text-body-1">Solar calculator to assess and optimize your energy savings.</div>
                            <div className="calculator__cta_input">
                                <input type="range" min="0" max="1000" value={sliderValue} id="slider" step={'10'} onInput={event =>handleInput(event) }/>
                                <div id="selector">
                                    <div className="selectBtn"></div>
                                    <div id="selectValue" className={"text-body-1"}></div>
                                </div>
                                <div id="progressBar"></div>
                                <div id="progressBackground"></div>
                                <div className="input__range"><span>$0</span><span>$1000</span></div>
                            </div>
                            <div className="calculator__cta_cta"><button className={'text-heading-7'} type="submit">Calculate</button></div>
                        </form>
                        <div className="calculator__cta_image">
                            <img src={`${require('../../assets/media/pictures/static/homesolution/8. Potential Savings.jpg')}`} alt=""/>
                        </div>
                    </div>
                </div>
            </section>

            <Global debugValue={props.debugValue}/>

        </>
    );
}

export default Roofing;