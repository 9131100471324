import React from 'react';

function SolarTaxCredit(props) {
    return (
        <div>
            <div className="position-relative">


                <figure className="page-hero page-hero-form">
                    <div className="div-table">
                        <div className="container hero-container">
                            <div className="hero-row">
                                <figcaption className="hero-inner">


                                    <div className="h1">Save With Solar Tax Credit</div>
                                    <div className="lead">The federal solar tax credit offers big savings to homeowners.
                                        Learn how to take advantage of this limited-time benefit.
                                    </div>

                                </figcaption>
                            </div>
                        </div>
                    </div>
                    <picture>
                        <img className="page-hero-bg" src="/img/page/bg-solar-tax-credit.jpg" alt="Background Photo"/>
                    </picture>
                </figure>
            </div>
            <div className="main-content">

                <div className="page-section offer-content-section">
                    <div className="section-header">


                        <h1>Federal Solar Tax Credit</h1>

                        <p className="lead">The federal solar tax credit is a tax credit that you can receive when
                            filing your annual tax return. The tax credit equals 26% (until the end of 2022) of the
                            total cost of your home solar energy system. For example, if your solar energy system costs
                            you a total of $20,000 then you can expect to apply $5,200 in credit to your tax return.</p>
                        <p className="lead">There is no limit to the amount you can claim on this credit. Whether your
                            solar system costs $10,000 or $10,000,000, if you meet the eligibility criteria, you can
                            claim your 26% tax credit on this amount.</p>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">

                                <div className="page-offers-section">
                                    <div className="offer-content mx-auto p-5">
                                        <div className="offer-panel text-center">
                                            <div className="panel panel-default">
                                                <a href="/offers/solar-special/">
                                                    <div className="panel-body match-height" >
                                                        <p className="offer-title h4">Home Solar Systems Starting at
                                                            Less Than $40/month</p>
                                                        <p>Enjoy top savings on our premier Solar
                                                            Panels &amp; Microinverters - starting at $39.83/month!</p>
                                                    </div>
                                                </a>
                                                <div className="panel-footer">
                                                    <a className="btn btn-info btn-custom ellipsis"
                                                       href="/offers/solar-special/">Save On Solar</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="page-offers-section mt-4">
                                    <div className="offer-content mx-auto p-5">
                                        <div className="offer-panel text-center">
                                            <div className="panel panel-default">
                                                <a href="/solar-products/electric-car-charging/">
                                                    <div className="panel-body match-height" >
                                                        <p className="offer-title h4">EV Charging Station at Home</p>
                                                        <p>Solar car charging inverters save you even more time and
                                                            money, with full visibility and control in the mobile
                                                            app.</p>
                                                    </div>
                                                </a>
                                                <div className="panel-footer">
                                                    <a className="btn btn-info btn-custom ellipsis"
                                                       href="/solar-products/electric-car-charging/">Learn More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6 content-section">
                                <h2>Contents</h2>
                                <h3><a href="#About_the_federal">About the federal solar tax credit</a></h3>
                                <ul>
                                    <li><a href="#eligibility">Eligibility for the solar tax credit</a></li>
                                    <li><a href="#residential">Residential qualifications</a></li>
                                    <li><a href="#businessqual">Business qualifications</a></li>
                                    <li><a href="#taxcredit">What does the tax credit cover?</a></li>
                                    <li><a href="#byyear">Tax credit rates by year</a></li>
                                </ul>
                                <h3><a href="#howto">How to use your tax credit</a></h3>
                                <ul>
                                    <li><a href="#whatyear">What year is my solar tax credit valid for?</a></li>
                                    <li><a href="#isnot">What the solar energy tax credit is NOT:</a></li>
                                    <li><a href="#whatis">What exactly is the federal solar tax credit?</a></li>
                                    <li><a href="#howmuch">Is there an easy way to tell how much of the federal solar
                                        tax credit I can use?</a></li>
                                </ul>

                                <h3><a href="#estimate">How to estimate your solar tax credit return for this year</a>
                                </h3>
                                <ul>
                                    <li><a href="#easyway">Is there an easy way to tell how much of the tax credit I can
                                        use?</a></li>
                                    <li><a href="#download">Download the 2019 Federal Solar Tax Credit Guide with the
                                        Tax Credit Impact Estimator</a></li>
                                    <li><a href="#help">Need further assistance from a qualified renewable energy
                                        expert</a>?
                                    </li>
                                </ul>
                                <div className="disclaimer-text p-4 text-center">
                                    <p><strong>Disclaimer:</strong> Keep in mind that we, at SolarMax Technology, are
                                        not tax professionals. We are not qualified to provide homeowners with tax
                                        advice. Always seek the advice of a tax professional to determine how to best
                                        utilize any federal solar investment tax credit that you may be eligible to
                                        receive.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about-section">
                    <div className="section-header">
                        <h2 className="section-title">About The Federal Solar Tax Credit</h2>
                        <p className="lead">There can be a considerable amount of confusion and misunderstanding
                            regarding the Federal Solar Investment Tax Credit. While the concept seems simple, it may be
                            complicated because everyone’s tax situation is different. Since there is no universal
                            answer that applies to all solar owners let’s explore your options.</p>
                    </div>
                    <div className="container-fluid p-0">
                        <div className="row no-gutter">
                            <div className="col-md-4 match-height eligibility-box" style={{height: "479px"}}>
                                <div className="div-table">
                                    <div className="div-table-cell">
                                        <div className="content mx-auto">
                                            <h2>Eligibility for the solar tax credit</h2>
                                            <p>Before you can claim the federal solar tax credit you need to meet the
                                                eligibility requirements like most credits or rebates. The requirements
                                                are different depending on whether you are installing solar onto your
                                                residence or your business.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 match-height residential-qualifications" style={{height: "479px"}}>
                                <div className="div-table">
                                    <div className="div-table-cell">
                                        <div className="content mx-auto">
                                            <h2>Residential qualifications</h2>
                                            <p>Your solar energy system must be providing energy to your home, be
                                                approved and in service.</p>
                                            <p>All local and utility fire and electrical code rules must be met.</p>
                                            <p>Your system must be installed and operating after January 1, 2006 and on,
                                                or before, December 31, 2021.</p>
                                            <p>You must own your system by purchasing or financing it. A lease or power
                                                purchase agreement (PPA) does not qualify you for the tax credit.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 match-height business-qualifications" style={{height: "479px"}}>
                                <div className="div-table">
                                    <div className="div-table-cell">
                                        <div className="content mx-auto">
                                            <h2>Business qualifications</h2>
                                            <p>Your system must be installed and operating after January 1, 2006 and on,
                                                or before, December 31, 2021.</p>
                                            <p>Your solar energy system equipment must be new, not used.</p>
                                            <p>Your solar energy system must be used by someone that is subject to U.S.
                                                income taxes. With the exclusion of tax-exempt organizations like
                                                churches, charities or schools.</p>
                                            <p>Your solar energy system must be located in the United States.</p>
                                            <p>The electricity produced can’t be used to heat a swimming pool.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="solar-tax-credit-section">
                    <div className="container-fluid p-0">
                        <div className="row no-gutter">
                            <div className="col-md-7 match-height tax-credit-content" style={{height: "475px"}}>
                                <div className="creddit-inner mx-auto p-4">
                                    <h2>What Does The Tax Credit Cover?</h2>
                                    <p>The federal solar tax credit is based on the entire cost of your solar energy
                                        system including the costs of:</p>
                                    <ul>
                                        <li>The solar panels, inverters and all materials</li>
                                        <li>All labor installation charges</li>
                                        <li>Roof repairs necessary to install solar (not the entire roof, just the
                                            side(s) that the solar panels are installed on)
                                        </li>
                                        <li>A home battery if it is charged solely by the solar panels</li>
                                        <li>Any upgrades or home modifications required to be compliant with local code
                                            such as main service panel upgrades, trenching, relocation of meters or
                                            other appliances.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-5 match-height text-center" style={{height: "475px"}}>
                                <div className="div-table">
                                    <div className="div-table-cell">
                                        <div className="table-wrap mx-auto p-4">
                                            <h2>Solar Tax Credit Rate 30%</h2>
                                            <p>The federal tax credit is at its highest rate ever: 30%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section solar-tax-cta text-center">
                    <div className="container">
                        <h2>Invest In Your Home With Solarmax Technology!</h2>
                        <a href="#price-quote" className="btn btn-primary btn-custom scroll-to">Free Consultation for
                            Home Solar</a>
                    </div>
                </div>

                <div className="tax-credit-section pt-5">
                    <div className="section-header">
                        <h2 className="section-title">How To Use Your Tax Credit</h2>
                    </div>
                    <div className="container-fluid p-0">
                        <div className="row no-gutter">
                            <div className="col-md-6 match-height credit-area" style={{height: "457px"}}>
                                <div className="content-area mx-auto p-4">
                                    <h2>What year is my solar tax credit valid for?</h2>
                                    <p>The IRS says that you can claim your solar tax credit on the date your system
                                        installation is complete and becomes active.</p>
                                    <p>When you get solar installed on your home, an inspector from the utility company
                                        comes out for the final inspection and will give you Permission to Operate
                                        (PTO). The date you receive PTO is the date your system’s installation is
                                        complete and you use this date to determine which year you can begin to claim
                                        your solar tax credit in.</p>
                                    <p>Your tax credit can be carried forward to the next year. So if you don’t use 100%
                                        the first year, IRS Form 5695 states you can use the remaining balance the next
                                        year. However, it is not yet clear whether you can carry an unused solar tax
                                        credit to years after the solar credit expires in 2022.</p>
                                </div>
                            </div>
                            <div className="col-md-6 match-height tax-cedit" style={{height: "457px"}}>
                                <div className="div-table">
                                    <div className="div-table-cell">
                                        <div className="content-area mx-auto p-4">
                                            <img className="img-responsive" src="/img/page/icon-stop.png" alt="ICON"/>
                                            <h2>What the solar energy tax credit is NOT:</h2>
                                            <p>It is not a rebate</p>
                                            <p>It is not a check</p>
                                            <p>It is not an itemized deduction</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="federal-solar">
                    <div className="container-fluid p-0">
                        <div className="row no-gutter">
                            <div className="col-md-5 match-height cta-federal-solar" style={{height: "550px"}}>
                                <div className="div-table">
                                    <div className="div-table-cell">
                                        <div className="content-section mx-auto p-4">
                                            <h2>What exactly is the federal solar tax credit?</h2>
                                            <p>The federal solar tax credit is a credit that can be used to offset your
                                                federal income tax liability.</p>
                                            <a href="#price-quote" className="btn btn-primary btn-custom mt-3">Schedule
                                                Your Solar Consultation</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 match-height" style={{height: "550px"}}>
                                <div className="div-table">
                                    <div className="div-table-cell">
                                        <div className="content-federal-solar p-5">
                                            <h3>This means that you must owe taxes in order to utilize the tax
                                                credit.</h3>
                                            <ul>
                                                <li>Having a tax liability does not mean that you have to write a check
                                                    when you submit your tax return. It means that the IRS keeps some of
                                                    the income tax that was withheld or paid.
                                                </li>
                                                <li>Getting a refund does not mean that you do not owe taxes. It means
                                                    that more taxes were collected by the IRS than what you ended up
                                                    owing.
                                                </li>
                                                <li>Not having to write a check to the IRS when you submit your tax
                                                    return to the IRS does not mean that you do not owe taxes.
                                                </li>
                                                <li>The amount of any federal solar tax credit that is used cannot
                                                    exceed the total tax liability for that year.
                                                </li>
                                                <li>In other words, you cannot use a credit for more than what you
                                                    owe.
                                                </li>
                                                <li>You may be able to use some of your tax credit now, and some
                                                    later.
                                                </li>
                                                <li>You may be able to carry over the tax credit to another year and use
                                                    it when you have a tax liability in a future year.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section pdf-solar-tax-credit">
                    <div className="section-header">
                        <h2 className="section-title">How To Estimate Your Solar Tax Credit Return For This Year</h2>
                    </div>
                    <div className="container">
                        <div className="col-md-8 match-height" style={{height: "326px"}}>
                            <h3>Is there an easy way to tell how much of the tax credit I can use?</h3>
                            <p>No. The easiest way is to consult your tax professional. However, SolarMax Technology has
                                developed the Tax Credit Impact Estimator to help customers understand how the tax
                                credit may affect them by showing how it might have affected their taxes in the previous
                                year.</p>
                            <p>If your taxes in the year of installation are going to be the same as last year, then you
                                may get a good idea how the tax credit affects you for this year.</p>
                            <p>Give SolarMax Technology a call or fill out the request form to get started today.</p>
                        </div>
                        <div className="col-md-4 match-height text-center" style={{height: "326px"}}>
                            <img className="img-responsive center-block mb-2" src="/img/page/icon-pdf.png" alt="PDF"/>
                            <p>Your eBook "Federal Solar Investment Tax Credit: A Practical Guide" is available for
                                download:</p>
                            <a className="download" href="/pdf/tax-credit-brochure.pdf" target="_blank">Download</a>
                        </div>
                    </div>
                </div>


            </div>
            
        </div>
    );
}

export default SolarTaxCredit;
