import '../../assets/css/form-estimate.css'

import {
    AdvancedMarker,
    APIProvider,
    Map,
    useAdvancedMarkerRef,
    useMap,
    useMapsLibrary
} from '@vis.gl/react-google-maps';

import React, {useEffect, useRef, useState} from "react";
import {Form, useLocation} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {handleSolarEstimatorSubmit} from "../../fetch/form"
import FormEstimateComplete from "./form-estimate-complete";
import {hints} from "../../lib/solar-estimator-hints"

const FormEstimate = (props) => {
    const [source, setSource] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [calculationResult, setCalculationResult] = useState(null)
    const [formDetails, setFormDetails] = useState({
        initialWidth: 0,
        offset: 0,
        step: 1
    });
    const [status, setStatus] = useState(301);
    const [modal, setModal] = useState({
        modal: false,
        type: ''
    })
    const [formData, setFormData] = useState({
        monthlyBill: 200,
        electricityRate: 0.30,
        date: '',
        phone: '',
        firstname: '',
        lastname: '',
        u_data: '',
        ev: "false",
        ev_count: "0",
        address: '',
        reasons: [],
        type: '',
        // interests: [],
        daytime: [],
        informative: props.bd
    })
    const [stepper, setStepper] = useState({
        monthlyBill: false,
        electricityRate: false,
        date: false,
        phone: false,
        firstname: false,
        lastname: false,
        u_data: false,
        ev: false,
        ev_count: false,
        address: false,
        reasons: false,
        type: false
    })
    const [markerRef, marker] = useAdvancedMarkerRef();

    useEffect(()=>{
        let tmp_stepper = stepper
        let keys = Object.keys(tmp_stepper);

        keys.forEach(key => {
            tmp_stepper[key] = !!(formData[key] && formData[key].length > 0);
        })

        setStepper(tmp_stepper)

        let final_submit = document.getElementById("final-submit")
        if (final_submit){
            if (formData.firstname.length > 0 && formData.lastname.length > 0 && formData.u_data.length > 0){
                final_submit.classList.remove("interaction-out")
            }else{
                final_submit.classList.add("interaction-out")
            }
        }

    },[formData, stepper])

    const handleFormInputs = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const API_KEY = process.env.REACT_APP_GKEY ?? "YOUR_API_KEY";

    const PlaceAutocomplete = ({onPlaceSelect}) => {
        const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
        const inputRef = useRef(null);
        const places = useMapsLibrary("places");

        useEffect(() => {
            if (!places || !inputRef.current) return;
            const options = {
                fields: ["geometry", "name", "formatted_address"],
            };
            setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
        }, [places]);

        useEffect(() => {
            if (!placeAutocomplete) return;
            placeAutocomplete.addListener("place_changed", () => {
                onPlaceSelect(placeAutocomplete.getPlace());
                setFormData({...formData, address: placeAutocomplete.getPlace().formatted_address});
                setStepper({...stepper, address: true});
            });
        }, [onPlaceSelect, placeAutocomplete]);
        if (document.getElementById("address_input") && selectedPlace){
            document.getElementById("address_input").value = selectedPlace.formatted_address;
        }
        return (
            <div className="autocomplete-container_s">
                <input type="text" required className={'mb-2'} name='address' id="address_input" placeholder="Enter your address/zip code then select it from the list" ref={inputRef}/>
            </div>
        );
    };

    const DragEnd = (e)=>{
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${marker.position.lat},${marker.position.lng}8&key=${API_KEY}`)
            .then(res => res.json())
            .then(data => {
                document.getElementById("address_input").value = data.results[0].formatted_address;
                setSelectedPlace({...selectedPlace, formatted_address: data.results[0].formatted_address});
                setFormData({...formData, address: data.results[0].formatted_address});
            }).catch(err => console.log(err))
    }

    const MapHandler = ({place, marker}) => {
        const map = useMap();
        useEffect(() => {
            if (!map || !place || !marker) return;
            if (place.geometry?.viewport) {
                map.fitBounds(place.geometry.viewport);
            }
            marker.position = place.geometry?.location;

        }, [map, place, marker]);
        return null;
    };

    let location = useLocation().pathname.split('/')

    useEffect(() => {
        if (status === 301) {
            setFormDetails({
                ...formDetails,
                initialWidth: parseInt(document.querySelector('.form-wrapper').getBoundingClientRect().width)
            });
        }
        let source_link = location[location.length - 1]
        setSource(source_link)
    }, []);

    const moveFw = (e) => {
        setFormDetails({
            ...formDetails,
            offset: formDetails.offset - formDetails.initialWidth,
            step: formDetails.step + 1
        })

        if (formDetails.step === 6) {
            document.getElementById('progress-container_s').style.visibility = 'hidden'
        }
    }

    const moveBc = (e) => {
        e.preventDefault();
        setFormDetails({
            ...formDetails,
            offset: formDetails.offset + formDetails.initialWidth,
            step: formDetails.step - 1
        })

    }

    const checkButtonHandle = (e) => {
        let button = e.currentTarget
        button.parentElement.classList.toggle('check-label-checked')
        if (button.dataset.category === 'reason') {
            button.closest('.main-interaction').querySelector('.interaction-next').removeAttribute('disabled');
            let value = e.target.value
            let tmp_reasons = formData.reasons
            const exists = tmp_reasons.some(item=>item.value === value)
            if (exists){
                tmp_reasons =  tmp_reasons.filter(c => {
                    return c.value !== value
                })
            }else{
                tmp_reasons.push({name: e.target.name, value: value})
            }
            setFormData({...formData, reasons: tmp_reasons})
            setStepper({...stepper, reasons: true})

            let context = ``

            for (let i = 0; i < tmp_reasons.length; i++) {

                if (tmp_reasons.length === 1) {
                    context += tmp_reasons[i].name.charAt(0).toUpperCase() + tmp_reasons[i].name.slice(1)
                } else if (i === 0) {
                    context += tmp_reasons[i].name.charAt(0).toUpperCase() + tmp_reasons[i].name.slice(1) + ", "
                } else if (i !== tmp_reasons.length - 1 && tmp_reasons.length !== 1) {
                    context += tmp_reasons[i].name + ", "
                } else {
                    context += " and " + tmp_reasons[i].name
                }
            }

            document.querySelector('.check-media').innerHTML = `<div class="check-content"><i class="fa-solid fa-piggy-bank"></i><div class="check-text">The right solar system can: <span class="fw-bold">${context}</span></div></div>`
            if (tmp_reasons.length === 0) {
                document.querySelector('.check-media').innerHTML = ``
                setStepper({...stepper, reasons: false})

            }
        }

        else if (button.dataset.category === 'daytime') {
            let value = e.target.value
            let tmp_daytime = formData.daytime
            const exists = tmp_daytime.some(item=>item.value === value)
            if (exists){
                tmp_daytime =  tmp_daytime.filter(c => {
                    return c.value !== value
                })
            }else{
                tmp_daytime.push({name: e.target.name, value: value})
            }
            setFormData({...formData, daytime: tmp_daytime})

        }
    }

    const radioButtonHandle = (e) => {
        let radio_btn = e.currentTarget
        radio_btn.closest('.interaction-select').querySelectorAll('label').forEach(item => {
            item.classList.remove('radio-label-checked')
        });
        radio_btn.closest('label').classList.toggle('radio-label-checked')
        setFormData({...formData, [e.target.name]: e.target.value})
        setStepper({...stepper, [e.target.name]: true})

        if (radio_btn.dataset.category === 'type') {
            document.querySelector('.type-media').innerHTML = hints[e.target.dataset.category][e.target.value]
        } else if (radio_btn.dataset.category === 'ev_intro') {
            document.querySelector('.secondary-step').innerHTML = hints[e.target.dataset.category][e.target.value]

            let evs = document.getElementsByClassName('ev-radio-button')
            if (evs){
                Array.from(evs).forEach(ev => {
                    ev.onclick = radioButtonHandle
                })
            }
        }
    }

    const handleStatus = (number) =>{
        setStatus(number)
    }

    const handleCalculationResult = (result) =>{
        setCalculationResult(result)
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        handleSolarEstimatorSubmit(formData, handleStatus, props, calculationResult, handleCalculationResult)
    }

    const handleModal = (e) =>{
        setModal({...modal, modal: !modal.modal, type: e.target.dataset.modal})
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - System estimator</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/solar-estimate`}/>
                </Helmet>
            </HelmetProvider>
            <APIProvider
                apiKey={API_KEY}
                solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
            >
                {
                    status === 301  &&
                    <section id="main">
                        <div className="container_s g-0">
                            <section className="progress-container" id={'progress-container'}>
                                <div className="progress-item">
                                    <div data-step="1"
                                         className={`progress-bar ${(formDetails.step < 2 || formDetails.step === 1) ? '' : 'progress-bar-active'}`}></div>
                                </div>
                                <div className="progress-item">
                                    <div data-step="2"
                                         className={`progress-bar ${(formDetails.step < 3 || formDetails.step === 2) ? '' : 'progress-bar-active'}`}></div>
                                </div>
                                <div className="progress-item">
                                    <div data-step="3"
                                         className={`progress-bar ${(formDetails.step < 4 || formDetails.step === 3) ? '' : 'progress-bar-active'}`}></div>
                                </div>
                                <div className="progress-item">
                                    <div data-step="4"
                                         className={`progress-bar ${(formDetails.step < 5 || formDetails.step === 4) ? '' : 'progress-bar-active'}`}></div>
                                </div>
                                <div className="progress-item">
                                    <div data-step="5"
                                         className={`progress-bar ${(formDetails.step < 6 || formDetails.step === 5) ? '' : 'progress-bar-active'}`}></div>
                                </div>
                            </section>
                            <Form onSubmit={event => handleSubmit(event)} method="post" id="act-form"
                                  className="form-wrapper">
                                {source && <input type="hidden" name={'source'} value={source}/>}
                                <div className="form-steps" id={'form-steps'}
                                     style={{transform: `translateX(${formDetails.offset}px)`}}>
                                    <div className="form-step">
                                        <section className="main-form-content">
                                            <div className="main-interaction">
                                                <div className="interaction-intro">
                                                    <div className="interaction-title">
                                                        What are the main reasons you would like a solar system?
                                                    </div>
                                                    <div className="interaction-subtitle">
                                                        <i className="fa-solid fa-circle"></i>(Choose all that apply)
                                                    </div>
                                                    <div className="interaction-desc">
                                                        <div className="separate-icon"><i className="ri-sun-line"></i>
                                                        </div>
                                                        Solar energy has a lot of benefits. It’s helpful to know which
                                                        benefits matter to you.
                                                    </div>

                                                </div>
                                                <div className="interaction-select">
                                                    <div className="select-container">
                                                        <div className="select-item">
                                                            <label htmlFor="q1-1">Save money<input
                                                                data-category="reason"
                                                                name="save you money"
                                                                className="check-button"
                                                                onClick={event => checkButtonHandle(event)}
                                                                type="checkbox"
                                                                value="savings"
                                                                id="q1-1"/></label>
                                                        </div>
                                                        <div className="select-item">
                                                            <label htmlFor="q1-2">Be eco-friendly<input
                                                                data-category="reason"
                                                                name="give you control over your power"
                                                                className="check-button"
                                                                onClick={event => checkButtonHandle(event)}
                                                                type="checkbox"
                                                                value="environment"
                                                                id="q1-2"/></label>
                                                        </div>
                                                        <div className="select-item">
                                                            <label htmlFor="q1-3">Avoid blackouts<input
                                                                data-category="reason"
                                                                name="keep your lights on when the grid goes down"
                                                                className="check-button"
                                                                onClick={event => checkButtonHandle(event)}
                                                                type="checkbox"
                                                                value="blackouts"
                                                                id="q1-3"/></label>
                                                        </div>
                                                        <div className="select-item">
                                                            <label htmlFor="q1-4">Property value<input
                                                                data-category="reason"
                                                                name="make you money"
                                                                className="check-button"
                                                                onClick={event => checkButtonHandle(event)}
                                                                type="checkbox"
                                                                value="property_value"
                                                                id="q1-4"/></label>
                                                        </div>
                                                        <div className="select-item">
                                                            <label htmlFor="q1-5">Get tax credits<input
                                                                data-category="reason"
                                                                name="help fight climate change"
                                                                className="check-button"
                                                                type="checkbox"
                                                                onClick={event => checkButtonHandle(event)}
                                                                value="tax_credit"
                                                                id="q1-5"/></label>
                                                        </div>
                                                        <div className="select-item">
                                                            <label htmlFor="q1-6">Generate profit<input
                                                                data-category="reason"
                                                                name="help fight climate change"
                                                                className="check-button"
                                                                type="checkbox"
                                                                onClick={event => checkButtonHandle(event)}
                                                                value="profit"
                                                                id="q1-6"/></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="check-media">

                                                </div>
                                                <div className="interaction-movement">
                                                    {stepper &&
                                                        <div
                                                            className={`interaction-next  interaction-first ${stepper.reasons === false ? 'interaction-out' : ''}`}
                                                            onClick={event => moveFw(event)}>Next<i
                                                            className="ri-arrow-right-s-line"></i></div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="main-creds">
                                                <div className="img-container">
                                                    <img src="../assets/media/pictures/static/SolarMAX-ad-v2.jpg"
                                                         alt=""/>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div className="form-step">
                                        <section className="main-form-content">
                                            <div className="main-interaction">
                                                <div className="interaction-intro">
                                                    <div className="interaction-title">
                                                        Where would the solar system be installed?
                                                    </div>
                                                    <div className="interaction-desc">
                                                        <div className="separate-icon"><i className="ri-sun-line"></i>
                                                        </div>
                                                        Switching to clean, solar energy can help families, businesses,
                                                        and the planet.
                                                    </div>

                                                </div>
                                                <div className="interaction-select">
                                                    <div className="select-container">
                                                        <div className="radio-item">
                                                            <label htmlFor="q2-1">My house<input data-category="type"
                                                                                                 className="radio-button"
                                                                                                 name="type"
                                                                                                 type="radio"
                                                                                                 onClick={event => radioButtonHandle(event)}
                                                                                                 value="residential"
                                                                                                 id="q2-1"/></label>
                                                        </div>
                                                        <div className="radio-item">
                                                            <label htmlFor="q2-2">My business<input data-category="type"
                                                                                                    className="radio-button"
                                                                                                    name="type"
                                                                                                    type="radio"
                                                                                                    onClick={event => radioButtonHandle(event)}
                                                                                                    value="commercial"
                                                                                                    id="q2-2"/></label>
                                                        </div>
                                                        <div className="radio-item">
                                                            <label htmlFor="q2-3">I’m renting<input data-category="type"
                                                                                                    className="radio-button"
                                                                                                    name="type"
                                                                                                    type="radio"
                                                                                                    onClick={event => radioButtonHandle(event)}
                                                                                                    value="rental"
                                                                                                    id="q2-3"/></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="type-media">

                                                </div>
                                                <div className="interaction-movement">
                                                    <div className={`interaction-back `}
                                                         onClick={event => moveBc(event)}><i
                                                        className="ri-arrow-left-s-line"></i></div>
                                                    <div
                                                        className={`interaction-next ${stepper.type === false ? 'interaction-out' : ''}`}
                                                        onClick={event => moveFw(event)}
                                                    >Next<i className="ri-arrow-right-s-line"></i></div>
                                                </div>
                                            </div>
                                            <div className="main-creds">
                                                <div className="img-container">
                                                    <img src="../assets/media/pictures/static/SolarMAX-ad-v2.jpg"
                                                         alt=""/>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div className="form-step">
                                        <section className="main-form-content">
                                        <div className="main-interaction">
                                                <div className="interaction-intro">
                                                    <div className="interaction-title">
                                                        Let’s find your roof. What’s your address?
                                                    </div>
                                                    <div className="interaction-desc">
                                                        <div className="separate-icon"><i className="ri-sun-line"></i>
                                                        </div>
                                                        We'll use Google Maps to check out the size and position of your
                                                        roof, or we can see how much sunlight your area usually gets
                                                        from your zip code.
                                                    </div>
                                                </div>
                                                <div className="interaction-select">
                                                    <div className="select-container">
                                                        <div className="map-items">
                                                            <div className="text-item">
                                                                <PlaceAutocomplete className="text-item"
                                                                                   data-category="address"
                                                                                   name="address"
                                                                                   onPlaceSelect={setSelectedPlace}/>

                                                                {/*<input className="text-item" autoComplete="off"*/}
                                                                {/*       data-category="address" name="address" type="text"*/}
                                                                {/*       value="" id="q3-1"*/}
                                                                {/*       placeholder="Type your address here..."/>*/}
                                                            </div>
                                                            {/*<div id="canvas-1"><img src="https://i.imgur.com/sIaHZ9i.png"*/}
                                                            {/*                        alt=""/></div>*/}
                                                            <Map style={{height: '400px'}}
                                                                 mapId={"bf51a910020fa25a"}
                                                                 defaultZoom={20}
                                                                 mapTypeId={'satellite'}
                                                                 mapTypeControl={false}

                                                                 defaultCenter={{
                                                                     lat: 33.97377897043439,
                                                                     lng: -117.36946059046028
                                                                 }}
                                                                 gestureHandling={"greedy"}
                                                                 disableDefaultUI={false}
                                                            >
                                                                <AdvancedMarker draggable={true} onDragEnd={DragEnd}
                                                                                ref={markerRef} position={null}/>
                                                            </Map>
                                                            <MapHandler place={selectedPlace} marker={marker}/>

                                                            <div className="map-description">
                                                                <div className="google-cred"><i
                                                                    className="fa-brands fa-google"></i>powered by
                                                                    Google
                                                                </div>
                                                                <div className="drag-act">Drag the pin to your roof
                                                                </div>
                                                            </div>
                                                            <div className="map-info">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="interaction-movement">
                                                    <div className="interaction-back" onClick={event => moveBc(event)}>
                                                        <i
                                                            className="ri-arrow-left-s-line"></i></div>
                                                    <div
                                                        className={`interaction-next ${stepper.address === false ? 'interaction-out' : ''}`}
                                                        onClick={event => moveFw(event)}
                                                    >Next<i className="ri-arrow-right-s-line"></i></div>
                                                </div>
                                            </div>
                                            <div className="main-creds">
                                                <div className="img-container">
                                                    <img src="../assets/media/pictures/static/SolarMAX-ad-v2.jpg"
                                                         alt=""/>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                    <div className="form-step">
                                        <section className="main-form-content">
                                        <div className="main-interaction">
                                                <div className="interaction-intro">
                                                    <div className="interaction-title">
                                                        How much do you usually <br/>pay for electricity?
                                                    </div>
                                                    <div className="interaction-desc">
                                                        <div className="separate-icon"><i className="ri-sun-line"></i>
                                                        </div>
                                                        Energy costs are always rising. The amount you save <br/>by
                                                        eliminating your energy bill gets bigger every year.
                                                    </div>
                                                </div>
                                                <div className="interaction-select">
                                                    <div className="select-container">
                                                        <div className="range-item">
                                                            <label htmlFor="q7-1">What’s your average monthly
                                                                bill?</label>
                                                            <input type="hidden" className="bill-count b-h-c"/>
                                                            <div
                                                                className="input-result input-result-q5-1 mobile">$ {formData.monthlyBill}</div>
                                                            <input className="range-button bill-count-range"
                                                                   onChange={event => handleFormInputs(event)}
                                                                   name="monthlyBill"
                                                                   type="range" value={formData.monthlyBill} id="q7-1"
                                                                   min="50" max="500"
                                                                   step="25"/>
                                                            <div
                                                                className="input-result input-result-q5-1">$ {formData.monthlyBill}</div>
                                                        </div>
                                                        <div className="range-item">
                                                            <label htmlFor="q7-2">What’s your price per
                                                                kilowatt-hour?</label>
                                                            <div className="input-result input-result-q5-2 mobile">$
                                                                0.30
                                                            </div>
                                                            <input className="range-button"
                                                                   onChange={event => handleFormInputs(event)}
                                                                   name="electricityRate" type="range"
                                                                   value={formData.electricityRate} id="q7-2" min="0"
                                                                   max="0.7" step="0.01"/>
                                                            <div
                                                                className="input-result input-result-q5-2">$ {formData.electricityRate}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="interaction-movement">
                                                    <div className="interaction-back" onClick={event => moveBc(event)}>
                                                        <i
                                                            className="ri-arrow-left-s-line"></i></div>
                                                    <div
                                                        className={`interaction-next ${stepper.type === false ? 'interaction-out' : ''}`}
                                                        onClick={event => moveFw(event)}
                                                    >Next<i className="ri-arrow-right-s-line"></i></div>
                                                </div>
                                            </div>
                                            <div className="main-creds">
                                                <div className="img-container">
                                                    <img src="../assets/media/pictures/static/SolarMAX-ad-v2.jpg"
                                                         alt=""/>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div className="form-step">
                                        <section className="main-form-content">
                                        <div className="main-interaction">
                                                <div className="interaction-intro">
                                                    <div className="interaction-title">
                                                        Do you drive an electric vehicle?
                                                    </div>
                                                    {/*<div className="interaction-subtitle">*/}
                                                    {/*    <i className="fa-solid fa-circle"></i>Do you drive an electric*/}
                                                    {/*    vehicle?*/}
                                                    {/*</div>*/}
                                                    <div className="interaction-desc">
                                                        <div className="separate-icon"><i className="ri-sun-line"></i>
                                                        </div>
                                                        Driving an EV means you could save by storing power to charge it
                                                        at night.
                                                    </div>
                                                </div>
                                                <div className="interaction-select">
                                                    <div className="select-container">
                                                        <div className="radio-item">
                                                            <label htmlFor="q5-1">Yes<input className="radio-button"
                                                                                            onClick={event => radioButtonHandle(event)}
                                                                                            data-category="ev_intro"
                                                                                            name="ev"
                                                                                            type="radio" value="true"
                                                                                            id="q5-1"/></label>
                                                        </div>
                                                        <div className="radio-item">
                                                            <label htmlFor="q5-2">No<input className="radio-button"
                                                                                           name="ev"
                                                                                           onClick={event => radioButtonHandle(event)}
                                                                                           data-category="ev_intro"
                                                                                           type="radio"
                                                                                           value="false"
                                                                                           id="q5-2"/></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="secondary-step">

                                                </div>

                                                <div className="interaction-movement">
                                                    <div className="interaction-back" onClick={event => moveBc(event)}>
                                                        <i
                                                            className="ri-arrow-left-s-line"></i></div>
                                                    <div
                                                        className={`interaction-next ${stepper.ev === false ? 'interaction-out' : ''}`}
                                                        onClick={event => moveFw(event)}
                                                    >Next<i className="ri-arrow-right-s-line"></i></div>
                                                    {/*<button id="submit">Get Quote<i*/}
                                                    {/*    className="ri-arrow-right-s-line"></i></button>*/}

                                                </div>
                                            </div>
                                            <div className="main-creds">
                                                <div className="img-container">
                                                    <img src="../assets/media/pictures/static/SolarMAX-ad-v2.jpg"
                                                         alt=""/>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                    <div className="form-step">
                                        <section className="main-form-content">
                                            <div className="main-interaction">
                                                <div className="interaction-intro">
                                                    <div className="interaction-title">
                                                        Lastly...
                                                    </div>
                                                    <div className="interaction-subtitle">
                                                        <i className="fa-solid fa-circle"></i>How do we to contact you
                                                        when you win?
                                                    </div>
                                                    <div className="interaction-desc">
                                                        <div className="separate-icon"><i
                                                            className="fa-solid fa-sun"></i></div>
                                                        Driving an electric vehicle means you'll need to store power to
                                                        charge it at night.
                                                    </div>
                                                </div>
                                                <div className="interaction-select">
                                                    <div className="select-container">
                                                        <div className="map-items cred-items">
                                                            <div className="cred-item">
                                                                <i className="fa-solid fa-user"></i><input
                                                                className="radio-button text-button" required
                                                                autoComplete="off" data-category="firstname"
                                                                name="firstname" type="text" value="" id="q11-1"
                                                                placeholder="Type your first name"
                                                                onChange={event => handleFormInputs(event)}
                                                                value={formData.firstname}
                                                            />
                                                            </div>
                                                            <div className="cred-item">
                                                                <i className="fa-solid fa-user"></i><input
                                                                className="radio-button text-button" required
                                                                autoComplete="off" data-category="lastname"
                                                                name="lastname" type="text" value="" id="q11-2"
                                                                placeholder="Type your last name"
                                                                value={formData.lastname}
                                                                onChange={event => handleFormInputs(event)}
                                                            />
                                                            </div>
                                                            <div className="cred-item ">
                                                                <i className="fa-solid fa-envelope"></i><input
                                                                className="radio-button text-button" required
                                                                autoComplete="off" data-category="u_data" name="u_data"
                                                                type="email" value="" id="q11-3"
                                                                placeholder="Type your email address"
                                                                value={formData.u_data}
                                                                onChange={event => handleFormInputs(event)}
                                                            />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="interaction-movement">
                                                    <div className="interaction-back" onClick={event => moveBc(event)}>
                                                        <i
                                                            className="ri-arrow-left-s-line"></i></div>
                                                    <button className="interaction-next" id={'final-submit'}>Get Quote<i
                                                        className="fa-solid fa-chevron-right"></i></button>
                                                </div>
                                            </div>
                                            <div className="main-creds">
                                                <div className="img-container">
                                                    <img src="../assets/media/pictures/static/SolarMAX-ad-v2.jpg"
                                                         alt=""/>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </section>
                }
                {
                    status === 200 &&
                    <FormEstimateComplete handleModal={handleModal} calculationResult={calculationResult}
                                          debugValue={props.debugValue} modal={modal} bd={props.bd}
                                          checkButtonHandle={checkButtonHandle} handleFormInputs={handleFormInputs}
                                          formData={formData}/>
                }
                {status === 300 &&
                    <div className="">
                        <div className="loader">
                            <span className="bar"></span>
                            <span className="bar"></span>
                            <span className="bar"></span>
                        </div>
                    </div>
                }
                {status === 400 &&
                    <div className="container_s" style={{maxWidth: "1440px", padding: "1rem 0"}}>
                        <div>
                            <span className="">Error in the estimation process.</span>
                        </div>
                    </div>
                }
            </APIProvider>
            <script
                src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDk6mSSvp037hEsh88M203qYn6Hye3hdzI&libraries=places&language=en"
                async>
            </script>

        </>
    )

}

export default FormEstimate;