import React, {useEffect, useState} from "react";
import {Form, Link, useLocation, useNavigate} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";

const ReferralConfirmation = (props) => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [newEmail, setNewEmail] = useState("");
    const [loading, setLoading] = useState(false);
    let location = useLocation();
    let navigate = useNavigate();
    const handleInput = (e) =>{
        setNewEmail(e.target.value)
    }

    useEffect(() => {
        let rl = location.pathname.split("/")[location.pathname.split("/").length - 1];
        if (location.pathname.split("/").length === 3){
            setLoading(true)
            fetch(`${props.debugValue}/api/check-referral`,{
                method: 'POST',
                body: JSON.stringify({code: rl, user_id: props.bd.user_id, u_os: props.bd.u_os, u_br: props.bd.u_br}),
                headers: {
                    'Authorization': 'Bearer ' + props.bd.t,
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json()).then(data => {
                setLoading(false)

                if (data.status !== 400){
                    setUser(data.code)
                }else{
                    setError(data.status)
                }
            }).catch(err => console.log(err))
        }
    }, [location]);

    const handleSubmit = (e) =>{
        e.preventDefault();
        setLoading(true)
        let code = location.pathname.split("/")[location.pathname.split("/").length - 1];
        fetch(`${props.debugValue}/api/confirm-referral`,{
            method: 'POST',
            body: JSON.stringify({u_br: props.bd.u_br, u_data: newEmail, u_os: props.bd.u_os, user_id: props.bd.user_id, email: user, code: code}),
            headers: {
                'Authorization': 'Bearer ' + props.bd.t,
                "Content-Type": "application/json"}
        }).then(res => res.json()).then(data => {
            setLoading(false)
            if (data.status !== 400){
                fetch(`${props.debugValue}/api/referral/get-referral`,{
                    method: 'POST',
                    body: JSON.stringify({email: newEmail}),
                    headers: {
                        'Authorization': 'Bearer ' + props.bd.t,
                        "Content-Type": "application/json"}
                }).then(res => res.json()).then(newUsers => {
                    if (newUsers.status !== 400){
                        newUsers.subscribers.forEach((subscriber) => {
                            if (subscriber.email === newEmail){
                                localStorage.setItem('referral', JSON.stringify({rlCode: subscriber.code, rlId: subscriber.user_id, rlEmail: subscriber.email, rrCode: code}))
                                navigate('/referral/dashboard')
                            }
                        })

                    }else {
                        setError({message:'Something went wrong in the process', description: 'This may appear only if the email is already used in the system, or if the invitation link is corrupt.'})
                    }
                })
            }else {
                setError({message:'Something went wrong in the process', description: 'This may appear only if the email is already used in the system, or if the invitation link is corrupt.'})
            }
        }).catch(err=>alert("Something went wrong! Please try again."))
    }
    return (
        <>
            <section className="global__hero_single" style={{
                backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url('${require('../../assets/media/pictures/static/my-referral-hero.png')}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
                <div className="container_s" style={{height: '100%'}}>
                    <div className="single__hero_wrapper">
                        <div className="single__hero_text">
                            {user && !error &&
                                <Form onSubmit={e => handleSubmit(e)}>
                                    <div className="single__hero_title text-heading-3">Confirm the email</div>
                                    <div className="single__hero_desc  text-heading-5 mb-2" >Add your email, so
                                        referral bonus will be attached and saved.
                                    </div>
                                    <div className={'text-body-3  mb-1'}>The invitation link came from <strong style={{color: 'var(--branding-secondary)'}}>{user.slice(0, 2)+'*****'+user.slice(user.length-2)}</strong>. If you are not familiar with it, ignore it.</div>
                                    <input className={'text-body-1'} type="email" onChange={e => handleInput(e)} value={newEmail}/>
                                    <div className="single__hero_cta" style={{marginTop: '30px'}}>
                                        {loading &&
                                            <button disabled={true} className={'text-body-1 mb-1'}
                                                    style={{fontWeight: 'bold'}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="65" height="15"
                                                     viewBox="0 0 135 140" fill="#fff">
                                                    <rect y="10" width="15" height="120" rx="6">
                                                        <animate attributeName="height" begin="0.5s" dur="1s"
                                                                 values="120;110;100;90;80;70;60;50;40;140;120"
                                                                 calcMode="linear" repeatCount="indefinite"/>
                                                        <animate attributeName="y" begin="0.5s" dur="1s"
                                                                 values="10;15;20;25;30;35;40;45;50;0;10"
                                                                 calcMode="linear" repeatCount="indefinite"/>
                                                    </rect>
                                                    <rect x="30" y="10" width="15" height="120" rx="6">
                                                        <animate attributeName="height" begin="0.25s" dur="1s"
                                                                 values="120;110;100;90;80;70;60;50;40;140;120"
                                                                 calcMode="linear" repeatCount="indefinite"/>
                                                        <animate attributeName="y" begin="0.25s" dur="1s"
                                                                 values="10;15;20;25;30;35;40;45;50;0;10"
                                                                 calcMode="linear" repeatCount="indefinite"/>
                                                    </rect>
                                                    <rect x="60" width="15" height="140" rx="6">
                                                        <animate attributeName="height" begin="0s" dur="1s"
                                                                 values="120;110;100;90;80;70;60;50;40;140;120"
                                                                 calcMode="linear" repeatCount="indefinite"/>
                                                        <animate attributeName="y" begin="0s" dur="1s"
                                                                 values="10;15;20;25;30;35;40;45;50;0;10"
                                                                 calcMode="linear" repeatCount="indefinite"/>
                                                    </rect>
                                                    <rect x="90" y="10" width="15" height="120" rx="6">
                                                        <animate attributeName="height" begin="0.25s" dur="1s"
                                                                 values="120;110;100;90;80;70;60;50;40;140;120"
                                                                 calcMode="linear" repeatCount="indefinite"/>
                                                        <animate attributeName="y" begin="0.25s" dur="1s"
                                                                 values="10;15;20;25;30;35;40;45;50;0;10"
                                                                 calcMode="linear" repeatCount="indefinite"/>
                                                    </rect>
                                                    <rect x="120" y="10" width="15" height="120" rx="6">
                                                        <animate attributeName="height" begin="0.5s" dur="1s"
                                                                 values="120;110;100;90;80;70;60;50;40;140;120"
                                                                 calcMode="linear" repeatCount="indefinite"/>
                                                        <animate attributeName="y" begin="0.5s" dur="1s"
                                                                 values="10;15;20;25;30;35;40;45;50;0;10"
                                                                 calcMode="linear" repeatCount="indefinite"/>
                                                    </rect>
                                                </svg>
                                            </button>
                                        }
                                        {!loading &&
                                            <button className={'text-body-1 mb-1'} style={{fontWeight: 'bold'}}>Confirm
                                                my
                                                referral bonus
                                            </button>
                                        }

                                    </div>
                                    <p className={'text-body-3'}>We won't share your email with anyone, and you can
                                        unsubscribe at any time. Learn more in our <Link
                                            style={{color: 'var(--branding-secondary)'}} to={'/privacy'}>privacy
                                            policy</Link>.</p>
                                </Form>
                            }
                            {error &&
                                <>
                                    <div className="single__hero_title text-heading-3">{error.message}</div>
                                    <div className="single__hero_desc  text-heading-5 mb-2">{error.description}
                                    </div>
                                </>

                            }
                        </div>

                    </div>

                </div>

            </section>
        </>
    );
}

export default ReferralConfirmation;