import React, {useEffect, useState} from 'react';
import '../assets/css/articles.css'
import Global from "./shared/global";
import {Link, useLocation} from "react-router-dom";
import Regions from "./shared/regions";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {getBlog} from "../fetch/blog";


function Article(props) {
    const [singlePost, setSinglePost] = useState(null)
    const [postLoader, setPostLoader] = useState(true)
    const location = useLocation()
    let [canonical, setCanonical] = useState(null)
    const post = location.pathname.split('/')[location.pathname.split('/').length-1]

    const handleCanonical = (string) =>{
        setCanonical(string)
    }

    const handlePost = (post) => {
        setSinglePost(post)
    }

    const handlePostLoader = (state) => {
        setPostLoader(state)
    }

    useEffect(()=>{
        setPostLoader(true)
        getBlog(props, post, handleCanonical, handlePost, handlePostLoader, location)
    }, [post])
    console.log(singlePost)
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    {singlePost && <title>{singlePost.title ? singlePost.title.replaceAll('^=39', '\'') : ''}</title>}
                    {canonical && <link rel="canonical" href={canonical}/>}
                </Helmet>
            </HelmetProvider>

            <section className="article__main">
                <div className="container_s">
                    <div className="article__wrapper">
                        <div className="article__info">
                            {postLoader &&
                                <div className="loader_s">
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                </div>
                            }
                            <div className={'mb-2'}><Link to={'/blog'}><i className="ri-arrow-left-line"></i> Go
                                back</Link></div>
                            {!postLoader && singlePost &&
                                <>
                                    <h1>{singlePost.title ? singlePost.title.replaceAll('^=39', '\'') : ''}</h1>
                                    <div className={'post__details text-heading-7'}>
                                        <span>{singlePost.created_at ? new Date(singlePost.created_at).toLocaleDateString("en-US", {
                                            day: 'numeric',
                                            month: 'long',
                                            year: 'numeric'
                                        }) : ''}</span><span>Post by {singlePost.author}</span></div>
                                    <div
                                        dangerouslySetInnerHTML={{__html: singlePost.content?.replaceAll('^=39', '\'')}}></div>
                                </>
                            }


                        </div>
                        <div className="article__interaction">
                            <iframe className={"form__holder"}
                                    style={{width: '100%', height: '740px', position: 'sticky', top: '80px'}}
                                    id={'solarmax__form'}
                                    src={`https://4345356.extforms.netsuite.com/app/site/crm/externalleadpage.nl?compid=4345356${props.form_source}`}
                                    frameBorder="0"></iframe>
                            {/*<ContactForm/>*/}

                        </div>
                    </div>
                </div>
            </section>

            <Regions/>

            <Global debugValue={props.debugValue}/>

            <section className="homepage__cta">
                <div className="container_s">
                    <div className="cta__wrapper">
                        <div className="cta__title text-heading-3">
                            Start today with a free quote from our solar experts
                        </div>
                        <div className="cta__desc text-body-1">
                            SolarMax is dedicated to providing the highest quality energy alternatives at affordable prices. Enjoy easy financing options and low monthly payments that make reinforcing your solar energy systems simple and stress-free.
                        </div>
                        <div className="cta__cta text-heading-6">
                            <button className={'text-heading-6'} onClick={props.toggleForm}>Get your quote</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Article;