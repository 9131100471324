import React from 'react';

function ReferralThanks(props) {
    return (
        <div>
            <h1>Thank you!</h1>
            <h5>Your referral request has been recorded.</h5>
        </div>
    );
}

export default ReferralThanks;