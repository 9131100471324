import React, {useState} from 'react';
import '../assets/css/shared.css'
import {Form, Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";

const FormComplete = (props) => {

    const [subEmail, setSubEmail] = useState(null)
    const [error, setError] = useState('')
    const [formSubmit, setFormSubmit] = useState(false)
    const handleSubscription = (e) =>{
        e.preventDefault()
        if (!subEmail && subEmail.trim().includes(' ')){
            setError('Please add the proper email')
        }else if(!subEmail.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )){
            setError('Please add the proper email')
        }else{
            setError('')
            fetch(`${props.debugValue}/api/subscribe`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + props.bd.t,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({u_data: subEmail ? subEmail : '' , u_br: props.bd.u_br, u_os: props.bd.u_os, user_id : props.bd.user_id})
            }).then(res=> res.json()).then(data=> {
                if (data.message === 200){
                    setFormSubmit(!formSubmit)
                }else{
                    setError('Something went wrong. Please try again.')
                }
            }).catch(err=>console.log(err))
        }
    }

    const handleInput = (e) =>{
        setSubEmail(e.target.value)
    }
    return (
        <div id={'thank__you'}  style={{background: "white"}}>

            <img style={{width: '100%'}} src={require('../assets/media/pictures/form-complete.jpg')} alt=""/>
            <div style={{fontFamily: 'var(--ff-medium)', width: '100%'}}>

                {formSubmit ?
                    <div className={'text-heading-3'} style={{textAlign: "center", margin: '12px 16px'}}>Thank you!</div>
                :
                    <>
                        <div className={'text-heading-6'}>Our solar energy experts will reach out to you by phone or email shortly.</div>
                        <div className={'text-heading-6'} style={{textAlign: "center", margin: '12px 16px'}}>But wait there's more…</div>
                        <p className={'text-body-1'}>Stay informed about industry news, government incentives, and our special offers by subscribing to our monthly solar newsletter.</p>
                        <Form onSubmit={event => handleSubscription(event)} style={{margin: '24px 0 16px 0'}}>
                            <input onChange={event => handleInput(event)} type="email" style={{width: '100%', padding: "9px 16px", borderRadius: "4px", outline: "none", border: '1px solid gray'}} value={subEmail? subEmail : ''}/>
                            <span style={{color: "red"}} className={'text-body-3'}>{error && error}</span>
                            <div style={{marginTop: '16px', display: "flex", gap: '16px'}}><button type={"submit"} className={'text-body-1'} style={{border: "none",flexGrow: 1, background: 'var(--button-primary)', padding: '8px 24px', borderRadius: '4px', cursor: "pointer"}}>Yes, sign up me up!</button><div style={{border: "none",flexGrow: 1, textAlign:"center", background: 'var(--button-custom)', padding: '7px 24px', borderRadius: '4px', cursor: "pointer"}} onClick={props.toggleForm}>No thanks</div></div>
                        </Form>
                        <p className={'text-body-3'}>We won't share your email with anyone, and you can unsubscribe at any time. Learn more in our <Link style={{color: 'var(--branding-secondary)'}} to={'/privacy'}>privacy policy</Link>.</p>
                    </>
            }
            </div>
        </div>
    );
}

export default FormComplete;