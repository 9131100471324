const getBlog = (props, post, handleCanonical, handlePost, handlePostLoader, location) => {
    fetch(`${props.debugValue}/api/blogs/${post ? post : 'redirect'}`).then(res=>res.json()).then(data=> {
        if (data.status !== 400){
            handlePost(data.blog)
            if (data.blog.rel_url){
                handleCanonical(data.blog.rel_url)
            }
            else if (location.pathname.includes('locations')){
                handleCanonical(`https://solarmaxtechnology.com/locations${data.blog.path}`)
            }
            else if (location.pathname.includes('blog')){
                handleCanonical(`https://solarmaxtechnology.com/blogs${data.blog.path}`)
            }
        }else{
            handlePost(null)
            if(location.pathname.split('/').some(path => path === 'blog')) window.location.href = '/blog'
            if(location.pathname.split('/').some(path => path === 'locations')) window.location.href = '/service-areas'
        }
        setTimeout(()=>{
            handlePostLoader(false)
        },1500)
    }).catch(err=>console.log(err))
}

const getAllBlogs = (
    props,
    currentPage,
    handleAllPosts,
    handlePostLoader
) => {
    fetch(`${props.debugValue}/api/blogs/list/${currentPage}`).then(res=>res.json()).then(data=>{
        handleAllPosts(data.all)
        handlePostLoader(false)
    }).catch(err=>console.log(err))
}

const getAllBlogsPaginated = (
    props,
    location,
    currentPage,
    handleAllPosts,
    handleRecentPosts,
    handlePagination,
    handlePostLoader
) => {
    if(location.pathname.split('/').length > 3){
        window.location.href = '/blog'
    }
    fetch(`${props.debugValue}/api/blogs/list/${currentPage}`).then(res=>res.json()).then(data=> {
        handleAllPosts(data.all)
        handleRecentPosts(data.recent)
        handlePagination(old=> ({
                ...old,
                pages: data.pages
            })
        )
        setTimeout(()=>{
            handlePostLoader(false)
        },1000)

    }).catch(err=>console.log(err))
}

const blogsSearch = (
    props,
    searchValue,
    pagination,
    handleAllPosts,
    handlePagination,
    handlePostLoader
) => {
    if (searchValue){
        fetch(`${props.debugValue}/api/blogs/search/${searchValue}/${pagination.items}`).then(res=>res.json()).then(data => {
            handleAllPosts(data.all)
            handlePagination(old=>({
                    ...old,
                    pages: data.pages
                })
            )
            handlePostLoader(false)
        }).catch(err=>console.log(err))
    }else{
        fetch(`${props.debugValue}/api/blogs/list/${pagination.items}`).then(res=>res.json()).then(data=> {
            handleAllPosts(data.all)
            handlePostLoader(false)
        }).catch(err=>console.log(err))
    }
}
module.exports = {
    getBlog,
    getAllBlogs,
    getAllBlogsPaginated,
    blogsSearch
}