import React from 'react';
import {Link} from "react-router-dom";
import Global from "./shared/global";
import {Helmet, HelmetProvider} from "react-helmet-async";

function Error(props) {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Not found</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/error`}/>
                </Helmet>
            </HelmetProvider>
            <section className="global__hero_single" style={{backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url(${require('../assets/media/pictures/static/financing-1.png')})`}}>
                <div className="container_s" style={{height: '100%'}}>
                    <div className="single__hero_wrapper">
                        <div className="single__hero_text" style={{textAlign: "center", width: '100%'}}>
                            <div className="single__hero_title text-heading-2">The page was not found</div>
                            <div className="single__hero_desc  text-heading-5">The page you are looking for was removed or in development. Please navigate to the main page or <Link to={'/contact-us'} onClick={props.handleLink} style={{fontWeight: "bold" ,color: 'var(--branding-primary)'}} className={'text-heading-4'}>Contact with us</Link> about the issue.</div>
                        </div>
                    </div>
                </div>
            </section>

            <Global debugValue={props.debugValue}/>
        </>
    );
}

export default Error;