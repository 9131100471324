import React from 'react';
import '../../assets/css/sm.css'

function SolarmaxVsTesla(props) {
    return (
        <>
            <div className="position-relative">

                <figure className="page-hero page-hero-form">
                    <div className="div-table">
                        <div className="container hero-container">
                            <div className="hero-row">
                                <figcaption className="hero-inner">

                                    <div className="h1">SolarMax Solar Purchase Plan vs Tesla Solar Subscription</div>

                                </figcaption>
                                <div className="hero-quote-section">
                                    <div id="quote-form" className="quote-form panel panel-quote-form">
                                        <div className="panel-body">
                                            <div className="section-header">

                                                <p className="h2">Home Solar Systems Starting at Less Than $40/month</p>
                                                <p className="lead">Enjoy top savings on our premier Solar
                                                    Panels &amp; Microinverters - starting at $39.83/month!</p>

                                            </div>
                                            <div className="mb-2">
                                                <div className="aggregate-reviews">
                                                    <div
                                                        className="credibility-description media d-inline-block mx-auto">
                                                        <div className="media-left hide"><i
                                                            className="fab fa-google fa-2x"
                                                            aria-hidden="true"></i></div>
                                                        <div className="review-stars media-body vertical-align-middle">
                                                            <span className="fas fa-star fa-lg"
                                                                  aria-hidden="true"></span> <span
                                                            className="fas fa-star fa-lg" aria-hidden="true"></span>
                                                            <span
                                                                className="fas fa-star fa-lg" aria-hidden="true"></span>
                                                            <span
                                                                className="fas fa-star fa-lg" aria-hidden="true"></span>
                                                            <span
                                                                className="fas fa-star fa-lg" aria-hidden="true"></span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="rating">
                                                            <span className="average">5.0</span> out of <span
                                                            className="best">5</span>
                                                        </div>
                                                        Out of <span className="votes">27</span> Reviews
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <picture>
                        {/*<img className="page-hero-bg" src="/img/page/hero-solarmax-tesla.jpg" alt="Background Photo"/>*/}
                    </picture>
                </figure>
            </div>
            <div className="main-content">
                <div className="page-section pb-0">
                    <div className="container">
                        <div className="row border-section">
                            <p>While considering whether to go with renting a solar system from Tesla or investing in
                                your
                                home with SolarMax Technology we invite you to look at a comparison of the two.</p>
                        </div>
                    </div>
                </div>

                <div className="page-section pros-conrs-setion">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 pro-section">
                                <h2>SolarMax Technology Pro</h2>
                                <h5>Own your solar energy system</h5>
                                <p>Get the full 26% tax credit</p>
                                <p>Added home value</p>
                                <p>Doesn’t discourage prospective homebuyers when you sell your home</p>
                                <h5>Premium SMX Solar Panels:</h5>
                                <p>SolarMax SMX panels are designed, engineered and assembled, tariff-free in the
                                    SolarMax
                                    California headquarters</p>
                                <p>Rated one of the most reliable panels in the world</p>
                                <h5>25 Year Warranties</h5>
                                <p>25 year solar panel warranty</p>
                                <p>25 year limited workmanship warranty</p>
                            </div>
                            <div className="col-md-6 cons-section">
                                <h2>Tesla Cons</h2>
                                <h4>Lease subscription costs 8% more per month</h4>
                                <h4>Non-refundable deposit required to request a reservation</h4>
                                <h4>Monthly rental rates can increase at any time with only 30 days to accept or
                                    cancel</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section comparison-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4 col-md-offset-1 match-height" style={{height: "472px"}}>
                                {/*<img className="img-responsive center-block lazyload" src="/img/ph-square.png"*/}
                                {/*     data-src="/img/page/tesla-home.jpg" alt="tesla"/>*/}
                            </div>
                            <div className="col-md-6 match-height" style={{height: "472px"}}>
                                <div className="col-md-8">
                                    <div className="solarmax-technology">
                                        <h2>SolarMax Technology</h2>
                                        <h5>Long-Term Financing for an Ultra-Low Payment</h5>
                                        <p>20 year term at 3.99% APR</p>
                                        <p>$0 down payment required</p>
                                        <p>We apply your future 26% federal tax credit upfront to keep your payments low
                                            today</p>
                                        <h5>Cheaper than a Tesla</h5>
                                        <p>$49/mo for 3.1kW vs $65/mo for 3.8kW = 8% cheaper per month</p>
                                        <h5>Add-ons available</h5>
                                        <p>LG Home Battery for home backup and Time of Use savings</p>
                                        <p>Level 2 car charging inverter</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="tesla-technology">
                                        <h2>Tesla</h2>
                                        <h4>Homeowner receives no tax credit</h4>
                                        <h4>Adds no value to your home</h4>
                                        <h4>Installation is limited to only 9 California utilities</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section solarmax-technology-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3 col-md-offset-3">
                                <h2>SolarMax Technology</h2>
                                <h5>Available in virtually all California utilities</h5>
                                <h5>No deposit required</h5>
                                <h5>Energy consultation creates the right size system you need and guarantees the power
                                    that
                                    it will produces for 10 years</h5>
                                <h2>Tesla</h2>
                                <h4>No in-depth energy consultation to create a custom system that meets your unique
                                    needs.
                                    The one-click subscription is a pay and pray model.</h4>
                                <h4>The Powerwall for home battery backup cannot be included in the lease</h4>
                                <h4>No power production guarantee</h4>
                            </div>
                            <div className="col-md-5">
                                {/*<img className="img-responsive center-block lazyload" src="/img/ph-square.png"*/}
                                {/*     data-src="/img/page/tesla-solar.jpg" alt="Tesla Solar"/>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section cta-solarmax-tesla text-center">
                    <div className="container">
                        <h2>Invest In Your Home With Solarmax Technology!</h2>
                        <a className="btn btn-primary btn-custom scroll-to mt-3" href="#quote-form">Get Free Quote</a>
                    </div>
                </div>


            </div>
        </>
    );
}

export default SolarmaxVsTesla;