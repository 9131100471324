import React from 'react';
import '../../assets/css/sm.css'

function Resources(props) {
    return (
        <>
            <figure className="page-hero page-hero-form">
                <div className="div-table">
                    <div className="container hero-container">
                        <div className="hero-row">
                            <figcaption className="hero-inner">


                                <div className="h1">Helpful Solar Resources for California Homeowners</div>
                                <div className="lead">Get the answers to all of your solar energy questions with our
                                    thorough list of homeowner resources.
                                </div>

                            </figcaption>
                        </div>
                    </div>
                </div>
                <picture>
                    <img className="page-hero-bg" src="/img/page/hero-resources.jpg" alt="Background Photo"/>
                </picture>
            </figure>
            <div className="main-content">
                <div className="page-section heading-section">
                    <div className="section-header">
                        <h1>Solar Resources</h1>

                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <p>At SolarMax Technology, we understand that solar technology is still a relatively new
                                    energy source that many residents of California are not familiar with. While you
                                    might understand the basics, you also need to understand the potential costs and
                                    benefits in order to make the most informed decision. To help educate our customers,
                                    we offer homeowner resources that provide you with plenty of information about your
                                    solar investment, including:</p>
                            </div>
                            <div className="col-md-6">

                                <div className="page-offers-section">
                                    <div className="offer-content mx-auto p-4">
                                        <div className="offer-panel text-center">
                                            <div className="panel panel-default">
                                                <a href="/offers/solar-special/">
                                                    <div className="panel-body match-height">
                                                        <p className="offer-title h4">Home Solar Systems Starting at
                                                            Less Than $40/month</p>
                                                        <p>Enjoy top savings on our premier Solar
                                                            Panels &amp; Microinverters - starting at $39.83/month!</p>
                                                    </div>
                                                </a>
                                                <div className="panel-footer">
                                                    <a className="btn btn-info btn-custom ellipsis"
                                                       href="/offers/solar-special/">Save On Solar</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section services-products text-center">
                    <div className="section-header">
                        <h2 className="section-title">Solar Panel Resources</h2>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4 mb-2">
                                <a href="/resources/solar-panel-cost/" className="products">
                                    <img className="img-responsive center-block lazyload" src="/img/ph-square.png"
                                         data-src="/img/page/solar-panel-cost.jpg" alt="Solar Panel Cost"/>
                                    <div className="caption">
                                        <p className="h4">Solar Panel Cost</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-4 mb-2">
                                <a href="/resources/solar-rebates/" className="products">
                                    <img className="img-responsive center-block lazyload" src="/img/ph-square.png"
                                         data-src="/img/page/rebates.jpg" alt="Rebates"/>
                                    <div className="caption">
                                        <p className="h4">Rebates</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-4 mb-2">
                                <a href="/offers/" className="products">
                                    <img className="img-responsive center-block lazyload" src="/img/ph-square.png"
                                         data-src="/img/page/tax-credit.jpg" alt="Rebates"/>
                                    <div className="caption">
                                        <p className="h4">Tax Credit</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-4 mb-2">
                                <a href="/solar-faq/" className="products">
                                    <img className="img-responsive center-block lazyload" src="/img/ph-square.png"
                                         data-src="/img/page/solar-faq.jpg" alt="FAQ"/>
                                    <div className="caption">
                                        <p className="h4">FAQ</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-4 mb-2">
                                <a href="/lunch-and-learn/" className="products">
                                    <img className="img-responsive center-block lazyload" src="/img/ph-square.png"
                                         data-src="/img/page/lunch-and-learn.jpg" alt="Lunch & Learn"/>
                                    <div className="caption">
                                        <p className="h4">Lunch & Learn</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Solar Rebates for Major Savings</h2>
                                <p>In an effort to encourage more homeowners to invest in solar energy, utility
                                    companies and the government have started offering more incentives. With the federal
                                    solar tax credit, you can deduct up to 30 percent of your solar panel installation
                                    costs. You can also speak with your utility provider to see if their policy is to
                                    offer <a href="/resources/solar-rebates/">solar rebates</a> depending on the amount
                                    of energy your solar panels produce.</p>
                            </div>
                            <div className="col-md-6">
                                <div className="banner-areas-served panel">
                                    <div className="panel-body">
                                        <p className="h2 text-center">Areas We Serve</p>
                                        <div className="row city-list">

                                            <div className="col-xs-6 match-height">
                                                <a href="/anaheim/"><i className="fas fa-map-marker-alt mr-2"></i>Anaheim</a>
                                            </div>
                                            <div className="col-xs-6 match-height">
                                                <a href="/solar-panels/arcadia/"><i
                                                    className="fas fa-map-marker-alt mr-2"></i>Arcadia</a>
                                            </div>
                                            <div className="col-xs-6 match-height">
                                                <a href="/solar-panels/chino-hills/"><i
                                                    className="fas fa-map-marker-alt mr-2"></i>Chino Hills</a>
                                            </div>
                                            <div className="col-xs-6 match-height">
                                                <a href="/corona/"><i className="fas fa-map-marker-alt mr-2"></i>Corona</a>
                                            </div>
                                            <div className="col-xs-6 match-height">
                                                <a href="/solar-panels/diamond-bar/"><i
                                                    className="fas fa-map-marker-alt mr-2"></i>Diamond Bar</a>
                                            </div>
                                            <div className="col-xs-6 match-height">
                                                <a href="/fontana/"><i className="fas fa-map-marker-alt mr-2"></i>Fontana</a>
                                            </div>
                                            <div className="col-xs-6 match-height">
                                                <a href="/solar-panels/hacienda-heights/"><i
                                                    className="fas fa-map-marker-alt mr-2"></i>Hacienda Heights</a>
                                            </div>
                                            <div className="col-xs-6 match-height">
                                                <a href="/solar-panels/hemet/"><i
                                                    className="fas fa-map-marker-alt mr-2"></i>Hemet</a>
                                            </div>
                                            <div className="col-xs-6 match-height">
                                                <a href="/irvine/"><i className="fas fa-map-marker-alt mr-2"></i>Irvine</a>
                                            </div>
                                            <div className="col-xs-6 match-height">
                                                <a href="/solar-panels/long-beach/"><i
                                                    className="fas fa-map-marker-alt mr-2"></i>Long Beach</a>
                                            </div>
                                            <div className="col-xs-6 match-height">
                                                <a href="/los-angeles/"><i className="fas fa-map-marker-alt mr-2"></i>Los
                                                    Angeles</a>
                                            </div>
                                            <div className="col-xs-6 match-height">
                                                <a href="/solar-panels/menifee/"><i
                                                    className="fas fa-map-marker-alt mr-2"></i>Menifee</a>
                                            </div>

                                        </div>
                                        <div className="text-center">
                                            <a className="btn btn-outline-white btn-custom" href="/solar-panels/">View
                                                All Service Areas</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Resources;