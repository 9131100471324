import React from 'react';
import Faq from "./shared/faq";
import Explore from "./shared/explore";
import {Helmet, HelmetProvider} from "react-helmet-async";

function Warranties(props) {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Warranties</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/warranties`}/>
                </Helmet>
            </HelmetProvider>
            <section className="global__hero_single" style={{backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url('${require('../assets/media/pictures/static/warranty_hero.jpg')}')`,  backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition:'center'}}>
                <div className="container_s" style={{height: '100%'}}>
                    <div className="single__hero_wrapper">
                        <div className="single__hero_text">
                            <div className="single__hero_title text-heading-3">We've got you covered</div>
                            <div className="single__hero_desc  text-heading-5">From panels to inverters to workmanship, you don't need to worry whether our warranties will cover it</div>
                            <div className="single__hero_cta">
                                <button onClick={props.toggleForm}>Get a free quote</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*<section className="intro__type_simple">*/}
            {/*    <div className="container_s">*/}
            {/*        <div className="intro__simple_wrapper">*/}
            {/*            <div className="intro__simple_text">*/}
            {/*                <div className="intro__simple_title"></div>*/}
            {/*                <div className="intro__simple_desc"></div>*/}
            {/*            </div>*/}
            {/*            <div className="intro__simple_img">*/}
            {/*                <img src="" alt=""/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

            <section className="benefits__grid_simple">
                <div className="container_s">
                    <div className="benefits__grid_wrapper">
                        <div className="benefits__grid_text">
                            <div className="benefits__grid_title text-heading-5">What we offer</div>
                            <div className="benefits__grid_desc text-body-1">Along with products our customers get exceptional service and tested through time quality</div>
                        </div>
                        <div className="benefits__grid_elements">
                            <div className="benefits__grid_element">
                                <div className="benefits__element_icon"><i className="ri-donut-chart-line"></i></div>
                                <div className="benefits__element_title text-heading-5">Guaranteed Performance</div>
                                <div className="benefits__element_desc text-body-1">Our solar warranty guarantees that your panels will meet specified efficiency levels for a specified period, ensuring optimal energy production.</div>
                            </div>
                            <div className="benefits__grid_element">
                                <div className="benefits__element_icon"><i className="ri-hammer-line"></i></div>
                                <div className="benefits__element_title text-heading-5">Product Coverage</div>
                                <div className="benefits__element_desc text-body-1">Our warranty covers any defects in materials or workmanship, ensuring that any malfunctions due to manufacturing issues are repaired or replaced at no cost.</div>
                            </div>
                            <div className="benefits__grid_element">
                                <div className="benefits__element_icon"><i className="ri-shield-check-line"></i></div>
                                <div className="benefits__element_title text-heading-5">Long-lasting Protection</div>
                                <div className="benefits__element_desc text-body-1">Enjoy peace of mind with our warranty that lasts for 20 to 25 years, signifying the durability and reliability of our solar panels.</div>
                            </div>
                            <div className="benefits__grid_element">
                                <div className="benefits__element_icon"><i className="ri-wallet-3-line"></i></div>
                                <div className="benefits__element_title text-heading-5">Financial Security</div>
                                <div className="benefits__element_desc text-body-1">Our warranty protects you from unexpected expenses by covering repairs or replacements, reducing your financial risk.</div>
                            </div>
                            <div className="benefits__grid_element">
                                <div className="benefits__element_icon"><i className="ri-brain-line"></i></div>
                                <div className="benefits__element_title text-heading-5">Peace of Mind</div>
                                <div className="benefits__element_desc text-body-1">Count on our warranty to safeguard your solar investment, providing worry-free energy cost savings and sustainable power generation.</div>
                            </div>
                            <div className="benefits__grid_element">
                                <div className="benefits__element_icon"><i className="ri-home-gear-line"></i></div>
                                <div className="benefits__element_title text-heading-5">Transferable Assurance</div>
                                <div className="benefits__element_desc text-body-1">Our warranty is transferable, boosting your property's value by allowing the warranty to be passed on to subsequent homeowners.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="warranties__certificates">
                <div className="container_s">
                    <div className="warranties__certificates_wrapper">
                        {/*<div className="warranties__certificates_text">*/}
                        {/*    <div className="warranties__certificates_title text-heading-5"></div>*/}
                        {/*    <div className="warranties__certificates_desc text-body-1"></div>*/}
                        {/*</div>*/}
                        <div className="warranties__certificates-elements">
                            <div className="warranties__certificates_warranty">
                                <div className="warranties__warranty_title text-heading-5 mb-2">Comprehensive warranties</div>
                                <div className="warranties__warranty_img"><img src={require('../assets/media/pictures/static/global-credibility-1.png')} alt=""/></div>
                                <div className="warranties__warranty_desc text-body-1">Rest easy knowing that your solar system is protected against defects in materials and workmanship by our industry-leading 25-year warranty. We pride ourselves on providing prompt customer support and quick resolution in the unlikely event that an issue arises.</div>
                            </div>
                            <div className="warranties__certificates_certificate">
                                <div className="warranties__certificate_title text-heading-5 mb-2">Guaranteed quality</div>
                                <div className="warranties__certificate_desc text-body-1">Why trust SolarMax? Because we deliver top-tier solar solutions with premium products. The strict adherence to the extensive list of certifications and industry standards we follow helps ensure you get the superior results we design your system to provide.</div>
                                <div className="warranties__certificate_elements">
                                    <div className="warranties__certificate_element"><img src={require('../assets/media/pictures/static/certificates/ce_logo.png')} alt=""/></div>
                                    <div className="warranties__certificate_element"><img src={require('../assets/media/pictures/static/certificates/csa-certificate.png')} alt=""/></div>
                                    <div className="warranties__certificate_element"><img src={require('../assets/media/pictures/static/certificates/dve-certificate.png')} alt=""/></div>
                                    <div className="warranties__certificate_element"><img src={require('../assets/media/pictures/static/certificates/ul.png')} alt=""/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Explore explore={props.explore}/>

            <Faq/>
        </>
    );
}

export default Warranties;