import React from 'react';

function FormThanks({source}) {
    return (
        <div style={{ color: 'black' }}>
            <h1>Thank you!</h1>
            <h5>Your {source === "quotation" ? "quote request was submitted" : "message was sent"} successfully.</h5>
        </div>
    );
}

export default FormThanks;