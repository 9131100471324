import React, {useEffect, useState} from 'react';
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
import '../../assets/css/business-solutions.css'
import Global from "../shared/global";
import {Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";

function BusinessOwnerBattery(props) {
    const [solutionsIndex, setSolutionsIndex] = useState('0')
    const [sliderValue, setSliderValue] = useState("260")
    const setDefaultSlider = (e) =>{
        let slider = document.getElementById('slider')
        let selector = document.getElementById('selector')
        let selectValue = document.getElementById('selectValue')
        let progressBar = document.getElementById('progressBar')

        selectValue.innerHTML = "$ "+slider.value
        selector.style.left = slider.value/10 + '%'
        progressBar.style.width = slider.value/10 + '%'
    }
    useEffect(()=>{
        setDefaultSlider()
    },[])

    const handleInput = (e) =>{
        setSliderValue(e.target.value)
        setDefaultSlider()
    }

    const handleSolutionsTab = (e) =>{
        const index = e.target.dataset.tabindex
        if (index){
            setSolutionsIndex(index)
        }
    }

    const handleSubmitCta = (e) => {
        e.preventDefault()
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Businesses - ESS</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/businesses/battery`}/>
                </Helmet>
            </HelmetProvider>
            <section className="solutions__hero">
            <div className="">
                    <div className="solutions__hero_wrapper">
                        <div className="solutions__hero_pages">
                            <Splide aria-label="" hasTrack={false}
                                    options={{
                                        perPage: 1,
                                        arrows: false,
                                        type: 'loop',
                                        autoplay:true,
                                        resetProgress: false
                                    }}>
                                <SplideTrack>
                                    <SplideSlide style={{backgroundImage: `url('${require('../../assets/media/pictures/static/business/1.1 BO_Battery Hero (Crop).jpg')}')`, backgroundSize: "cover"}}>
                                        <div className="slide__wrapper container_s">
                                            <div className="slide__text">
                                                <div className="slide__sub text-heading-6">
                                                    Empowering businesses since 2008
                                                </div>
                                                <div className="slide__title text-heading-1">
                                                    Secure your operations with a dedicated ESS
                                                </div>
                                                <div className="slide__desc text-heading-4">
                                                    Maximize efficiency, reliability, and sustainability with a cutting-edge energy storage system
                                                </div>
                                                <div className="slide__cta text-heading-6">
                                                    <button className=" text-heading-6" onClick={props.toggleForm}>Get a tailored energy audit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                    <SplideSlide style={{backgroundImage: `url(${require('../../assets/media/pictures/static/homesolution/2Hero.jpg')})`, backgroundSize: `cover`, backgroundPosition:` ${props.bd.u_os === "desktop" ? 'center' : '65%' }`}}>
                                        <div className="slide__wrapper container_s">
                                            <div className="slide__text">
                                                <div className="slide__sub text-heading-6">
                                                    Empowering businesses since 2008
                                                </div>
                                                <div className="slide__title text-heading-1">
                                                    Energize your enterprise
                                                </div>
                                                <div className="slide__desc text-heading-4">
                                                    Save big, become energy-independent, and take pride in being eco-friendly
                                                </div>
                                                <div className="slide__cta text-heading-6">
                                                    <Link to={'/businesses/solar'} onClick={props.handleLink}>Learn more</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                </SplideTrack>
                                {/*<button className="splide__toggle" type="button">*/}
                                {/*    <span className="splide__toggle__play">Play</span>*/}
                                {/*    <span className="splide__toggle__pause">Pause</span>*/}
                                {/*</button>*/}
                                <div className="splide__progress">
                                    <div className="splide__progress__bar"/>
                                </div>

                            </Splide>
                        </div>
                    </div>
                </div>
            </section>

            <section className="solutions__intro" style={{background: `linear-gradient(179deg, #03141B 30.12%, rgba(255, 255, 255, 0.00) 92%), url(${require('../../assets/media/pictures/static/b-battery-intro.jpeg')}) no-repeat center`}}>

                <div className="container_s">
                    <div className="solutions__intro_wrapper">
                        <div className="solutions__intro_title text-heading-4 mb-1">
                            Next-Level energy management for your business
                        </div>
                        <div className="solutions__intro_desc text-body-1">
                            Find out how tailored energy storage solutions can minimize both energy costs and operational downtime.
                        </div>
                    </div>
                </div>
            </section>

            <section className="solutions__benefits_single">
                <div className="container_s">
                    <div className="solutions__benefits_wrapper">
                        <div className="solutions__benefits_title text-heading-4 mb-1">Keep your competitive edge <br/> with advanced energy storage</div>
                        <div className="solutions__benefits_desc text-body-1 mb-4">The benefits of protecting your business with a custom ESS are comprehensive and compelling</div>
                        <div className="solutions__benefits_elements">
                            <div className="solutions__benefits_element">
                                <div className="benefit__elements_icon">
                                    <i className="ri-wireless-charging-line"></i>
                                </div>
                                <div className="benefit__elements_title text-heading-5 mb-1">Reduced operational costs</div>
                                <div className="benefit__elements_desc text-body-1">Utilize stored energy during peak demand times to significantly reduce electricity costs</div>
                            </div>
                            <div className="solutions__benefits_element">
                                <div className="benefit__elements_icon">
                                    <i className="ri-shield-flash-line"></i>
                                </div>
                                <div className="benefit__elements_title text-heading-5 mb-1">Enhanced reliability</div>
                                <div className="benefit__elements_desc text-body-1">Eliminate operational disruptions with dependable backup power during outages</div>
                            </div>
                            <div className="solutions__benefits_element">
                                <div className="benefit__elements_icon">
                                    <i className="ri-seedling-line"></i>
                                </div>
                                <div className="benefit__elements_title text-heading-5 mb-1">Green corporate branding</div>
                                <div className="benefit__elements_desc text-body-1">Show corporate social responsibility by lowering carbon emissions with green power</div>
                            </div>
                            <div className="solutions__benefits_element">
                                <div className="benefit__elements_icon">
                                    <i className="ri-funds-line"></i>
                                </div>
                                <div className="benefit__elements_title text-heading-5 mb-1">Scalability and flexibility</div>
                                <div className="benefit__elements_desc text-body-1">Easily expand your energy storage capacity as your business and energy demand grow</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={'solutions__single'}>
                <div className="container_s">
                    <div className="solutions__wrapper mt-5 mb-3">
                        <div className="solutions__text mb-3">
                            <div className="solutions__title section__title text-heading-4 mb-1">
                                One battery, many uses
                            </div>
                        </div>
                        <ul className="solutions__benefits">
                            <li data-tabindex={0} onClick={event => handleSolutionsTab(event)} className="solution__benefit">
                                <div> <p className={'tab__title text-heading-6'} data-tabindex={0}>Industrial manufacturing
                                </p>
                                    <div className={solutionsIndex !== "0" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="benefits__sub_wrapper" style={{backgroundImage: `url('${require('../../assets/media/pictures/static/business/3.1 Industrial Manufacturing.jpg')}')`}}>
                                            <div className="benefits__sub_info">
                                                <div className="tab__text">
                                                    <div className="tab__single_icon mb-2">
                                                        <i className="ri-building-4-fill"></i>
                                                    </div>
                                                    <div className="tab__single_title mb-2 text-heading-4">
                                                        Stable and reliable power for factories
                                                    </div>
                                                    <div className="tab__single_desc mb-2">
                                                        Protect your equipment, operations, and output capacity with a consistent supply of power, regardless of grid conditions
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-tabindex={1} onClick={event => handleSolutionsTab(event)} className="solution__benefit">
                                <div> <p className={'tab__title text-heading-6'} data-tabindex={1}>Agricultural production
                                </p>
                                    <div className={solutionsIndex !== "1" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="benefits__sub_wrapper" style={{backgroundImage: `url('${require('../../assets/media/pictures/static/business/3.2 Agrivoltaics (Crop).jpg')}')`}}>
                                            <div className="benefits__sub_info">
                                                <div className="tab__text">
                                                    <div className="tab__single_icon mb-2">
                                                        <i className="ri-flower-fill"></i>
                                                    </div>
                                                    <div className="tab__single_title mb-2 text-heading-4">
                                                        Energy savings for farmers
                                                    </div>
                                                    <div className="tab__single_desc mb-2">
                                                        Harvest big savings and keep your farm's operations green with
                                                        the power of Agrivoltalics (APV)
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-tabindex={2} onClick={event => handleSolutionsTab(event)}
                                className="solution__benefit">
                                <div><p className={'tab__title text-heading-6'} data-tabindex={2}>Emergency services</p>
                                    <div
                                        className={solutionsIndex !== "2" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="benefits__sub_wrapper"
                                             style={{backgroundImage: `url('${require('../../assets/media/pictures/static/business/3.3 Emergency services (Crop).jpg')}')`}}>
                                            <div className="benefits__sub_info">
                                                <div className="tab__text">
                                                    <div className="tab__single_icon mb-2">
                                                        <i className="ri-hospital-line"></i>
                                                    </div>
                                                    <div className="tab__single_title mb-2 text-heading-4">
                                                        Power when it's needed most
                                                    </div>
                                                    <div className="tab__single_desc mb-2">
                                                        Trust that our backup power solutions will keep your support
                                                        services online to save lives, no matter what comes
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div style={{transform: `translateX(${solutionsIndex * 100}%)`}}
                                     className={'tab__active'}></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="partners">
                <div className="container_s">
                    <div className="partners__wrapper">
                        <div className="partners__title text-heading-4">PARTNERS</div>
                        <Splide aria-label="" className={'partners__list'} hasTrack={false}
                                options={{
                                    perPage: props.bd.u_os === "desktop" ? 5 : 3,
                                    arrows: false,
                                    type: 'loop',
                                    autoplay:true,
                                    resetProgress: false,
                                    gap: props.bd.u_os === "desktop" ?'32px' : '0px',
                                    pagination: false,
                                    perMove:1,
                                    interval: 2000
                                }}
                        >
                            <SplideTrack>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-1.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-2.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-3.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-4.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-12.jpg')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-6.jpg')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-7.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-8.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-9.jpg')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-10.png')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-11.jpg')} alt=""/></div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="partner"><img src={require('../../assets/media/pictures/static/partners/partner-5.png')} alt=""/></div>
                                </SplideSlide>

                            </SplideTrack>

                        </Splide>
                    </div>
                </div>
            </div>

            <section className="calculator__cta">
                <div className="container">
                    <div className="calculator__cta_wrapper">
                        <form className="calculator__cta_interact" method={'POST'} onSubmit={event => handleSubmitCta(event)}>
                            <div className="calculator__cta_title text-heading-3 mb-1">Unlock a new level of savings</div>
                            <div className="calculator__cta_desc text-body-1">Get a quick custom assessment of how much your business could save.</div>
                            <div className="calculator__cta_input">
                                <input type="range" min="0" max="1000" value={sliderValue} id="slider" onInput={event =>handleInput(event) }/>
                                <div id="selector">
                                    <div className="selectBtn"></div>
                                    <div id="selectValue" className={"text-body-1"}></div>
                                </div>
                                <div id="progressBar"></div>
                                <div id="progressBackground"></div>
                                <div className="input__range"><span>$0</span><span>$1000</span></div>
                            </div>
                            <div className="calculator__cta_cta"><button className={'text-heading-7'} type="submit">Calculate</button></div>
                        </form>
                        <div className="calculator__cta_image">
                            <img src={`${require('../../assets/media/pictures/static/business/4. Slider image.jpg')}`} alt=""/>
                        </div>
                    </div>
                </div>
            </section>

            <Global debugValue={props.debugValue}/>
        </>

    );
}

export default BusinessOwnerBattery;