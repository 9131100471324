'use client';

import useSWR from "swr";

const { v4: uuidv4 } = require('uuid');

export class Tags {
    constructor(source, medium, campaign, term, content) {
        this.source = source;
        this.medium = medium;
        this.campaign = campaign;
        this.term = term;
        this.content = content;
    }
    static Create(value: any){
        return new this(
            value.get("utm_source"),
            value.get("utm_medium"),
            value.get("utm_campaign"),
            value.get("utm_term"),
            value.get("utm_content")
        )
    }
}


export const cleanup = async (bd, pass: string, handle_render) => {
    if (process.env.REACT_APP_PROD === 'false' && bd.t && bd.t !== '') {
        try {
            fetch(`${pass}/api/visitor/add-record`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + bd.t,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    u_pv: bd.u_pv,
                    u_br: bd.u_br,
                    u_os: bd.u_os,
                    user_id: bd.user_id,
                    source: bd.source
                })
            }).then(res=> {
                handle_render()
            }).catch(err => console.log(err))
        } catch (error) {
            console.error('Error sending data on unload:', error);
        }
    }
};

export const cleanup_update = async (bd, pass: string) => {
    if (process.env.REACT_APP_PROD === 'true' && bd.t && bd.t !== '') {
        try {
            fetch(`${pass}/api/visitor/update-record`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + bd.t,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    u_pv: bd.u_pv,
                    u_br: bd.u_br,
                    u_os: bd.u_os,
                    user_id: bd.user_id,
                    source: bd.source
                })
            }).catch(err => console.log(err))
        } catch (error) {
            console.error('Error sending data on unload:', error);
        }
    }
};
export const useAnalytics = (bd, setT, first_render) => {
    let updater = () => {
        if (bd.u_pv.length > 0) {
            bd.u_pv[bd.u_pv.length - 1].timestamp = Date.now();
        }
        setT(bd.t, bd.u_br, bd.user_id, bd.u_os, bd.source)
    }
    return useSWR("UPDATE_ANALYTICS", updater, {refreshInterval: 10000})
}

export const check_device = () =>{
    if (window.innerWidth < 767) return "mobile";
    if (window.innerWidth >= 767 && window.innerWidth <= 1280) return "tablet";
    if (window.innerWidth > 1280) return "desktop";
}

export const check_tags = () => {
    let params = new URLSearchParams(window.location.search);

    if (params.size === 0){
        return JSON.stringify({
            term: null,
            medium: "organic",
            source: "website",
            content: null,
            campaign: null
        })
    }
    return Tags.Create(params)
}

export const check_browser = () =>{
    let N = navigator.appName, ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*([\d\.]+)/i);

    if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
    M = M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];

    return M.join(' ');
}

export const gen_id = () =>{
    return uuidv4()
}

export const add_pages = (bd, page, pass, add, first_render, handle_render) => {
    let tmp_list = bd.u_pv
    tmp_list.push({path: page, timestamp: Date.now()})

    if (first_render){
        if (bd.t !== ''){
            cleanup(bd, pass, handle_render);
        }
    }else{
        if (bd.t !== ''){
            cleanup_update(bd, pass);
        }
    }

    add(tmp_list)
}

export const check_env = () =>{
    if (process.env.REACT_APP_PROD === "true") return `https://${process.env.REACT_APP_URL_DEV}`
    if (process.env.REACT_APP_PROD === "false") return `http://${process.env.REACT_APP_URL}`
}

export const checkToken = (name='solar-user') =>{
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    let cookie = parts.length === 2 ? parts.pop().split(';').shift() : null;
    if (cookie) return  cookie
    if (!cookie) return  false
}

export const getToken = (debugValue, setT) =>{
    try{
        fetch(`${debugValue}/api/gen-token`, {method: 'GET'}).then(res=> res.json()).then(data =>{
            var now = new Date();
            var time = now.getTime();
            time += 3600 * 1000;
            now.setTime(time);
            document.cookie = `solar-user=${data.token}; expires=${now.toUTCString()}`
            let b = check_browser()
            let id = gen_id()
            let s = check_device()
            let source  = check_tags()

            setT(data.token, b, id, s, source)
        }).catch(err=>console.log(err))
    }catch(err){
        console.log(err)
    }

}

export const copyToClipboard = (e) => {
    let value = e.target.value;
    e.target.select();
    e.target.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(value).then(()=> console.log('Copied to clipboard'));
}