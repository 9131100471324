import React from 'react';
/* global google */
import {Form} from "react-router-dom";
import {handleQuoteForm} from "../../fetch/form";
import '../../assets/css/contact-form.css'
import FormThanks from "./form-thanks";


export function Address(address_full){
    function check_address(address,type){
        for(const a of address){
            if (a && a.types && a.types.some(t=> t === type)){
                return a.long_name ? a.long_name : a.short_name;
            }
        }
        return null
    }
    this.coutry = check_address(address_full, "country");
    this.state = check_address(address_full, "administrative_area_level_1");
    this.city = check_address(address_full, "locality");
    this.zip_code = check_address(address_full, "postal_code");
    this.street = check_address(address_full, "route");
    this.street_num = check_address(address_full, "street_number");
}

const FormType = {
    quotation: {
        type: "quotation",
        text: function () {
            return (
                <>
                    <p style={{margin: '0'}} className="form__title">Get a 4kW solar system and 5kWh battery for
                        just</p>
                    <p style={{margin: '0'}} className="form__sub"><b> $79 </b>/month</p>
                    <p style={{margin: '0'}} className="form__desc">Lock in our best deal ever on premium panels,
                        microinverters,
                        and battery storage before it's gone.</p>
                </>
            )
        },
        cta: "Get the quote"
    },
    contact: {
        type: "contact",
        text: function (){
            return(
                <>
                    <p style={{margin: '0'}} className="form__sub">Contact us through the <b> Email</b></p>
                    <p style={{margin: '0'}} className="form__desc">Or call us <b><a href="tel:(951) 221-8172">directly</a></b></p>
                </>
            )
        },
        cta: "Send the message"
    }
}

const FormQuote = (props) => {
    const [formData, setFormData] = React.useState({})
    const [loading, setLoading] = React.useState(300);
    const [error, setError] = React.useState(null);

    const handleInput = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleAddress = (e) =>{
        function handlePlaceChanged(){
            const place = autocomplete.getPlace();

            setFormData({...formData, address: new Address(place.address_components)})
        }
        let autocomplete = new google.maps.places.Autocomplete(document.getElementById(props.formSource === "quotation" ? "autocomplete_quotation" : "autocomplete_contact"),
            {"fields": ["address_components", "formatted_address"]});

        autocomplete.addListener('place_changed', handlePlaceChanged);
    }

    const handleError = (error) => {
        setError(error)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(301)
        handleQuoteForm(formData, props, handleError, props.formSource).then(res=> {
            if (res && res.status === 200) {
                setLoading(200)
            }else{
                setLoading(400)
            }
        })
    }

    return (
        <div className={"form__holder"}>
            {loading === 200 ? <FormThanks source={props.formSource}/> :
                <Form
                    style={{background: "white"}}
                    onSubmit={event => handleSubmit(event)}
                    id={"contact-form-custom"}
                >
                    {FormType[props.formSource]?.text()}
                    <div className="entry__container">
                        <input type="hidden" value={'contact'} name={'source'} id={'source'}/>
                        <input type="text" name='firstName' id="first_name" required placeholder="Full name"
                               onChange={event => handleInput(event)}/>
                        <input type="text" name='lastName' id="last_name" required placeholder="Last name"
                               autoComplete="off" onChange={event => handleInput(event)}/>
                        <input type="email" name='email' id="email" required placeholder="Email" autoComplete="off"
                               onChange={event => handleInput(event)}/>
                        <input type="text" name='phone' id="phone" required placeholder="Phone number"
                               autoComplete="off"
                               onChange={event => handleInput(event)}/>
                        <input type="text" id={props.formSource === "quotation" ? "autocomplete_quotation" : "autocomplete_contact" } placeholder="Enter your address" name={"address"} onChange={event => handleAddress(event)}/>
                        <select name="type" id="type" required
                                onChange={event => handleInput(event)} defaultValue={""}>
                            <option value="" disabled>Select the topic</option>
                            <option value="Residential">Residential solar energy</option>
                            <option value="Commercial">Commercial solutions</option>
                            <option value="Led">LED lighting</option>
                            <option value="Roof">Roof replacement</option>
                        </select>

                        <textarea name="comment" id="comment"
                                  placeholder="Tells us more about what you are interested in" cols="30" rows="5"
                                  autoComplete="off" onChange={event => handleInput(event)}></textarea>
                    </div>
                    {error &&
                        <p style={{fontSize: '12px', color: 'red'}}>{error}</p>
                    }

                    <p>

                        {loading === 301 ? <button disabled className={'text-body-1 mb-1'}
                                                   style={{
                                                       fontWeight: 'bold',
                                                       cursor: 'crosshair',
                                                       pointerEvents: "none"
                                                   }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="65" height="15"
                                     viewBox="0 0 135 140" fill="#fff">
                                    <rect y="10" width="15" height="120" rx="6">
                                        <animate attributeName="height" begin="0.5s" dur="1s"
                                                 values="120;110;100;90;80;70;60;50;40;140;120"
                                                 calcMode="linear" repeatCount="indefinite"/>
                                        <animate attributeName="y" begin="0.5s" dur="1s"
                                                 values="10;15;20;25;30;35;40;45;50;0;10"
                                                 calcMode="linear" repeatCount="indefinite"/>
                                    </rect>
                                    <rect x="30" y="10" width="15" height="120" rx="6">
                                        <animate attributeName="height" begin="0.25s" dur="1s"
                                                 values="120;110;100;90;80;70;60;50;40;140;120"
                                                 calcMode="linear" repeatCount="indefinite"/>
                                        <animate attributeName="y" begin="0.25s" dur="1s"
                                                 values="10;15;20;25;30;35;40;45;50;0;10"
                                                 calcMode="linear" repeatCount="indefinite"/>
                                    </rect>
                                    <rect x="60" width="15" height="140" rx="6">
                                        <animate attributeName="height" begin="0s" dur="1s"
                                                 values="120;110;100;90;80;70;60;50;40;140;120"
                                                 calcMode="linear" repeatCount="indefinite"/>
                                        <animate attributeName="y" begin="0s" dur="1s"
                                                 values="10;15;20;25;30;35;40;45;50;0;10"
                                                 calcMode="linear" repeatCount="indefinite"/>
                                    </rect>
                                    <rect x="90" y="10" width="15" height="120" rx="6">
                                        <animate attributeName="height" begin="0.25s" dur="1s"
                                                 values="120;110;100;90;80;70;60;50;40;140;120"
                                                 calcMode="linear" repeatCount="indefinite"/>
                                        <animate attributeName="y" begin="0.25s" dur="1s"
                                                 values="10;15;20;25;30;35;40;45;50;0;10"
                                                 calcMode="linear" repeatCount="indefinite"/>
                                    </rect>
                                    <rect x="120" y="10" width="15" height="120" rx="6">
                                        <animate attributeName="height" begin="0.5s" dur="1s"
                                                 values="120;110;100;90;80;70;60;50;40;140;120"
                                                 calcMode="linear" repeatCount="indefinite"/>
                                        <animate attributeName="y" begin="0.5s" dur="1s"
                                                 values="10;15;20;25;30;35;40;45;50;0;10"
                                                 calcMode="linear" repeatCount="indefinite"/>
                                    </rect>
                                </svg>
                            </button> :
                            <button type="submit" className="g-recaptcha" style={{width: '100%'}}
                                    data-sitekey="6LfYUwIqAAAAANjkT-HvIF5P-XxjMwfMvul_ffJD"
                                    data-callback='onSubmit'
                                    data-action='submit'>{FormType[props.formSource]?.cta}
                            </button>

                        }

                    </p>
                    <span className="montserrat-form form__desc">Your personal information is never distributed or sold to anyone. You can opt-out of communications at any time. View our<a
                        className="privacy__link" href=""> privacy policy</a>.</span>
                </Form>
            }
        </div>
    )
}

export default FormQuote;