import React from 'react';
import ReactDOM from 'react-dom/client';
import Main from './views/layouts/main';
import {Helmet, HelmetProvider} from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HelmetProvider>
        <Helmet>

        </Helmet>
        <Main />
    </HelmetProvider>
);


