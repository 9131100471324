import React from 'react';
import {Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";

function Explore(props) {
    const handleLink = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    return (
        <>
            <section className="explores">
                <div className="container_s">
                    <div className="explores__wrapper">
                        <div className="explores__text">
                            <div className="explores__title text-heading-5">Explore our support offerings</div>
                        </div>
                        <div className="explores__elements">
                            {props.explore && props.explore.map(item => {
                                return (
                                    <div key={item.title} className="explore" >
                                        <img src={`${item.photo}`} alt=""/>
                                        <div className="explore__text">
                                            <div className="explore__title text-heading-3">{item.title}</div>
                                            <div className="explore__desc text-heading-6">{item.description}</div>
                                            <div className="explore__cta text-heading-6"><Link onClick={handleLink}
                                                                                               to={item.link}>Explore
                                                offering</Link></div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default Explore;