const handleSolarEstimatorSubmit = (formData, handleStatus, props, calculationResult, handleCalculationResult) =>{
    if (formData.firstname.length > 0 && formData.lastname.length > 0 && formData.u_data.length > 0){
        handleStatus(300)
        let tmp_data = formData
        tmp_data.user_id = props.bd.user_id
        fetch(`${props.debugValue}/api/form/solar-estimator`,{
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + props.bd.t,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json()).then(data => {
            if (data.status === 200) {
                handleStatus(200)
                handleCalculationResult({...calculationResult,
                    bill_saved: data.bill_saved,
                    overall_savings: data.overall_savings,
                })
            }else if (data.status === 400) {
                handleStatus(400)
                alert('The estimate was not sent, please try again.')
            }else if (data.status === 401) {
                alert('Issue with estimation please try again.')
            }
        }).catch(err=>{
            handleStatus(301)
            alert('Something went wrong! Please try again.')
        })
    }else{
        alert('Please fill all the required fields.')
    }
}

module.exports = {
    handleSolarEstimatorSubmit
}