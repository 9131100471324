import React from 'react';

function WhyChooseSolarmax(props) {
    return (
        <div>
            <div className="position-relative">


                <a href="#price-quote" className="btn btn-primary btn-quote-ft-mobile scroll-to">Free Quote</a>

                <figure className="page-hero page-hero-form">
                    <div className="div-table">
                        <div className="container hero-container">
                            <div className="hero-row">
                                <figcaption className="hero-inner">


                                    <div className="h1">Want to Choose the Right Solar Company?</div>
                                    <div className="lead">SolarMax Technology does everything better:</div>

                                </figcaption>
                                <div className="hero-quote-section">
                                    <div id="quote-form" className="quote-form panel panel-quote-form">
                                        <div className="panel-body">
                                            <iframe className={"form__holder"}
                                                    style={{
                                                        width: '100%',
                                                        height: '740px',
                                                        position: 'sticky',
                                                        top: '80px'
                                                    }}
                                                    id={'solarmax__form'}
                                                    src={`https://4345356.extforms.netsuite.com/app/site/crm/externalleadpage.nl?compid=4345356${props.form_source}`}
                                                    frameBorder="0"></iframe>
                                        </div>
                                    </div>

                                    <script defer="" src="/js/bundle/bundle.ui.quote.min.js?v=17.0.5.1"></script>
                                </div>
                            </div>
                        </div>
                    </div>
                    <picture>
                        <img className="page-hero-bg" src="/img/page/hero-why-choose-solarmax.jpg"
                             alt="Background Photo"/>
                    </picture>
                </figure>
            </div>
            <div className="main-content">

                <div className="page-section process-section text-center">
                    <div className="section-header">
                        <img className="img-responsive center-block" src="/img/logo.png" alt="Logo"/>


                        <h1>Get The Best Price, No Middle Man</h1>

                        <p className="lead">by doing everything in-house, we pass all the saving on to you</p>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 mb-2">
                                <div className="process mx-auto">
                                    <img className="img-responsive center-block" src="/img/page/icon-panel.png"
                                         alt="Solar Panels Assembled Locally"/>
                                    <div className="caption">
                                        <p className="h4">Solar Panels Assembled Locally</p>
                                        <p>Avoid panel import tariffs as well as shipping, freight, and distribution
                                            mark-ups</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-2">
                                <div className="process mx-auto">
                                    <img className="img-responsive center-block" src="/img/page/icon-inhouse.png"
                                         alt="In-House Design and Engineering"/>
                                    <div className="caption">
                                        <p className="h4">In-House Design and Engineering</p>
                                        <p>Solar experts work with you to customize your system for your individual
                                            power needs</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-2">
                                <div className="process mx-auto">
                                    <img className="img-responsive center-block" src="/img/page/icon-warehouse.png"
                                         alt="State of Art Warehouse"/>
                                    <div className="caption">
                                        <p className="h4">State of Art Warehouse</p>
                                        <p>Our warehouse allows us greater purchasing power to reduce costs to the
                                            customer</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-2">
                                <div className="process mx-auto">
                                    <img className="img-responsive center-block" src="/img/page/icon-customer.png"
                                         alt="Customer Service Hub"/>
                                    <div className="caption">
                                        <p className="h4">Customer Service Hub</p>
                                        <p>Dedicated project management team to help during your build and for years to
                                            come</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section projects-details">
                    <div className="section-header">
                        <h2 className="section-title">The only California Solar Company Whose Entire Operation is All
                            under One Roof</h2>
                        <ul className="fa-ul mt-4">
                            <li><i className="fa fa-circle fa-li"
                                   aria-hidden="true"></i><strong>2008</strong> Established
                            </li>
                            <li><i className="fa fa-circle fa-li" aria-hidden="true"></i><strong>165,000</strong> sq ft
                                Headquarters
                            </li>
                            <li><i className="fa fa-circle fa-li"
                                   aria-hidden="true"></i><strong>12,000+</strong> Projects completed
                            </li>
                            <li><i className="fa fa-circle fa-li" aria-hidden="true"></i><strong>Top 1%</strong> Solar
                                installers in US
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="page-section testimonial-section text-center">
                    <div className="section-header">
                        <h2 className="section-title">Our Customers Love Us</h2>
                        <p className="lead">As California’s renewable energy leader, we’ve helped thousands of customers
                            switch to solar energy.</p>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="carousel slide" data-ride="carousel" id="quote-carousel">
                                    <div className="carousel-inner text-center">

                                        <div className="item">
                                            <blockquote>
                                                <div className="row">
                                                    <div className="col-sm-8 col-sm-offset-2">
                                                        <p>The process was extremely smooth, and the crews who installed
                                                            all of our equipment were punctual and professional.</p>
                                                        <small>Kelly McCaulley, Upland</small>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>

                                        <div className="item">
                                            <blockquote>
                                                <div className="row">
                                                    <div className="col-sm-8 col-sm-offset-2">
                                                        <p>The SolarMax team were always quick in responding to our
                                                            questions and keeping us informed throughout the project.
                                                            SolarMax price was very reasonable and we are a completely
                                                            satisfied customer.</p>
                                                        <small>Mike and Jane, Trabuco Canyon</small>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>

                                        <div className="item active">
                                            <blockquote>
                                                <div className="row">
                                                    <div className="col-sm-8 col-sm-offset-2">
                                                        <p>From the beginning to the end every SolarMax employee handled
                                                            themselves professionally and courteously. The project took
                                                            less than 90 days from contract signing to PTO and was
                                                            seamless.</p>
                                                        <small>Joe Aceves, Corona</small>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>

                                        <div className="item">
                                            <blockquote>
                                                <div className="row">
                                                    <div className="col-sm-8 col-sm-offset-2">
                                                        <p>I have had a SolarMax system for one year. This company has a
                                                            high level of integrity and ethics. The system exceeded my
                                                            expectations. Unlike many of today's contractors this
                                                            company completed, showed up on time and did what they said
                                                            they were going to do from start to finish.</p>
                                                        <small>Lousi Daniel, Cherry Valley</small>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>

                                    </div>
                                    <ol className="carousel-indicators">
                                        <li data-target="#quote-carousel" data-slide-to="0" className=""><img
                                            className="img-responsive" src="/img/page/susan.jpg" alt=""/></li>
                                        <li data-target="#quote-carousel" data-slide-to="1" className=""><img
                                            className="img-responsive" src="/img/page/chase.jpg" alt=""/></li>
                                        <li data-target="#quote-carousel" data-slide-to="2" className="active"><img
                                            className="img-responsive " src="/img/page/tyn.jpg" alt=""/></li>
                                        <li data-target="#quote-carousel" data-slide-to="3" className=""><img
                                            className="img-responsive" src="/img/page/john.jpg" alt=""/></li>
                                    </ol>

                                    <a data-slide="prev" href="#quote-carousel" className="left carousel-control"><i
                                        className="fa fa-chevron-left" aria-hidden="true"></i></a>
                                    <a data-slide="next" href="#quote-carousel" className="right carousel-control"><i
                                        className="fa fa-chevron-right" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section experience-section">
                    <div className="section-header">
                        <h2 className="section-title">Experience Unbeatable Quality</h2>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="experience mx-auto">
                                    <img className="img-responsive center-block"
                                         src="/img/page/logo-25year-warranty-solar-panels.png"
                                         alt="25 year solar panel power production guarantee"/>
                                    <ul className="fa-ul">
                                        <li className="mb-3"><i className="fa fa-circle fa-li" aria-hidden="true"></i>SolarMax
                                            solar panels are one of only four US companies rated in the top tier of
                                            reliability in the world for the past two years.
                                        </li>
                                        <li className="mb-3"><i className="fa fa-circle fa-li" aria-hidden="true"></i>25
                                            year solar panel power production guarantee
                                        </li>
                                        <li className="mb-3"><i className="fa fa-circle fa-li" aria-hidden="true"></i>Designed,
                                            engineered and assembled in Southern California headquarters guarantees
                                            quality control
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="experience mx-auto">
                                    <img className="img-responsive center-block"
                                         src="/img/page/logo-25year-workmanship.png" alt="25 year workmanship"/>
                                    <ul className="fa-ul">
                                        <li className="mb-3"><i className="fa fa-circle fa-li" aria-hidden="true"></i>25
                                            year limited workmanship warranty.
                                        </li>
                                        <li className="mb-3"><i className="fa fa-circle fa-li" aria-hidden="true"></i>The
                                            best in the industry
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="experience mx-auto">
                                    <img className="img-responsive center-block"
                                         src="/img/page/logo-10year-power-production.png"
                                         alt="10 Year Guarantee on Power Production"/>
                                    <ul className="fa-ul">
                                        <li className="mb-3"><i className="fa fa-circle fa-li" aria-hidden="true"></i>The
                                            first solar company to offer a 10 year complete solar energy system power
                                            production guarantee.
                                        </li>
                                        <li className="mb-3"><i className="fa fa-circle fa-li" aria-hidden="true"></i>We
                                            guarantee that your premium SolarMax system will produce as promised, or we
                                            pay for your utility charges.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="page-section cta-consultation">
                    <div className="container">
                        <h2>Big Savings on Your Home Solar System</h2>
                        <p>Solar Panel System with Inverter just $59.99/month – Plus NEM 2.0 Savings if You Hurry!</p>
                        <a className="btn btn-customise mt-3">Free Consultation</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyChooseSolarmax;
