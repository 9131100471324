import React, {useEffect, useRef, useState} from 'react';
import {Form} from "react-router-dom";
import {createReferral, referFriendFull, ReferralStorage} from "../../../fetch/referral";
import ReferralThanks from "../referral-thanks";

function ReferralInviteFull({data, updateReferral}) {
    const [status, setStatus] = useState(301);

    const referralFull = useRef({
        rlFirstName: null,
        rlLastName: null,
        rlEmail: null,
        rlPhone: "",
        firstName: null,
        lastName: null,
        email: null,
        phone: "",
    });

    const submitReferralFull = (e) => {
        e.preventDefault();
        setStatus(301)

        if (!Object.values(referralFull).some(r => r === null)){
            referFriendFull(referralFull, data).then(rl => {
                if (rl && rl.status === 200) {
                    if(rl.data && rl.data.status === 200){
                        localStorage.setItem('referral', JSON.stringify(new ReferralStorage(rl.data.referral)))
                        updateReferral()
                    }else{
                        setStatus(400)
                    }
                    setStatus(200)
                }
                else setStatus(400)
            })
        }
    }

    const changeInput = (e) => {
        referralFull.current = {...referralFull.current, [e.target.name]: e.target.value}
    }

    return (
        <section className={"modal__form_section"}>
            <div className={"referral__form_full"}>
                <div className="referral__form-wrapper">
                    <div className="referral__form">
                        {status === 200 ? <ReferralThanks/>
                            : <Form
                                onSubmit={event => submitReferralFull(event)}
                                id={"referrer-full-form-custom"}
                            >
                                <h3>Shine Together: Earn Cash by Referring a Friend!</h3>
                                <h5>You are:</h5>
                                <input
                                    type="text"
                                    name={"firstName"}
                                    required={true}
                                    placeholder={"First name"}
                                    onChange={event => changeInput(event)}
                                />
                                <input
                                    type="text"
                                    name={"lastName"}
                                    required={true}
                                    placeholder={"Last name"}
                                    onChange={event => changeInput(event)}
                                />
                                <input
                                    type="email"
                                    name={"email"}
                                    required={true}
                                    placeholder={"Email"}
                                    onChange={event => changeInput(event)}
                                />
                                <input
                                    type="text"
                                    name={"phone"}
                                    placeholder={"Phone (optional)"}
                                    onChange={event => changeInput(event)}
                                />
                                <br/>
                                <h5>Your friend is:</h5>
                                <input
                                    type="text"
                                    name={"rlFirstName"}
                                    placeholder={"Your friend's first name"}
                                    onChange={event => changeInput(event)}
                                />
                                <input
                                    type="text"
                                    name={"rlLastName"}
                                    placeholder={"Your friend's last name"}
                                    onChange={event => changeInput(event)}
                                />
                                <input
                                    type="email"
                                    name={"rlEmail"}
                                    placeholder={"You friend's email"}
                                    onChange={event => changeInput(event)}
                                />
                                <input
                                    type="text"
                                    name={"rlPhone"}
                                    placeholder={"Your friend's phone (optional)"}
                                    onChange={event => changeInput(event)}
                                />
                                {status === 400 &&
                                    <span style={{color: "red"}} className="montserrat-form form__desc">It seems that your friend's account already exists in system. Try another account or refresh the page.</span>}

                                {status === 300 ?
                                    <button disabled className={'text-body-1 mb-1'}
                                            style={{fontWeight: 'bold', cursor: 'crosshair'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="135" height="30"
                                             viewBox="0 0 135 140" fill="#fff">
                                            <rect y="10" width="15" height="120" rx="6">
                                                <animate attributeName="height" begin="0.5s" dur="1s"
                                                         values="120;110;100;90;80;70;60;50;40;140;120"
                                                         calcMode="linear" repeatCount="indefinite"/>
                                                <animate attributeName="y" begin="0.5s" dur="1s"
                                                         values="10;15;20;25;30;35;40;45;50;0;10"
                                                         calcMode="linear" repeatCount="indefinite"/>
                                            </rect>
                                            <rect x="30" y="10" width="15" height="120" rx="6">
                                                <animate attributeName="height" begin="0.25s" dur="1s"
                                                         values="120;110;100;90;80;70;60;50;40;140;120"
                                                         calcMode="linear" repeatCount="indefinite"/>
                                                <animate attributeName="y" begin="0.25s" dur="1s"
                                                         values="10;15;20;25;30;35;40;45;50;0;10"
                                                         calcMode="linear" repeatCount="indefinite"/>
                                            </rect>
                                            <rect x="60" width="15" height="140" rx="6">
                                                <animate attributeName="height" begin="0s" dur="1s"
                                                         values="120;110;100;90;80;70;60;50;40;140;120"
                                                         calcMode="linear" repeatCount="indefinite"/>
                                                <animate attributeName="y" begin="0s" dur="1s"
                                                         values="10;15;20;25;30;35;40;45;50;0;10"
                                                         calcMode="linear" repeatCount="indefinite"/>
                                            </rect>
                                            <rect x="90" y="10" width="15" height="120" rx="6">
                                                <animate attributeName="height" begin="0.25s" dur="1s"
                                                         values="120;110;100;90;80;70;60;50;40;140;120"
                                                         calcMode="linear" repeatCount="indefinite"/>
                                                <animate attributeName="y" begin="0.25s" dur="1s"
                                                         values="10;15;20;25;30;35;40;45;50;0;10"
                                                         calcMode="linear" repeatCount="indefinite"/>
                                            </rect>
                                            <rect x="120" y="10" width="15" height="120" rx="6">
                                                <animate attributeName="height" begin="0.5s" dur="1s"
                                                         values="120;110;100;90;80;70;60;50;40;140;120"
                                                         calcMode="linear" repeatCount="indefinite"/>
                                                <animate attributeName="y" begin="0.5s" dur="1s"
                                                         values="10;15;20;25;30;35;40;45;50;0;10"
                                                         calcMode="linear" repeatCount="indefinite"/>
                                            </rect>
                                        </svg>
                                    </button>
                                    : <button className={'text-body-1'}>Refer a friend<i className="ri-link-m"></i>
                                    </button>
                                }
                                <span className="montserrat-form form__desc">Your personal information is never distributed or sold to anyone. You can opt-out of communications at any time. View our<a
                                    className="privacy__link" href="/privacy"> privacy policy</a>.</span>
                            </Form>
                        }
                    </div>
                </div>
                <div className="referral__form_full-wrapper"></div>
            </div>

        </section>
    );
}

export default ReferralInviteFull;