import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";

function Locations(props) {
    const [locations, setLocations] = useState(null)
    const [activeDropdown, setActiveDropdown] = useState('1')

    useEffect(()=>{
        fetch(`${props.debugValue}/api/blogs/all-locations`).then(res=>res.json()).then(data=> {
            if (data.status === 200){
                setLocations(data.locations)
            }
        }).catch(err=>console.log(err))
    }, [])

    const handleDropdown = (e) =>{
        let active_drop = e.target.dataset.dropid
        if (active_drop){
            setActiveDropdown(active_drop)
        }
    }


    const handleLink = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Service Areas</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/locations`}/>
                </Helmet>
            </HelmetProvider>
            <section className="global__hero_single" style={{backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url(${require('../assets/media/pictures/static/service_areas_hero.jpg')})`, backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition:'center'}}>
                <div className="container_s" style={{height: '100%'}}>
                    <div className="single__hero_wrapper">
                        <div className="single__hero_text">
                            <div className="single__hero_title text-heading-3">Wide coverage, fast support</div>
                            <div className="single__hero_desc  text-heading-5">Our extensive service area means we can quickly address and support your solar energy requirements all across Southern California</div>
                            <div className="single__hero_cta">
                                <button className={'text-heading-7'} onClick={props.toggleForm}>Get a free quotation</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="locations">
                <div className="container_s">
                    <div className="locations__wrapper">
                        <div className="locations__text">
                            <div className="locations__title"></div>
                        </div>
                        <div className="locations__elements">
                            {locations && locations.counties.map(location=>{
                                let tmp_cities = locations.cities.filter(city=>city.ref_id === location.id)
                                return(
                                    <div className="locations__element" key={location.name + location.id}>
                                        <p className={'text-heading-5'} style={{fontWeight: "bold", borderTop: "1px solid var(--border-subtle)", paddingTop: "12px", display: "flex", justifyContent: "space-between"}} onClick={event => handleDropdown(event)} data-dropid={location.id}>{location.name} county <span className="icon-container_s"><i
                                            className={`ri-arrow-${activeDropdown === location.id? 'up' : 'down'}-s-line`}></i></span></p>
                                        <div className={`dropdown__elements ${activeDropdown === location.id? 'dropdown__active' : 'dropdown__deactive'}`} >{tmp_cities && tmp_cities.map(city=>{
                                            return(
                                                <Link className={'text-heading-6'} onClick={handleLink} key={city.name + city.id} to={`/locations${city.path}`}><i className="ri-map-pin-fill"></i> {city.name}</Link>
                                            )
                                        })}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>

            <section className="homepage__cta">
                <div className="container_s">
                    <div className="cta__wrapper">
                        <div className="cta__title text-heading-3">
                            Start your solar journey with a free quote
                        </div>
                        <div className="cta__desc text-body-1">
                            SolarMax is dedicated to providing the highest quality energy alternatives at affordable prices. Enjoy easy financing options and low monthly payments that make shifting to solar energy simple and stress-free.
                        </div>
                        <div className="cta__cta text-heading-6">
                            <button className={'text-heading-6'} onClick={props.toggleForm}>Get a free quote</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Locations;