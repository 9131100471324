import React from 'react';

function ReferralProfile({referralProfile, copyToClipboard}) {
    if (!referralProfile) return null;

    return (
        <>
            <div className="single__hero_title text-heading-3">My referral code</div>

            <input className={'text-body-1'} type="text" style={{background: "white", cursor: "pointer"}}
                   onClick={event => copyToClipboard(event)} defaultValue={referralProfile.code}/>

        </>
    );
}

export default ReferralProfile;
