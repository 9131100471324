import React from 'react';

function SoFinancing(props) {
    return (
        <div>
            <div className="container">
                <div className="page-header ">

                    <h1>Save Now with Special Financing</h1>


                </div>
            </div>

            <div className="container container-page">

                <div className="row" itemProp="makesOffer" itemScope itemType="http://schema.org/Offer" style={{display: 'flex'}}>
                    <div className="col-md-6 format-page" style={{width: '50%'}}>
                        <div className="offer-hero">
                            <img itemProp="image" className="img-responsive"
                                 src="/assets/media/pictures/wwwsolarmaxtechcom/offers/images/large/ce8802eb-b5c2-47b5-ac16-431ed09ace77.jpg"
                                 alt="Solar financing"/>
                        </div>

                        <div className="page-content"><p>At SolarMax Technology, we believe that money should never
                            stand in the way of
                            Southern California home and business owners who want to make an investment in and enjoy the
                            many
                            benefits of sustainable energy. While we strive to offer some of the most cost-effective
                            solar products
                            and packages in the state, we also offer a range of attractive financing options that can
                            make it easier
                            than ever for you to realize the benefits of clean solar energy. </p>
                            <h2>Save Now with Special Financing</h2>
                            <p>See if you qualify for special low-APR financing to save you even more:<br/></p>
                            <ul>
                                <li>$39.83 monthly* (with 30% tax credit)<br/></li>
                                <li>$0 Down - pay nothing now<br/></li>
                                <li>25 year financing as low as 3.99% <br/><br/></li>
                            </ul>
                            <h2>Harness the Clean Power of Solar Energy With Affordable Financing Options</h2>
                            <p>The following are just a few of the generous financing options from which you can
                                choose:</p>
                            <ul>
                                <li><strong>Great Terms</strong><strong
                                  >:&nbsp;</strong><span
                                    >Long-term financing options with low interest rates for affordable monthly payments</span><span
                                    >.</span></li>
                                <li><strong>Flexible:&nbsp;</strong>You can work with our specialists to customize
                                    financing terms
                                    and payments to fit your needs.
                                </li>
                                <li><strong>Future Tax Credit Applied: </strong>Apply your future solar Federal tax
                                    credit to your
                                    loan today for instant, low monthly payments.<br/></li>
                            </ul>
                            <h3>Ready to Learn More? Reach Out to SolarMax Today for More Info!</h3>
                            <p>To find out more about the cost of your solar panel project, submit your details through
                                this online
                                form and we can provide you with a free, no-obligation estimate. You can also call
                                SolarMax to find
                                out more about our in-house financing options to see if they are right for you!</p>
                            <p><br/></p>
                            <p><em>​<br/>*Terms and Conditions: Financing is available on approved credit. Monthly
                                payment amount
                                assumes the 30% tax credit is applied. Payment increases to $56.99 if 30% ITC is not
                                applied by the
                                16th of the month. $0 down payment required with a payment within 16 months equal to the
                                30% federal
                                renewable energy tax credit (ITC) required. Limited availability – act now, while
                                supplies
                                last.</em> <br/></p></div>


                    </div>

                    <div className="col-md-6 position-sticky side-bar" style={{width: '50%'}}>


                        <div id="quote-form" className="quote-form panel panel-quote-form">
                            <div className="panel-body">
                                <div className="section-header">
                                    <p className="h2">Home Solar Systems Starting at Less Than $40/month</p>
                                    <div className="lead">Enjoy top savings on our premier Solar
                                        Panels &amp; Microinverters - starting
                                        at $39.83/month!
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-5 credibility text-center">
                                        <div className="item">
                                            <div className="aggregate-reviews ">
                                                <div className="credibility-description">
                                                    <div className="review-stars">
                                                        <span className="fa fa-star fa-lg"></span> <span
                                                        className="fa fa-star fa-lg"></span> <span
                                                        className="fa fa-star fa-lg"></span>
                                                        <span className="fa fa-star fa-lg"></span> <span
                                                        className="fa fa-star fa-lg fa-star-half-o"></span>
                                                    </div>
                                                </div>

                                                <div className="value">
                                                    <div className="rating">
                                                        <span className="average">4.8</span> out of <span
                                                        className="best">5</span>
                                                    </div>
                                                    based on <span className="votes">45</span> Reviews
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-sm-7">


                                        <form className="mb-1" id="price-quote"
                                              action="https://www.solarmaxtech.com/quote-thank-you/?ref=pricing"
                                              method="post"
                                              role="form">
                                            <div className="form-group">
                                                <label htmlFor="price-quote-name" className="sr-only">What is your
                                                    name?</label>
                                                <input id="price-quote-name" name="name" className="form-control"
                                                       type="text"
                                                       placeholder="Full Name" maxLength="50"/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="price-quote-email" className="sr-only">What is your
                                                    email address?</label>
                                                <input id="price-quote-email" name="email" className="form-control"
                                                       type="email"
                                                       placeholder="Email Address" maxLength="50"/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="price-quote-phone" className="sr-only">What is your
                                                    phone number?</label>
                                                <input id="price-quote-phone" name="phone" className="form-control"
                                                       type="tel"
                                                       placeholder="Phone Number" maxLength="14"/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="price-quote-address" className="sr-only">Whats your full
                                                    address?</label>
                                                <input id="price-quote-address" name="address" className="form-control"
                                                       type="text"
                                                       placeholder="Full Address" autoComplete="off"/>
                                                <input id="g-address1" name="address1" type="hidden"
                                                       autoComplete="off"/>
                                                <input id="g-address2" name="address2" type="hidden"
                                                       autoComplete="off"/>
                                                <input id="g-city" name="city" type="hidden" autoComplete="off"/>
                                                <input id="g-state" name="state" type="hidden" autoComplete="off"/>
                                                <input id="g-zip" name="zipcode" type="hidden" autoComplete="off"/>
                                                <input id="g-country" name="country" type="hidden" autoComplete="off"/>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="price-quote-project" className="sr-only">What type of
                                                    project are you
                                                    interested in?</label>
                                                <select id="price-quote-project" name="service"
                                                        className="form-control">
                                                    <option value="" selected="selected">Project Type</option>
                                                    <option value="Residential Solar Energy">Residential Solar Energy
                                                    </option>
                                                    <option value="Commercial Solutions">Commercial Solutions</option>
                                                    <option value="LED Lighting">LED Lighting</option>
                                                    <option value="Roof Replacement">Roof Replacement</option>
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="price-quote-details" className="sr-only">Please provide
                                                    a description of
                                                    your project.</label>
                                                <div className="controls">
                                        <textarea id="price-quote-details" name="details" className="form-control"
                                                  placeholder="Project Description" rows="3"
                                                  maxLength="3000"></textarea>
                                                </div>
                                            </div>
                                            <input id="price-quote-type" name="type" type="hidden" value="Quote"/>
                                            <input id="quoteToken" name="quoteToken" type="hidden" value=""/>
                                            <input id="variantPage" type="hidden" data-value="false"
                                                   data-phone-trackable=""
                                                   data-phone-id="" value=""/>
                                            <input id="check" name="spamCheck" className="covered" value=""/>
                                            <button id="price-quote-submit"
                                                    className="btn btn-block btn-lg btn-primary submit-form"
                                                    type="submit">
                                                Get Free Quote
                                            </button>
                                        </form>
                                        <p><small>Your personal information is never distributed or sold to anyone. You
                                            can opt-out
                                            of communications at any time. View our <a
                                                href="../../privacy-policy/index.html">privacy
                                                policy</a>.</small></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <input id="input-offerid" type="hidden" name="offerid"
                                                     value="3bf08a43-9420-4446-b79c-0119cad6871d"/>
                    </div>
                </div>

            </div>


            <div className="breadcrumbs">
                <div className="container">
                    <ol className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <a itemProp="item" href="../../index.html">
                            <span className="hidden-lg">
                                <i className="fas fa-home"></i>
                            </span>
                                <span className="visible-lg" itemProp="name">Home</span>
                            </a>

                        </li>
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <a itemProp="item" href="../index.html">
                                <span itemProp="name">Offers</span>
                            </a>

                        </li>
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <a href="index.html" itemProp="url">
                                <span itemProp="title">Save Now with Special Financing</span>
                            </a>

                        </li>

                    </ol>
                </div>
            </div>
        </div>
    );
}

export default SoFinancing;