import React from 'react';

function ElectricCarCharging(props) {
    return (
        <div>
            <div className="main-content">

                <div className="page-section solar-ev-section">
                    <div className="section-header">


                        <h1>California EV Charger Station: Home Charger Installation</h1>

                        <p className="lead">When you choose SolarMax Technology you are choosing to power your life with
                            innovation. With SolarMax you can now drive your electric car on sunshine.</p>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>Your Home Solar EV Solution</h2>
                                <p>Maximize your savings and minimize your dependency on non-renewable energy with a
                                    full home solar system - complete with your EV charging station.</p>
                                <p><strong>Drive on Sunshine</strong> – the solar power produced from your rooftop is
                                    more affordable and from a sustainable source. Purchasing less energy from the grid
                                    means more savings for you.</p>
                                <p><strong>Level 2 charging with solar boost mode</strong> – using your solar power, you
                                    can charge your electric vehicle up to 6 times faster than the factory 120V charging
                                    cable that came with your car. (1)</p>
                                <p><strong>Save money, time and hassle</strong> – Purchasing and installing a separate
                                    Level 2 car charger can easily cost at least $1,600 which includes the cost of the
                                    charger, of hiring a licensed electrician, and potential materials costs: wiring,
                                    conduit, and circuit breaker.</p>
                                <p><strong>Don’t own an electric vehicle yet?</strong> Be future-ready and select the
                                    electric vehicle charging inverter option for your solar energy system. You can add
                                    the car and charging cable at any time.</p>
                                <div className="electric-car-charge mt-4">
                                    <div className="media">
                                        <div className="media-left">
                                            <img className="img-responsive" src="/img/page/ev-charging-in-driveway.jpg"
                                                 alt="ev charging"/>
                                        </div>
                                        <div className="media-body">
                                            <p className="h4">The Cost of Charging an Electric Car at Home [Grid, Solar
                                                & Gas Compared]</p>
                                            <a href="/blog/p.190208000/the-cost-of-charging-an-electric-car-at-home-grid-solar-gas-compared/"
                                               className="btn btn-info btn-custom">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">

                                <div className="page-offers-section">
                                    <div className="offer-content mx-auto p-4">
                                        <div className="offer-panel text-center">
                                            <div className="panel panel-default">
                                                <a href="/offers/solar-electric-vehicle/">
                                                    <div className="panel-body match-height">
                                                        <p className="offer-title h4">Guide to Charging Your Electric
                                                            Car with Solar</p>
                                                        <p>Why using solar panels to charge your electric car is the
                                                            cheapest way to refuel it.</p>
                                                    </div>
                                                </a>
                                                <div className="panel-footer">
                                                    <a className="btn btn-info btn-custom ellipsis"
                                                       href="/offers/solar-electric-vehicle/">Learn More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="page-offers-section mt-4">
                                    <div className="offer-content mx-auto p-4">
                                        <div className="offer-panel text-center">
                                            <div className="panel panel-default">
                                                <div className="panel-body match-height">
                                                    <p className="offer-title h4">Solar Tax Credit and Solar Rebates</p>
                                                    <p>Special financing + federal solar tax credits + California solar
                                                        rebates make solar affordable - now. Learn about your options
                                                        and how to save by going solar.</p>
                                                </div>
                                                <div className="panel-footer">
                                                    <a className="btn btn-info btn-custom ellipsis"
                                                       href="/solar-tax-credit/">Learn More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="key-benefits-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 col-md-push-6 bg-key-benefits match-height"></div>
                            <div className="col-md-6 col-md-pull-6 p-5 match-height">
                                <h2>Key Benefits of the Solar Car Charging Inverter</h2>
                                <div className="media">
                                    <div className="media-left">
                                        <img className="img-responsive center-block lazyload"
                                             src="/img/page/icon-charge.png" alt="charge"/>
                                    </div>
                                    <div className="media-body pl-4">
                                        <p>Combines sun and grid power for charging up to six times faster than standard
                                            electric vehicle chargers ...</p>
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="media-left">
                                        <img className="img-responsive center-block lazyload"
                                             src="/img/page/icon-electric.png" alt="electric"/>
                                    </div>
                                    <div className="media-body pl-4">
                                        <p>Saves you the time, money and hassle of installing a standalone electric car
                                            charger on your own</p>
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="media-left">
                                        <img className="img-responsive center-block lazyload"
                                             src="/img/page/icon-warranty.png" alt="warranty"/>
                                    </div>
                                    <div className="media-body pl-4">
                                        <p>Up to 25 year warranty</p>
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="media-left">
                                        <img className="img-responsive center-block lazyload"
                                             src="/img/page/icon-phone.png" alt="phone"/>
                                    </div>
                                    <div className="media-body pl-4">
                                        <p>Fully integrated charging scheduling and control with the smart app</p>
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="media-left">
                                        <img className="img-responsive center-block lazyload"
                                             src="/img/page/icon-safety.png" alt="safety"/>
                                    </div>
                                    <div className="media-body pl-4">
                                        <p>Advanced safety features including arc-fault protection</p>
                                    </div>
                                </div>
                                <a href="#price-quote" className="btn btn-primary btn-custom mt-4 scroll-to">Get Free
                                    Quote</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="visibility-control-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 match-height bg-visibility-control"></div>
                            <div className="col-md-6 p-5 visibility-control-content match-height">
                                <div className="div-table">
                                    <div className="div-table-cell">
                                        <h2>Full Visibility and Control</h2>
                                        <p>Your electric vehicle charging inverter supports full network connectivity
                                            and integrates seamlessly with your solar monitoring platform. You can track
                                            your charging status, control vehicle charging, and set charging
                                            schedules.</p>
                                        <ul>
                                            <li>Smart-scheduling for use with Time of Use (TOU) rates – charge from the
                                                grid during off-peak hours
                                            </li>
                                            <li>Track solar, electric vehicle and grid consumption for visibility and
                                                control of household energy usage
                                            </li>
                                            <li>Remote operation via mobile app – turn charging on and off directly from
                                                your smartphone
                                            </li>
                                            <li>View charging duration, charge energy, and the percent charge from your
                                                solar power
                                            </li>
                                        </ul>
                                        <a href="#price-quote" className="btn btn-primary btn-custom mt-4 scroll-to">Get
                                            Free Quote</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tesla-section text-center py-4">
                    <div className="div-table">
                        <div className="div-table-cell">
                            <div className="section-header">
                                <img className="img-responsive center-block lazyload" src="/img/ph-square.png"
                                     data-src="/img/page/tesla-logo.png" alt="Tesla"/>
                                <h2 className="section-title my-4">Innovative Tesla EV Charging Station</h2>
                                <p>We install quality electric vehicle charging solutions from Tesla. Innovative systems
                                    offer the fastest charging option, power sharing, comprehensive tracking from your
                                    mobile device, and exceptional safety.</p>
                                <p>For unsurpassed product and service quality, and rely on the industry leaders in
                                    solar EV charging: Tesla and SolarMax.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="comparison-section page-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-center">
                                <div className="table-wrap">
                                    <h2>Electric Vehicle Charging Comparison</h2>
                                    <table className="table-striped table" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                        <tr className="blue-bar">
                                            <td width="134">
                                                <p align="center"><img className="img-responsive center-block"
                                                                       src="/img/page/icon-solar.png" alt="ICON"/></p>
                                            </td>
                                            <td width="179">
                                                <p align="center">
                                                    EV Charger Level 1
                                                    (1.44 kW 12A@120Vac)
                                                </p>
                                            </td>
                                            <td width="179">
                                                <p align="center">
                                                    EV Level 2 Charging Inverter
                                                    with Solar Boost Mode
                                                    Charging speed depends on solar production
                                                    (Maximum 9.6 kW 40A 240Vac)(1)
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="134">
                                                <p align="center">
                                                    Added miles per
                                                    1 hour of charging (2)
                                                </p>
                                            </td>
                                            <td width="179"><p align="center">5 miles</p></td>
                                            <td width="179"><p align="center">25 to 30 miles</p></td>
                                        </tr>
                                        <tr>
                                            <td width="134"><p align="center">Charge time needed to meet average daily
                                                mileage (2)</p></td>
                                            <td width="179"><p align="center">6.5 hours</p></td>
                                            <td width="179"><p align="center">1 to 1.5 hours</p></td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>

                                <div className="content text-center">
                                    <p>(1) Check your car manual for maximum charge rate</p>
                                    <p>(2) Assuming 3 miles/kWh and with a US household average driving distance of 29
                                        miles per day (source:
                                        https://www.bts.gov/statistical-products/surveys/national-household-travel-survey-daily-travel-quick-facts) </p>
                                    <p>Due to differences in the type of charging cable required between electric
                                        vehicle manufacturers, SolarMax does not include a charging cable with the
                                        inverter. Customers will need to purchase their car manufacturer’s specific
                                        charging cable separately.</p>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>

                <div className="electric-car-charging page-section">
                    <div className="section-header">
                        <h2 className="section-title">Enjoy Significant Savings With Electric Car Charging</h2>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-6 col-md-4 mb-3">
                                <div className="charging">
                                    <img className="img-responsive center-block lazyload" src="/img/ph-square.png"
                                         data-src="/img/page/products-smx-panel.jpg" alt="Solar Panel Systems"/>
                                    <div className="caption match-height">
                                        <p className="h4">Solar Panel Systems</p>
                                        <p>Complete home solar system designed for your home with high-performance solar
                                            panels engineered for maximum efficiency.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-6 col-md-4 mb-3">
                                <div className="charging">
                                    <img className="img-responsive center-block lazyload" src="/img/ph-square.png"
                                         data-src="/img/page/energy-storage.jpg" alt="Energy Storage"/>
                                    <div className="caption match-height">
                                        <p className="h4">Energy Storage</p>
                                        <p>Battery back-up systems support off-grid energy use, keeping your home
                                            running even when the power is down.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-6 col-md-4 mb-3">
                                <div className="charging">
                                    <img className="img-responsive center-block lazyload" src="/img/ph-square.png"
                                         data-src="/img/page/ev-charging.jpg" alt="EV Charging Station"/>
                                    <div className="caption match-height">
                                        <p className="h4">EV Charging Station</p>
                                        <p>Solar car charging inverters save you even more time and money, with full
                                            visibility and control in the mobile app.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section process-section text-center">
                    <div className="section-header">
                        <h2 className="section-title">Our Process</h2>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2 col-md-offset-1 mb-2">
                                <a className="process" href="/residential-solar-energy/consultation/">
                                    <img className="img-responsive center-block lazyload" src="/img/ph-rectangle.png"
                                         data-src="/img/page/icon-consult.png" alt="Free Consultation"/>
                                    <div className="caption">
                                        <p className="h4">1. Free Consultation</p>
                                        <p>Get a comprehensive analysis of your energy consumption patterns, your home’s
                                            solar readiness and an explanation of the solar options that meet your
                                            goals.</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-2 mb-2">
                                <a className="process" href="/residential-solar-energy/financing/">
                                    <img className="img-responsive center-block lazyload" src="/img/ph-rectangle.png"
                                         data-src="/img/page/icon-finance.png" alt="Financing"/>
                                    <div className="caption">
                                        <p className="h4">2. Financing</p>
                                        <p>Through partnerships with trusted lenders, SolarMax is pleased to offer a
                                            selection of financing options. Whether you want to pay your system off
                                            quickly or you prefer a low monthly payment, we help you select the best
                                            financing option for you.</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-2 mb-2">
                                <a className="process" href="/residential-solar-energy/design/">
                                    <img className="img-responsive center-block lazyload" src="/img/ph-rectangle.png"
                                         data-src="/img/page/icon-design.png" alt="Design"/>
                                    <div className="caption">
                                        <p className="h4">3. Design</p>
                                        <p>A SolarMax engineer will analyze your roof, shading and energy needs to
                                            customize a beautiful solar energy system that delivers you the maximum
                                            value.</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-2 mb-2">
                                <a className="process" href="/residential-solar-energy/permitting/">
                                    <img className="img-responsive center-block lazyload" src="/img/ph-rectangle.png"
                                         data-src="/img/page/icon-permit.png" alt="Permitting"/>
                                    <div className="caption">
                                        <p className="h4">4. Permitting</p>
                                        <p>SolarMax handles each step of the permit process for you including submitting
                                            applications, paying fees and even applying for rebates (where
                                            applicable).</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-2 mb-2">
                                <a className="process" href="/residential-solar-energy/installation/">
                                    <img className="img-responsive center-block lazyload" src="/img/ph-rectangle.png"
                                         data-src="/img/page/icons-install.png" alt="Installation"/>
                                    <div className="caption">
                                        <p className="h4">5. Installation</p>
                                        <p>Your solar energy system is installed with meticulous precision by the most
                                            experienced roofers and electricians who specialize on advanced solar energy
                                            systems.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default ElectricCarCharging;