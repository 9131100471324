const hints= {
    type: {
        residential: `<div class="check-content"><i class="ri-home-5-line"></i><div class="map-text">Getting a Solar system is a great way to increase the value of your house! The average project adds between 3% and 7% to a house’s equity. Plus, it shows you are doing your part to protect the planet for the next generation.</div></div>`,
        commercial: `<div class="check-content"><i class="ri-building-line"></i><div class="map-text">Using solar power for your business can help your business save money, guarantee your operations stay online when the power goes down, and help cut your cost of operations. Plus, it protects your bottom line against carbon taxes in the future and helps build an eco-friendly brand.</div></div>`,
        rental: `<div class="check-content"><i class="ri-dashboard-horizontal-line"></i><div class="map-text">If you don’t own your home, you can still win the $10,000 dollar prize.  Plus, if you share this contest with your landlord, you get $500 if they buy or lease a solar system.</div></div>`,
    },
    property_rel: {
        Yes: "Installing a solar system can be a sound financial investment for property owners. The upfront cost of the system can be recouped over time through energy savings. Along with solar systems providing owners with a degree of energy independence and resilience.",
        No: "Solar systems provide a level of price stability for tenants. While utility rates may fluctuate over time, especially due to factors like fuel costs, solar energy costs remain relatively stable. Also, solar systems can help tenants reduce their electricity bills. "
    },
    trees: {
        Yes: "Solar panels need direct sunlight to generate electricity efficiently. If there are trees that cast significant shade on the area where solar panels would be installed, it can greatly reduce the solar system's performance. In such cases, selectively removing or trimming trees that obstruct sunlight may be necessary to maximize solar energy production.",
        No: "Trees support a diverse range of plant and animal species. Removing trees can disrupt the natural habitat for birds, insects, and other wildlife that rely on trees for shelter, food, and nesting. This loss of biodiversity can have cascading effects on local ecosystems. Cutting down trees reduces this natural air filtration capacity, potentially leading to decreased air quality and increased carbon emissions."
    },
    roof: {
        Yes: "No worries! We will check the condition of the roof in person before we finalize the quote.",
        No: "That’s great! You probably won’t need to repair the roof before you install the panels.",
        NotSure: "No worries! We will check the condition of the roof in person before we finalize the quote.",
    },
    battery: {
        Yes: "In the event of a power outage, a solar system with battery storage can provide backup power. Traditional grid-tied solar systems without batteries are designed to shut down during outages for safety reasons.",
        No: "Without battery storage, solar system owners remain connected to the grid and can rely on net metering programs. Net metering allows solar system owners to feed excess energy they generate back into the grid, effectively \"banking\" it as credits."
    },
    ev_intro: {
        true: `<div class="main-interaction_s">
                        <div class="interaction-intro">
                                    <div class="interaction-subtitle">
                                        <i class="ri-sun-line"></i>How many EVs do you have?
                                    </div>
                                </div>
                                <div class="interaction-select">
                                    <div class="select-container_s">
                                        <div class="radio-item radio-item-small">
                                                                                           
                                            <label for="q6-1" class="radio-label-checked">1<input class="radio-button ev-radio-button" checked="checked" data-category="evCount" name="ev_count" type="radio" value="1" id="q6-1"></label>
                                        </div>
                                        <div class="radio-item radio-item-small">
                                            <label for="q6-2">2<input class="radio-button ev-radio-button" data-category="evCount" name="ev_count" type="radio" value="2" id="q6-2"></label>
                                        </div>
                                        <div class="radio-item radio-item-small">
                                            <label for="q6-3">3+<input class="radio-button ev-radio-button" data-category="evCount" name="ev_count" type="radio" value="3" id="q6-3"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="check-content">
                                            <i class="ri-car-line"></i><div class="map-text">Perfect! You're already reducing carbon bremissions on the road too. Keep it up! </div>
                                        </div>
                                        </div>`,
        false: ""
    },
}

module.exports = {
    hints
}