import React from 'react';


function Pagination(props) {
    const getValues = () =>{
        let values = []
        for (let i = 1; i < props.pages+1; i++){
            values.push(8*i)
        }
        return values
    }

    let interval = 8

    let pages = getValues()

    return (
        <>
            <button key={'before'} className={`pagination__ ${pages[0]/interval == props.currentPage ? 'pagination__active' : ''}`} data-pagination={'prev'} data-limit={pages[0]} onClick={props.handleBeforePagination}>
                <i className="ri-arrow-left-s-line"></i></button>
            <button key={'after'} className={`pagination__ ${pages[pages.length-1]/interval == props.currentPage ? 'pagination__active' : ''}`} data-pagination={'next'} data-limit={pages[pages.length-1]} onClick={props.handleNextPagination}>
                Next <i className="ri-arrow-right-s-line"></i></button>

            {/*{pages && pages.map(item=>{*/}
            {/*    return(*/}
            {/*        <button key={item} className={`pagination ${item/interval == props.currentPage ? 'pagination__active' : ''}`} data-pagination={item} onClick={props.handlePagination}>{item/8}</button>*/}
            {/*    )*/}
            {/*    })*/}
            {/*}*/}
        </>
    );
}

export default Pagination;