import React, {useState} from 'react';
import '../../assets/css/faq.css'

function Faq(props) {
    const [solutionsIndex, setSolutionsIndex] = useState('0')
    const handleSolutionsTab = (e) => {
        const index = e.target.dataset.tabindex
        if (index) {
            setSolutionsIndex(index)
        }
    }
    return (
        <>
            <section className={'solutions__single faq__single'}>
                <div className="container_s">
                    <div className="solutions__wrapper mt-3 mb-3">
                        <div className="solutions__text mb-3">
                            <div className="solutions__title section__title text-heading-4 mb-1">
                                FAQ
                            </div>
                        </div>
                        <ul className="solutions__benefits">
                            <li data-tabindex={0} onClick={event => handleSolutionsTab(event)}
                                className="solution__benefit">
                                <div><p className={'tab__title text-heading-6'} data-tabindex={0}>Solar</p>
                                    <div
                                        className={solutionsIndex !== "0" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="mx-auto sub__wrapper">
                                            <div className="accordions">
                                                <div className="accordion"><label
                                                    className="accordion__title text-heading-5"
                                                    htmlFor="radio_1"><input
                                                    type="radio" name="radio1" defaultChecked id="radio_1"/><span>How do solar panels
                                                    work?</span><i className="ri-arrow-down-s-line"></i></label>
                                                    <div className="accordion__text">
                                                        <p>There are four main components to a home solar energy system:
                                                            solar panels, inverters, monitoring system, and the new
                                                            utility net meter.

                                                            When the sun is out, your solar panels produce electricity
                                                            in the form of DC current. Your house runs on AC current, so
                                                            an inverter or many micro-inverters are used to convert your
                                                            solar DC current into useable AC current. When your new
                                                            solar panels are installed, your utility provider will also
                                                            install a new bi-directional meter. This new meter tracks
                                                            how much electricity you over-produce and send back to the
                                                            grid as well as how much electricity you consume from the
                                                            grid at nighttime. The monitoring system connects to your
                                                            home’s Wi-Fi and Internet connection to let you and SolarMax
                                                            know the health of your solar energy system.</p>
                                                    </div>
                                                </div>
                                                <div className="accordion"><label
                                                    className="accordion__title text-heading-5"
                                                    htmlFor="radio_2"><input
                                                    type="radio" name="radio1" id="radio_2"/><span>Can I afford to go
                                                    solar?</span><i className="ri-arrow-down-s-line"></i></label>
                                                    <div className="accordion__text">
                                                        <p>In general, if you are already able to afford your electric
                                                            bill then you can afford to go solar. If you have average
                                                            credit rating or better then you may qualify for a $0 down
                                                            solar loan or solar lease. Even if you have bad credit there
                                                            are options like the HERO program where you can use the
                                                            equity in your home as collateral.</p>
                                                    </div>
                                                </div>
                                                <div className="accordion"><label
                                                    className="accordion__title text-heading-5"
                                                    htmlFor="radio_3"><input
                                                    type="radio" name="radio1" id="radio_3"/><span>How does solar actually save
                                                    me money?</span><i className="ri-arrow-down-s-line"></i></label>
                                                    <div className="accordion__text">
                                                        <p>Solar energy is cheap energy. It has the lowest levelized
                                                            cost of energy (LCOE) of any form of energy we generate. The
                                                            upfront costs are higher than other options, but whether you
                                                            are financing, leasing, or buying outright, having solar
                                                            panels will always be less expensive than paying your
                                                            utility provider for electricity for the next 25 years. Our
                                                            solar panels have a 25-year manufacturer warranty, and many
                                                            continue generating clean energy well beyond 30 years, so
                                                            they will pay for themselves and save you money for years
                                                            before needing to be replaced.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-tabindex={1} onClick={event => handleSolutionsTab(event)}
                                className="solution__benefit">
                                <div><p className={'tab__title text-heading-6'} data-tabindex={1}>Financing</p>
                                    <div
                                        className={solutionsIndex !== "1" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="mx-auto sub__wrapper">
                                            <div className="accordions">
                                                <div className="accordion"><label
                                                    className="accordion__title text-heading-5"
                                                    htmlFor="radio_4"><input
                                                    type="radio" name="radio2" defaultChecked id="radio_4"/><span>What is the benefit of purchasing my solar panels with cash?</span><i
                                                    className="ri-arrow-down-s-line"></i></label>
                                                    <div className="accordion__text">
                                                        <p>Some people have the money to buy their solar energy system
                                                            all at once. These people typically look at their solar
                                                            panel purchase as an investment.

                                                            If you were to pay cash for your solar panels, it typically
                                                            takes about six or seven years to see a return on your
                                                            investment. In other words after six or seven years, you’ve
                                                            saved enough money by not paying your utility company for
                                                            electricity that you now have recouped the cost of your
                                                            system in electricity savings. From here on out, you are
                                                            generating free electricity and, for the average solar
                                                            owner, saving hundreds of dollars a month.</p>
                                                    </div>
                                                </div>
                                                <div className="accordion"><label
                                                    className="accordion__title text-heading-5"
                                                    htmlFor="radio_5"><input
                                                    type="radio" name="radio2" id="radio_5"/><span>When would I want to finance the purchase of my solar panels?</span><i
                                                    className="ri-arrow-down-s-line"></i></label>
                                                    <div className="accordion__text">
                                                        <p>Not everyone has the money to buy their solar system upfront
                                                            and would prefer to buy their panels with no down payment.
                                                            Also, many people are just looking for some immediate
                                                            monthly energy savings and they want protection from
                                                            constantly rising utility rates.

                                                            In this case you could easily benefit from financing the
                                                            purchase of your solar panels.</p>
                                                    </div>
                                                </div>
                                                <div className="accordion"><label
                                                    className="accordion__title text-heading-5"
                                                    htmlFor="radio_6"><input
                                                    type="radio" name="radio2" id="radio_6"/><span>Are there benefits to the PACE loan program?</span><i
                                                    className="ri-arrow-down-s-line"></i></label>
                                                    <div className="accordion__text">
                                                        <p>For homeowners who do not want to do a credit check or have
                                                            an additional loan count against their debt-to-income ratio,
                                                            then PACE financing may be a good choice. SolarMax is
                                                            partnered with CaliforniaFIRST to provide PACE financing.

                                                            PACE stands for Property Assessed Clean Energy. PACE is
                                                            different than a traditional loan based on your credit. PACE
                                                            is structured like a tax assessment on your property. Rather
                                                            than paying monthly payments to a lender, you pay your
                                                            installments through your property tax bill.

                                                            <br/>Some reasons homeowners choose PACE:

                                                            <br/>1)The homeowner is only paying on tax assessment and
                                                            can write off interest payments (similar to mortgage
                                                            interest).
                                                            <br/>2)The loan does not show up on a credit report and
                                                            therefore is not calculated in the homeowner’s
                                                            debt-to-income ratio.
                                                            <br/>3)No hard pull on your credit report is done, just a
                                                            soft pull which does not affect your credit score.
                                                            <br/>4)Repayment terms can be anywhere from 5 years to 30
                                                            years.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-tabindex={2} onClick={event => handleSolutionsTab(event)}
                                className="solution__benefit">
                                <div><p className={'tab__title text-heading-6'} data-tabindex={2}>Battery</p>
                                    <div
                                        className={solutionsIndex !== "2" ? "benefits__sub_tab" : "benefits__sub_active"}>
                                        <div className="mx-auto sub__wrapper">
                                            <div className="accordions">
                                                <div className="accordion"><label
                                                    className="accordion__title text-heading-5"
                                                    htmlFor="radio_7"><input
                                                    type="radio" name="radio3" defaultChecked id="radio_7"/><span>What’s the difference between energy storage and battery backup?</span><i
                                                    className="ri-arrow-down-s-line"></i></label>
                                                    <div className="accordion__text">
                                                        <p>At SolarMax you can buy a battery to go along with your solar
                                                            energy system. Depending on the brand, that battery can be
                                                            used as a backup power source during blackouts and/or used
                                                            to store excess energy produced by your panels during the
                                                            day to be used at night.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="accordion"><label
                                                    className="accordion__title text-heading-5"
                                                    htmlFor="radio_8"><input
                                                    type="radio" name="radio3" id="radio_8"/><span>Do solar panels keep my house powered in a blackout?</span><i
                                                    className="ri-arrow-down-s-line"></i></label>
                                                    <div className="accordion__text">
                                                        <p>No. For safety reasons, power utilities do not allow solar
                                                            panel energy to keep flowing and feeding the grid during a
                                                            blackout. To keep your house powered without interruption
                                                            during a blackout you’ll need a battery backup.</p>
                                                    </div>
                                                </div>
                                                <div className="accordion"><label
                                                    className="accordion__title text-heading-5"
                                                    htmlFor="radio_9"><input
                                                    type="radio" name="radio3" id="radio_9"/><span>Should I get energy storage if my utility is converting my billing to Time of Use?</span><i
                                                    className="ri-arrow-down-s-line"></i></label>
                                                    <div className="accordion__text">
                                                        <p>Maybe. Some utilities are converting their customers to Time
                                                            of Use billing. In Time of Use billing you are charged
                                                            higher rates for your electricity during times of the day
                                                            when the demand for electricity is highest. The most
                                                            expensive time is in the evening. The problem with Time of
                                                            Use and solar is that when you are producing solar energy
                                                            during the day, you are being credited a lower amount than
                                                            when you go to pull back your energy from the grid in the
                                                            evening at a higher rate. A battery used for energy storage
                                                            will be charged by your solar panels during the day and then
                                                            you can consume that energy at night instead of paying the
                                                            higher electrical costs imposed by your utility.
                                                            <br/>
                                                            This scenario sounds like you need energy storage if you are
                                                            on Time of Use. The only problem is that currently (2019)
                                                            the cost of an energy storage battery is probably going to
                                                            be more expensive to buy than the energy savings you get
                                                            using the battery. But over time utility rates will rise and
                                                            battery technology will keep improving. We will probably see
                                                            the price of energy go up and the cost of home batteries go
                                                            down enough in the next 5 years where using a battery for
                                                            energy storage might begin to make financial sense.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div style={{transform: `translateX(${solutionsIndex * 100}%)`}}
                                     className={'tab__active'}></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="section bg-pink-light">

            </section>
        </>
    );
}

export default Faq;
