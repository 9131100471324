import React, {useRef, useState} from 'react';
import {referFriend} from "../../fetch/referral";
import ReferralInviteProcess from "./components/referral-invite-process";
import ReferralThanks from "./referral-thanks";

function ReferralInvite({data, loading}) {
    const referralInfo = useRef({
        firstname: null,
        lastname: null,
        email: null
    });
    const [referred, setReferred] = useState({
        link: null,
        code: null
    });

    const changeReferral = (e) => {
        referralInfo.current = {...referralInfo.current, [e.target.name]: e.target.value};
    }
    const submitInvite = (e) => {
        e.preventDefault();
        Object.values(referralInfo.current).some(v => !v) ?
            alert("Fill all the required fields.")
            : referFriend(
                referralInfo.current.firstname,
                referralInfo.current.lastname,
                referralInfo.current.email,
                data
            ).then(rl => rl && rl.status === 200 && setReferred({...referred, link:`${data.debugValue}/my-referral/${rl.data.referral.code}`, code: rl.data.referral.code}))

    }
    return (
        <>
            {referred.code ? <ReferralThanks/>
                : <ReferralInviteProcess loading={loading} submitInvite={submitInvite} changeReferral={changeReferral} />
            }

        </>
    );
}

export default ReferralInvite;
