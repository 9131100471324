const cs = require('../lib/campaigns.json')

export let SOURCES = {
    "TWITTER": {source:"twitter", link: "https://twitter.com/share?url=", options: ""},
    "FACEBOOK": {source:"facebook", link: "https://www.facebook.com/sharer/sharer.php?u=", options: ""},
    // "INSTAGRAM": {source:"instagram", link: "", options: ""},
    "LINKEDIN": {source:"linkedin", link: "https://www.linkedin.com/sharing/share-offsite/?url=", options: ""},
    "WEBSITE": {source:"website", link: "", options: ""},
}

export async function get_utm_link(c: string, source: string, pass: string) {
    let cd:{date: string,
        campaign:string,
        link:string,
        medium: string,
        expiry: string} = cs[c]
    if (new Date(cd.expiry) > Date.now()){
        return {
            status: 200,
            message: "Success",
            source: SOURCES[source].source,
            value: `${SOURCES[source].link}${cd.link}?utm_medium=${cd.medium}&utm_campaign=${cd.campaign}&utm_source=${SOURCES[source].source}${SOURCES[source].options}`
        }
    }
    return {status: 400, message: "Expired", value: null}
}