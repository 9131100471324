import React, {useEffect, useRef, useState} from 'react';
import Faq from "./shared/faq";
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
import ContactForm from "./shared/contact-form";
import {Helmet, HelmetProvider} from "react-helmet-async";


function Contact(props) {



    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Contact us</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/contact-us`}/>
                </Helmet>
            </HelmetProvider>
            <section className="contact__hero_single" style={{backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.80) 100%, rgba(0, 0, 0, 0.00) 10%), url(${require('../assets/media/pictures/static/contact_us_hero.jpg')})`, backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition:'center'}}>
                <div className="container_s" style={{height: '100%'}}>
                    <div className="contact__hero_title text-heading-2">Need help?</div>

                    <div className="contact__hero_wrapper">
                        <div className="contact__hero_text">
                            <div className="contact__hero_details">
                                <div className="contact__detail">
                                    <div className="contact__detail_icon"><i className="ri-phone-fill"></i></div>
                                    <div className="contact__detail_text">
                                        <div className="contact__detail_title text-body-1">Give us a call</div>
                                        <div className="contact__detail_desc text-heading-5"><a href="tel:(951) 221-8172">(951) 221-8172</a></div>
                                    </div>
                                </div>
                                <div className="contact__detail">
                                    <div className="contact__detail_icon"><i className="ri-mail-line"></i></div>
                                    <div className="contact__detail_text">
                                        <div className="contact__detail_title text-body-1">Send us an email</div>
                                        <div className="contact__detail_desc text-heading-5"><a href="mailto:support@solarmax.com">sales@solarmax.com</a></div>
                                    </div>
                                </div>
                                <div className="contact__detail">
                                    <div className="contact__detail_icon"><i className="ri-mail-line"></i></div>
                                    <div className="contact__detail_text">
                                        <div className="contact__detail_title text-body-1">For technical issues</div>
                                        <div className="contact__detail_desc text-heading-5"><a href="mailto:customerservice@solarmaxtech.com">customerservice@solarmaxtech.com</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="contact__hero_form">
                            {/*<Form>*/}
                            {/*    <input type="text" required onChange={event =>handleInputChange(event) } name={'fullName'} placeholder={"Full name"}/>*/}
                            {/*    <input type="email" required onChange={event =>handleInputChange(event) }  name={'email'} placeholder={"Email"}/>*/}
                            {/*    <input type="text" required onChange={event =>handleInputChange(event) }  name={'phone'} placeholder={"Phone number"}/>*/}
                            {/*    <input type="text" required onChange={event =>handleInputChange(event) }  name={'address'} placeholder={"Address"}/>*/}
                            {/*    <select name="projectType" id="" onChange={event =>handleInputChange(event) } >*/}
                            {/*        <option value="residential">Residential solar energy</option>*/}
                            {/*        <option value="commercial">Commercial solutions</option>*/}
                            {/*        <option value="led">LED lighting</option>*/}
                            {/*        <option value="roof">Roof replacement</option>*/}
                            {/*    </select>*/}
                            {/*    <input type="text" required onChange={event =>handleInputChange(event) }  name={'comment'} placeholder={"Project description"}/>*/}
                            {/*    <button type={'submit'} className={'text-heading-6'}>Get free quote</button>*/}

                            {/*</Form>*/}
                            <iframe className={"form__holder"}
                                    style={{width: '100%', height: '740px', position: 'sticky', top: '80px'}}
                                    id={'solarmax__form'}
                                    src={`https://4345356.extforms.netsuite.com/app/site/crm/externalleadpage.nl?compid=4345356${props.form_source}`}
                                    frameBorder="0"></iframe>
                            {/*<ContactForm/>*/}
                        </div>
                    </div>
                </div>
            </section>

            <section className="departments">
                <div className="container_s">
                    <div className="departments__wrapper">
                        <div className="departments__text">
                            <div className="departments__title text-heading-5">Our offices</div>
                            <div className="departments__desc"></div>
                        </div>
                        <Splide aria-label="" hasTrack={false} options={{
                            perPage: 1
                        }}>
                            <SplideTrack>
                                <SplideSlide>
                                    <div className="departments__main" style={{background:`url('${require('../assets/media/pictures/static/2.1 SolarMax Front Photo.jpg')}')`, backgroundSize: "cover", backgroundPosition: 'center'}}>
                                        <div className="departments__location">
                                            <div className="departments__state text-heading-5">Riverside Office</div>
                                            <div className="departments__state text-body-1">Open Monday - Friday, 8 am to 7 pm</div>
                                            <div className="departments__address text-body-6"><i className="ri-map-pin-2-fill"></i> 3080 12th Street, Riverside, CA 92507</div>
                                            <div className="departments__mail text-body-6"><i className="ri-phone-fill"></i> (951) 221-8172</div>

                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="departments__main" style={{background:` url('${require('../assets/media/pictures/static/2.2 Diamond bar office.jpg')}')`, backgroundSize: "cover", backgroundPosition: 'center'}}>
                                        <div className="departments__location">
                                            <div className="departments__state text-heading-5">Diamond Bar Office</div>
                                            <div className="departments__state text-body-1">Open By Appointment Only</div>
                                            <div className="departments__address text-heading-6"><i className="ri-map-pin-2-fill"></i> 3230 Fallow Field Dr. Diamond Bar, CA 91765</div>
                                            <div className="departments__mail text-heading-6"><i className="ri-phone-fill"></i> (909) 245-9952</div>
                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="departments__main" style={{background:` url('${require('../assets/media/pictures/static/2.3 San Diego office.jpg')}')`, backgroundSize: "cover", backgroundPosition: 'center'}}>
                                        <div className="departments__location">
                                            <div className="departments__state text-heading-5">San Diego Office</div>
                                            <div className="departments__state text-body-1">Open By Appointment Only</div>
                                            <div className="departments__address text-heading-6"><i className="ri-map-pin-2-fill"></i> 8825 Production Ave San Diego, CA 92121</div>
                                            <div className="departments__mail text-heading-6"><i className="ri-phone-fill"></i> (619) 332-1696</div>
                                        </div>
                                    </div>
                                </SplideSlide>
                            </SplideTrack>
                        </Splide>

                    </div>
                </div>
            </section>

            <Faq/>
        </>
    );
}

export default Contact;