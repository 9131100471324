import React from 'react';
import Faq from "./shared/faq";
import '../assets/css/info-cigan.css'
import Explore from "./shared/explore";
import {Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
function Financing(props) {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Financing</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/financing`}/>
                </Helmet>
            </HelmetProvider>
            <section className="global__hero_single" style={{backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url('${require('../assets/media/pictures/static/financing_hero.jpg')}')`, backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition:'center'}}>
                <div className="container_s" style={{height: '100%'}}>
                    <div className="single__hero_wrapper">
                        <div className="single__hero_text">
                            <div className="single__hero_title text-heading-3">Save now with special financing</div>
                            <div className="single__hero_desc  text-heading-5">Affordable solar solutions for Southern California homeowners and businesses, with flexible financing options</div>
                            <div className="single__hero_cta">
                                <a href="">Explore more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="financing__details">
                <div className="container_s">
                    <div className="financing__details_wrapper">
                        <div className="financing__details_titles">
                            <div className="financing__numbers_title text-heading-5">
                                Flexible terms that fit your specific needs
                            </div>
                            <div className="financing__results_title text-heading-5">
                                Harness the sun's limitless power on a limited budget with tailored financing options
                            </div>
                        </div>
                        <div className="financing__details_numbers">
                            <div className="financing__numbers_elements">
                                <div className="financing__numbers_element">
                                    <div className="financing__numbers_text">
                                        <div className="financing__number_title text-heading-5">Competitive interest rates</div>
                                        <div className="financing__number_desc text-body-1">Get affordable financing with rock-bottom rates as low as</div>
                                    </div>
                                    <div className="financing__number_indic text-heading-4"><span>3.99% rate</span><span></span></div>
                                </div>
                                <div className="financing__numbers_element">
                                    <div className="financing__numbers_text">
                                        <div className="financing__number_title text-heading-5">No upfront costs</div>
                                        <div className="financing__number_desc text-body-1">Purchase your system with no initial down payment amount</div>

                                    </div>
                                    <div className="financing__number_indic text-heading-4"><span>$0 down</span><span></span></div>
                                </div>
                                <div className="financing__numbers_element">
                                    <div className="financing__numbers_text">
                                        <div className="financing__number_title text-heading-5">Long-term financing</div>
                                        <div className="financing__number_desc text-body-1">Lock in financing rates and spread payments across the system life for up to</div>

                                    </div>
                                    <div className="financing__number_indic text-heading-4"><span>25 years</span><span></span></div>
                                </div>
                                <div className="financing__numbers_element">
                                    <div className="financing__numbers_text">
                                        <div className="financing__number_title text-heading-5">Reasonable requirements</div>
                                        <div className="financing__number_desc text-body-1">Credit ratings shouldn't stop you switching to solar; we only require a</div>

                                    </div>
                                    <div className="financing__number_indic text-heading-4"><span>650 score</span><span></span></div>
                                </div>
                                <div className="financing__numbers_image">
                                    <img src={require('../assets/media/pictures/static/financing/3-Under four tiles.jpg')} alt=""/>
                                </div>

                            </div>
                        </div>
                        <div className="financing__details_results">
                            <div className="single__hero_desc tmp__mobile_text text-body-1">Affordable solar solutions for Southern California homeowners and businesses, with flexible financing options.</div>

                            <div className="financing__results_img">
                                <img src={require('../assets/media/pictures/static/financing/2-Above 39.80 per month.jpg')} alt=""/>
                            </div>
                            <div className="financing__results_result">
                                <div className="financing__result_text">
                                    <div className="financing__result_title text-heading-5"> Affordable Monthly Payments</div>
                                    <div className="financing__result_desc text-body-1">Enjoy the convenience of paying only</div>
                                </div>
                                <div className="financing__result_details"><span className={'text-heading-3'}>$39.83</span><span>after the 30% tax credit</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="financing__options">
                <div className="container">
                    <div className="financing__options_wrapper">
                        <div className="financing__options_text">
                            <div className="financing__options_title text-heading-5">Flexible financing for the greater good</div>
                            <div className="financing__options_desc text-heading-6">Smart financing options to help everyone enjoy a more sustainable future</div>

                        </div>
                        <div className="financing__options_elements">
                            <div className="financing__options_element">
                                <div className="financing__element_img">
                                    <img src={require('../assets/media/pictures/static/financing/4-2-Financing.png')} alt=""/>
                                </div>
                                <div className="financing__element_text">
                                    <div className="financing__element_title text-heading-6">Great interest rates</div>
                                    <div className="financing__element_desc text-body-1">Long-term financing options with low interest rates for affordable monthly payments</div>
                                    <div className="financing__element_cta">
                                        <a href="">Get a quote</a>
                                    </div>
                                </div>
                            </div>
                            <div className="financing__options_element">
                                <div className="financing__element_img">
                                    {/*<img src={require('../assets/media/pictures/static/financing/4-3-Financing.png')}*/}
                                    {/*     alt=""/>*/}
                                    <i className="ri-draft-line"></i>
                                </div>
                                <div className="financing__element_text">
                                    <div className="financing__element_title text-heading-6">Flexible terms</div>
                                    <div className="financing__element_desc text-body-1">You can work with our specialists to customize financing terms and payments to fit your needs</div>
                                    <div className="financing__element_cta">
                                        <Link to={"/special-offers"}>See special offers</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="financing__options_element">
                                <div className="financing__element_img">
                                    <img src={require('../assets/media/pictures/static/financing/4-1-Financing.png')} alt=""/>
                                </div>
                                <div className="financing__element_text">
                                    <div className="financing__element_title text-heading-6">Future credits applied</div>
                                    <div className="financing__element_desc text-body-1">Apply your future solar Federal tax credit to your loan today for instant, low monthly payments</div>
                                    <div className="financing__element_cta">
                                        <a href="">Learn more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Explore explore={props.explore}/>

            <Faq/>

            <section className="homepage__cta">
                <div className="container">
                    <div className="cta__wrapper">
                        <div className="cta__title text-heading-3">
                            Start your solar journey with a free quote
                        </div>
                        <div className="cta__desc text-body-1">
                            SolarMax is dedicated to providing the highest quality energy alternatives at affordable prices. Enjoy easy financing options and low monthly payments that make shifting to solar energy simple and stress-free.
                        </div>
                        <div className="cta__cta text-heading-6">
                            <button className={'text-heading-6'} onClick={props.toggleForm}>Get a free quote</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Financing;