import React from 'react';
import {Link} from "react-router-dom";
import map from "../../assets/media/pictures/static/map-global.png";
import {Helmet, HelmetProvider} from "react-helmet-async";

function Regions(props) {

    const handleLink = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    return (
        <>

            <section className="locations__global">
                <div className="container_s">
                    <div className="locations__wrapper">
                        <div className="locations__cities">
                            <div className="locations__cities_title text-heading-4">
                                Areas we cover
                            </div>
                            <div className="locations__cities_desc text-body-1">
                                We are proud to serve homes and businesses from Los Angeles to San Diego and beyond
                            </div>
                            <div className="locations__cities_places">
                                <div className="locations__place">
                                    <Link to={`/locations/anaheim`} id={'homepage-locations-anaheim'} onClick={handleLink}><i className="ri-map-pin-2-fill"></i> <p className={'text-heading-6'}>Anaheim</p></Link>
                                </div>
                                <div className="locations__place">
                                    <Link to={`/locations/san-diego`} id={'homepage-locations-san_diego'} onClick={handleLink}><i className="ri-map-pin-2-fill"></i> <p className={'text-heading-6'}>San Diego</p></Link>
                                </div>
                                <div className="locations__place">
                                    <Link to={`/locations/riverside`} id={'homepage-locations-riverside'} onClick={handleLink}><i className="ri-map-pin-2-fill"></i> <p className={'text-heading-6'}>Riverside</p></Link>
                                </div>
                                <div className="locations__place">
                                    <Link to={`/locations/long-beach`} id={'homepage-locations-long_beach'} onClick={handleLink}><i className="ri-map-pin-2-fill"></i> <p className={'text-heading-6'}>Long Beach</p></Link>
                                </div>
                                <div className="locations__place">
                                    <Link to={`/locations/santa-ana`} id={'homepage-locations-santa_ana'} onClick={handleLink}><i className="ri-map-pin-2-fill"></i> <p className={'text-heading-6'}>Santa Ana</p></Link>
                                </div>
                                <div className="locations__place">
                                    <Link to={`/locations/chula-vista`} id={'homepage-locations-chula_vista'} onClick={handleLink}><i className="ri-map-pin-2-fill"></i> <p className={'text-heading-6'}>Chula Vista</p></Link>
                                </div>
                                <div className="locations__place">
                                    <Link to={`/locations/santa-clarita`} id={'homepage-locations-santa_clarita'} onClick={handleLink}><i className="ri-map-pin-2-fill"></i> <p className={'text-heading-6'}>Santa Clarita</p></Link>
                                </div>
                                <div className="locations__place">
                                    <Link to={`/locations/los-angeles`} id={'homepage-locations-los_angeles'} onClick={handleLink}><i className="ri-map-pin-2-fill"></i> <p className={'text-heading-6'}>Los Angeles</p></Link>
                                </div>
                                <div className="locations__place">
                                    <Link to={`/locations/san-bernardino`} id={'homepage-locations-san_bernardino'} onClick={handleLink}><i className="ri-map-pin-2-fill"></i> <p className={'text-heading-6'}>San Bernardino</p></Link>
                                </div>
                                <div className="locations__place">
                                    <Link to={`/locations/moreno-valley`} id={'homepage-locations-moreno_valley'} onClick={handleLink}><i className="ri-map-pin-2-fill"></i> <p className={'text-heading-6'}>Moreno Valley</p></Link>
                                </div>
                                <div className="locations__place">
                                    <Link to={`/locations/irvine`} id={'homepage-locations-irvine'} onClick={handleLink}><i className="ri-map-pin-2-fill"></i> <p className={'text-heading-6'}>Irvine</p></Link>
                                </div>
                                <div className="locations__place">
                                    <Link to={`/locations/fontana`} id={'homepage-locations-fontana'} onClick={handleLink}><i className="ri-map-pin-2-fill"></i> <p className={'text-heading-6'}>Fontana</p></Link>
                                </div>
                            </div>
                            <div className="locations__cities_cta text-heading-7">
                                <Link to={'/service-areas'} onClick={handleLink}>View all service areas <i className="ri-arrow-right-line"></i></Link>
                            </div>
                        </div>
                        <div className="locations__map">
                            <img src={map} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Regions;