import React from 'react';

function SoBattery(props) {
    return (
        <div>
            <div className="container">
                <div className="page-header ">
                    <h1>Solar battery Storage - Starting Under $62/Month</h1>
                </div>
            </div>
            <div className="container container-page">
                <div className="row" itemProp="makesOffer" itemScope itemType="http://schema.org/Offer" style={{display: 'flex'}}>
                    <div className="col-md-6 format-page" style={{width: '50%'}}>
                        <div className="offer-hero">
                            <img itemProp="image" className="img-responsive"
                                 src="/assets/media/pictures/wwwsolarmaxtechcom/offers/images/large/bbff4211-124a-4b0b-a4b8-1d2faba6e540.jpg"
                                 alt="Solar So_battery Storage - Starting Under $62/Month"/>
                        </div>

                        <div className="page-content"><p>Save even more each month by adding a solar battery to your
                            home solar system.
                            Harness the power of the sun, and hold on to that energy into the evening, when you need it
                            most and
                            costs are highest. And send the excess back to the grid for energy credits at the best
                            rates.</p>
                            <h3>Solar So_battery Special</h3>
                            <p>Solar So_battery starting at $61.50/month <br/>(1) Enphse IQ So_battery 5P</p>
                            <h3>Save More with Multiple Savings Options</h3>
                            <ul>
                                <li>Special low-APR financing for those who qualify</li>
                                <li><span>Federal tax incentives are at their highest rates: 30%</span>
                                </li>
                                <li><span>California solar rebates with SGIP (Self-Generation Incentive Program)</span>
                                </li>
                                <li><span><span>And continue to benefit from long-term energy savings each month.</span><br/></span>
                                </li>
                            </ul>
                            <p>Secure your savings today, and continue to save more each month.<br/> <br/><em>*Terms and
                                Conditions:
                                Financing is available on approved credit. Monthly payment amount assumes the 30% tax
                                credit is
                                applied. Payment increases if 30% ITC is not applied by the 16th of the month. $0 down
                                payment
                                required with a payment within 16 months equal to the 30% federal renewable energy tax
                                credit (ITC)
                                required. Limited availability – act now, while supplies last.</em><br/></p></div>
                    </div>

                    <div className="col-md-6 position-sticky side-bar" style={{width: '50%'}}>
                        <div className="panel panel-default">
                            <div className="panel-heading">Solar So_battery Special</div>
                            <div className="panel-body">
                                <iframe className={"form__holder"}
                                        style={{width: '100%', height: '740px', position: 'sticky', top: '80px'}}
                                        id={'solarmax__form'}
                                        src={`https://4345356.extforms.netsuite.com/app/site/crm/externalleadpage.nl?compid=4345356${props.form_source}`}
                                        frameBorder="0"></iframe>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="breadcrumbs">
                <div className="container">
                    <ol className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                        <a itemProp="item" href="../../index.html">
                            <span className="hidden-lg">
                                <i className="fas fa-home"></i>
                            </span>
                                <span className="visible-lg" itemProp="name">Home</span>
                            </a>

                        </li>
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <a itemProp="item" href="../index.html">
                                <span itemProp="name">Offers</span>
                            </a>

                        </li>
                        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                            <a href="index.html" itemProp="url">
                                <span itemProp="title">Solar So_battery Storage - Starting Under $62/Month</span>
                            </a>
                        </li>
                    </ol>
                </div>
            </div>

        </div>
    );
}

export default SoBattery;